export interface ILodgingInitialValues {
  description: string;
  maximumCapacity: number;
  numberOfBathrooms: number;
  sleepingArrangementDescription: string;
  numberOfTwinBeds: number;
  numberOfQueenBeds: number;
  numberOfKingBeds: number;
  numberOfBedrooms: number;
}

export const lodgingInitialValues = {
  description: "",
  maximumCapacity: 0,
  numberOfBathrooms: 0,
  sleepingArrangementDescription: "",
  numberOfTwinBeds: 0,
  numberOfQueenBeds: 0,
  numberOfKingBeds: 0,
  numberOfBedrooms: 0,
};

export interface IExperienceInitialValues {
  location: { label: string; id: string };
  name: string;
  shortDescription: string;
  description: string;
  experienceType: { label: string; id: string };
  wildlife: { label: string; id: string };
  whatToExpectDescription: string;
  publish: boolean;
}

export const experienceInitialValues = {
  location: { label: "", id: "" },
  name: "",
  shortDescription: "",
  description: "",
  experienceType: { label: "", id: "" },
  wildlife: { label: "", id: "" },
  whatToExpectDescription: "",
  publish: false,
};

export interface IPackageInitialValues {
  location: { label: string; id: string };
  experience: { label: string; id: string };
  lodging: { label: string; id: string };
  title: string;
  description: string;
  minAmountOfGuests: number;
  maxAmountOfGuests: number;
  minReservationDays: number;
  maxReservationDays: number;
  participantPrice: number;
  nonParticipantPrice: number;
  published: boolean;
  mostPopular: boolean;
  soldOut: boolean;
}

export const packageInitialValues = {
  location: { label: "", id: "" },
  experience: { label: "", id: "" },
  lodging: { label: "", id: "" },
  title: "",
  description: "",
  minAmountOfGuests: 0,
  maxAmountOfGuests: 0,
  minReservationDays: 0,
  maxReservationDays: 0,
  participantPrice: 0,
  published: false,
  mostPopular: false,
  soldOut: false,
};
