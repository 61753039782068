import styled from "styled-components";
import React, { ReactNode } from "react";
import { Box, Typography } from "@mui/material";

export const StyledBox = styled(Box)<ApiErrorBoxProps>(() => ({
  backgroundColor: "#fff3e0",
  padding: "16px",
  border: "1px solid #f44336",
  borderRadius: "4px",
}));

type ApiErrorBoxProps = {
  children: ReactNode;
};

export const ApiError: React.FC<ApiErrorBoxProps> = ({
  children,
}): React.ReactElement => {
  return (
    <StyledBox>
      {" "}
      <Typography color="error" variant="h6">
        {children}
      </Typography>
    </StyledBox>
  );
};

export default ApiError;
