import { useQuery } from "@tanstack/react-query";
import AsyncStatuses from "common/types/enums/asyncStatuses";
import { locationKeys } from "./locationQueryKeyFactory";
import { locationApi } from "./locationService";

const useQueryLocationContactRoles = () => {
  return useQuery({
    queryKey: locationKeys.lists(),
    queryFn: () => locationApi.getLocationContactRoles(),
  });
};

export const useGetLocationContactRoles = (page?: number, size?: number) => {
  const query = useQueryLocationContactRoles();

  return {
    ...query,
    data: query.data?.data,
    LocationContactRolestatus: query.status as AsyncStatuses,
  };
};
