import styled from "styled-components";
import { Box } from "@mui/material";
import { TextField } from "@mui/material";

export const StyledInput = styled(TextField)(() => ({
  width: "49%",
}));

export const StyledBoxDescription = styled(Box)(() => ({
  border: "1px solid lightgrey",
  borderRadius: "4px",
}));
