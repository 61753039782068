import styled from "styled-components";
import { Typography, TextField } from "@mui/material";
import { theme } from "common/styles/theme";

export const StyledTitle = styled(Typography)(() => ({
  paddingBottom: "26px",
}));

export const StyledInput = styled(TextField)(() => ({
  width: "49%",
}));

export const StyledLine = styled(Typography)(() => ({
  padding: "20px 0",
  color: theme.palette.greyShades.dark,
}));
