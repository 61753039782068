import { useMutation, useQueryClient } from "@tanstack/react-query";
import { lodgingApi } from "./lodgingService";
import AsyncStatuses from "common/types/enums/asyncStatuses";
import { LodgingsAPIApiUpdateLodgingRequest } from "api/v1.0";
import { lodgingKeys } from "./lodgingKeyFactory";

function useMutateLodging() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (updateRequest: LodgingsAPIApiUpdateLodgingRequest) =>
      lodgingApi.updateLodging(updateRequest),
    onSuccess: async (_data, _variables) => {
      await queryClient.invalidateQueries({ queryKey: lodgingKeys.all });
    },
  });
}

export const useUpdateLodging = () => {
  const mutateLodging = useMutateLodging();

  return {
    ...mutateLodging,
    updateStatus: mutateLodging.status as AsyncStatuses,
  };
};
