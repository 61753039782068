import { useQuery } from "@tanstack/react-query";
import AsyncStatuses from "common/types/enums/asyncStatuses";
import { lodgingApi } from "./lodgingService";
import { lodgingKeys } from "./lodgingKeyFactory";

const useQueryAmenities = () => {
  return useQuery({
    queryKey: lodgingKeys.list("amenities"),
    queryFn: () => lodgingApi.getAllAmenities(),
  });
};

export const useGetLodgingAmenities = () => {
  const query = useQueryAmenities();

  return {
    ...query,
    data: query.data?.data,
    locationStatus: query.status as AsyncStatuses,
  };
};
