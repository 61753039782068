import styled from "styled-components";
import { Autocomplete } from "@mui/material";

export const StyledSubTitle = styled("h3")(() => ({
  fontSize: "16px",
  paddingTop: "30px",
  marginBottom: "10px",
}));

export const StyledAutocomplete = styled(Autocomplete)(() => ({
  width: "40%",
}));
