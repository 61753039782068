import {
  LocationAddressRequest,
  UpdateLocationRequest,
  UpsertLocationContactRequest,
} from "api/v1.0";
import dayjs from "dayjs";

export interface ILocationAddressRequestFormValues
  extends Omit<LocationAddressRequest, "state" | "country" | "city"> {
  state?: LocationAddressRequest["state"] | null;
  country?: LocationAddressRequest["country"] | null;
  city?: LocationAddressRequest["city"] | null;
}

export interface IUpsertLocationContactRequest
  extends Omit<UpsertLocationContactRequest, "dateOfBirth"> {
  dateOfBirth: Date;
}

export interface ILocationFormValues
  extends Omit<
    UpdateLocationRequest,
    "directions" | "locationContacts" | "address" | "history"
  > {
  locationContacts: IUpsertLocationContactRequest[];
  taxRate: number;
  address: ILocationAddressRequestFormValues;
  history?: string | null;
}

export const locationFormInitialValuesTemplate: ILocationFormValues = {
  name: "",
  description: "",
  address: {
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: null,
    postalCode: "",
    country: null,
    latitude: 0,
    longitude: 0,
  },
  locationAddressDisplayName: "",
  history: null,
  locationContacts: [],
  taxRate: 0,
};

export const locationContactInitialValuesTemplate: IUpsertLocationContactRequest =
  {
    firstName: "",
    lastName: "",
    email: "",
    dateOfBirth: dayjs().toDate(),
    roles: [],
    bio: "Bio",
  };
