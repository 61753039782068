import HttpClient from ".";
import { IBookingResponse, ICreateBookingRequest, IGetBookingsResponse } from "./types/booking";

const baseURL: any = process.env.REACT_APP_BASE_URL;
const httpClient = HttpClient(baseURL);

async function getBookingsAPI(yearMonth: string, page: number = 0, pageSize: number = 10, userId: string = "", paymentStatuses: string = "") {
  try {
    const data: IGetBookingsResponse = await httpClient.getMethod(
      `/v1/bookings?page=${page}&pageSize=${pageSize}&userId=${userId}&paymentStatuses=${paymentStatuses}&yearMonth=${yearMonth}`,
    );
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

async function createBookingAPI(body: ICreateBookingRequest) {
  try {
    const data: IBookingResponse = await httpClient.postMethod(
      "/v1/bookings",
      body,
    );
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

export {
  createBookingAPI,
  getBookingsAPI
};
