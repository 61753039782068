import styled from "styled-components";
import React, { ReactNode } from "react";
import { Box as MuiBox } from "@mui/material";

export const StyledBox = styled(MuiBox)<BoxProps>(
  ({ justifyContent, alignItems }) => ({
    display: "flex",
    justifyContent: justifyContent,
    alignItems: alignItems,
  }),
);

type BoxProps = {
  children: ReactNode;
  justifyContent?: "space-between" | "space-evenly" | "space-around" | "center";
  mb?: number;
  alignItems?: "center";
};

export const Box: React.FC<BoxProps> = ({
  children,
  justifyContent = "space-between",
  alignItems = "center",
  mb,
}): React.ReactElement => {
  return (
    <StyledBox justifyContent={justifyContent} mb={mb} alignItems={alignItems}>
      {children}
    </StyledBox>
  );
};

export default Box;
