import React, { useContext } from "react";
import { authContext } from "context/AuthContext";
import { Navigate, Outlet, useLocation } from "react-router-dom";

interface AuthRoutesProps {
  isPrivateRoute: boolean;
}

const AuthRoutes: React.FC<AuthRoutesProps> = ({ isPrivateRoute }) => {
  const { isLoggedIn, isAdmin, logout } = useContext(authContext);
  const { pathname } = useLocation();

  if (!isLoggedIn && isPrivateRoute) {
    return (
      <Navigate
        to={`/login?returnUrl=${encodeURIComponent(pathname)}`}
        replace
      />
    );
  }

  if (isPrivateRoute && isLoggedIn && !isAdmin) {
    logout();
    return <Navigate to="/forbidden" />;
  }

  return <Outlet />;
};

export default AuthRoutes;
