import HttpClient from ".";
import { ILodging } from "./types/lodgings";

const baseURL: any = process.env.REACT_APP_BASE_URL;
const httpClient = HttpClient(baseURL);

async function createLodgingAPI(body: ILodging) {
  try {
    const data: ILodging = await httpClient.postMethod("/v1/lodgings", body);
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

async function editLodgingAPI(id: string, body: ILodging) {
  try {
    const data: ILodging = await httpClient.putMethod(
      `/v1/lodgings/${id}`,
      body,
    );
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

async function searchLodgingsFromLocationAPI(locationId: string) {
  try {
    const data: ILodging[] = await httpClient.getMethod(
      `/v1/locations/${locationId}/lodgings`,
    );
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

async function getOneLodgingAPI(id: string) {
  try {
    const data: ILodging = await httpClient.getMethod(`/v1/lodgings/${id}`);
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

async function deleteLodgingAPI(id: string | null) {
  try {
    await httpClient.deleteMethod(`/v1/lodgings/${id}`);
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

export {
  createLodgingAPI,
  editLodgingAPI,
  searchLodgingsFromLocationAPI,
  getOneLodgingAPI,
  deleteLodgingAPI,
};
