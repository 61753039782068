import { useQuery } from "@tanstack/react-query";
import { locationApi } from "./locationService";
import AsyncStatuses from "common/types/enums/asyncStatuses";
import { locationKeys } from "./locationQueryKeyFactory";

const useQueryLocationById = (id: string) => {
  return useQuery({
    queryKey: locationKeys.detail(id),
    queryFn: () => locationApi.findLocationById({ id }),
    enabled: id.trim().length > 0,
  });
};

export const useGetLocationById = (id: string) => {
  const query = useQueryLocationById(id);

  return {
    ...query,
    data: query.data?.data,
    locationStatus: query.status as AsyncStatuses,
  };
};
