import React from "react";

import H2 from "components/atoms/H2";
import Container from "components/atoms/Container";

const NotFound = () => {
  return (
    <Container>
      <H2>Not found!</H2>
      <p>
        We couldn&rsquo;t find the page you&rsquo;re looking for. Make sure the
        URL is correct and try again.
        <br />
        <br />
        If you believe this is an error, please contact the Birddog support
        team.
      </p>
    </Container>
  );
};

export default NotFound;
