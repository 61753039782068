export type DeepNonNullable<T> = {
  [K in keyof T]: NonNullable<T[K]> extends object
    ? DeepNonNullable<T[K]>
    : NonNullable<T[K]>;
};

export const removeBlankAttributes = <T>(obj: T) => {
  const result = {} as DeepNonNullable<Required<typeof obj>>;
  if (typeof obj === "object" && !Array.isArray(obj) && obj !== null) {
    for (const key in obj) {
      const objKey = key as keyof typeof obj;
      if (obj[objKey] !== null && obj[objKey] !== undefined) {
        result[objKey] = obj[objKey];
      }
    }
  }
  return result;
};
