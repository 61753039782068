import React from "react";
import Container from "components/atoms/Container";
import { useNavigate } from "react-router-dom";
import Lodging from "components/templates/Lodging/Lodging";
import { LodgingResponse } from "api/v1.0";

function EditLodging() {
  const navigate = useNavigate();

  const handleSubmitSuccess = (lodgingResponse: LodgingResponse) => {
    navigate(`/lodging/${lodgingResponse.id}/amenities`);
  };

  const handleDeleteSuccess = (status: string) => {
    navigate("/success", {
      state: {
        title: `Lodging ${status} successfully!`,
      },
    });
  };

  return (
    <Container>
      <Lodging
        title={"Edit a lodging"}
        canEdit={true}
        onSubmitSuccess={handleSubmitSuccess}
        onDeleteSuccess={handleDeleteSuccess}
      />
    </Container>
  );
}

export default EditLodging;
