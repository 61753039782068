import React from "react";
import Container from "components/atoms/Container";
import { Box, Typography, styled } from "@mui/material";
import Button from "components/atoms/Button";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export const StyledBox = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
}));

export const StyledContainer = styled("div")(({ theme }) => ({
  minWidth: "320px",
  maxWidth: "370px",
  borderRadius: "12px",
  padding: "24px",
  boxShadow:
    "0px 5.64706px 16.94118px -2.82353px rgba(24, 39, 75, 0.08), 0px 4.23529px 8.47059px -4.23529px rgba(24, 39, 75, 0.12)",
}));

export const StyledTitle = styled(Typography)(({ theme }) => ({
  margin: "0px",
  marginBottom: "16px",
  fontWeight: "700",
  fontSize: "20px",
  lineHeight: "24px",
  color: theme.palette.blackShades.main,
}));

export const StyledParagraph = styled(Typography)(({ theme }) => ({
  color: theme.palette.greyShades.dark,
  paddingBottom: "16px",
}));

function SuccessPage() {
  const useLocationTitle = useLocation().state.title;
  const defaultTitle = "Success!";
  const title = useLocationTitle || defaultTitle;
  const navigate = useNavigate();

  return (
    <Container>
      <StyledBox>
        <StyledContainer>
          <Box>
            <StyledTitle>{title} </StyledTitle>
            <StyledParagraph>
              You can go back to your home screen to create a new package,
              location or experience. Also, you can search for this or any other
              package, location or experience
            </StyledParagraph>
            <hr />
            <Button
              qaAttribute="success-navigate-home-button"
              variant="outlined"
              color="blackShades"
              size="large"
              fullWidth
              onClick={() => {
                navigate("/");
              }}
            >
              GO TO HOME
            </Button>
          </Box>
        </StyledContainer>
      </StyledBox>
    </Container>
  );
}

export default SuccessPage;
