import React from "react";
import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  styled,
  FormControlLabelProps,
  FormControlLabel as MuiFormControllLabel,
} from "@mui/material";

interface CheckboxProps extends MuiCheckboxProps {
  label: React.ReactNode;
  labelProps?: Omit<FormControlLabelProps, "label">;
}

export const StyledCheckbox = styled(MuiCheckbox)(() => ({}));
export const FormControlLabel = styled(MuiFormControllLabel)(() => ({
  whiteSpace: "nowrap",
}));

export const Checkbox: React.FC<CheckboxProps> = ({
  label,
  labelProps = {},
  size = "medium",
  color = "primary",
  ...restProps
}): React.ReactElement => {
  return (
    <FormControlLabel
      control={<StyledCheckbox size={size} color={color} {...restProps} />}
      label={label}
    />
  );
};

export default Checkbox;
