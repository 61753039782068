import React from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { theme } from "common/styles/theme";
import Header from "components/atoms/Header";
import ApplicationRoutes from "routes/ApplicationRoutes";
import LoadingProvider from "context/LoadingContext";
import { AuthContextProvider } from "context/AuthContext";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LicenseInfo } from "@mui/x-license-pro";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

LicenseInfo.setLicenseKey(
  "674c2b5fcc4da7e1209160434b1f04c6Tz03MTIwMSxFPTE3MjE1OTAyNTYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
});

function App() {
  return (
    <AuthContextProvider>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <LoadingProvider>
              <CssBaseline />
              <Header />
              <ApplicationRoutes />
            </LoadingProvider>
          </QueryClientProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </AuthContextProvider>
  );
}

export default App;
