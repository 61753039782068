import {
  BedroomResponse,
  LodgingResponse,
  UpsertBedroomRequest,
  UpsertLodgingRequest,
} from "api/v1.0";
import {
  BedroomSchema,
  initialBedroomSchema,
  initialLodgingSchema,
  initialUpdateLodgingAmenitiesSchema,
  LodgingSchema,
  UpdateLodgingAmenitiesSchema,
} from "./lodgingTypes";
import { removeBlankAttributes } from "common/utils/tsUtils";

export const lodgingSchemaToRequest = (
  formValues: LodgingSchema,
  locationId: string,
): UpsertLodgingRequest => {
  return {
    locationId,
    ...removeBlankAttributes(formValues),
  };
};

export const lodgingResponseToSchema = ({
  bedrooms,
  ...restLodging
}: LodgingResponse): LodgingSchema => {
  return {
    ...initialLodgingSchema,
    ...removeBlankAttributes(restLodging),
    bedrooms: bedrooms
      ? bedrooms.map((bedroom) => bedroomResponseToSchema(bedroom))
      : [],
  };
};

export const lodgingResponseToUpdateLodgingAmenitiesSchema = ({
  bedrooms,
  ...restLodging
}: LodgingResponse): UpdateLodgingAmenitiesSchema => {
  return {
    ...initialUpdateLodgingAmenitiesSchema,
    ...removeBlankAttributes(restLodging),
  };
};

export const bedroomResponseToSchema = (
  bedroomResponse: BedroomResponse,
): BedroomSchema => {
  return {
    ...initialBedroomSchema,
    ...removeBlankAttributes(bedroomResponse),
  };
};

export const bedroomSchemaToRequest = (
  bedroomResponse: BedroomSchema,
): UpsertBedroomRequest => {
  return { ...bedroomResponse };
};
