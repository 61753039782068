export function formatPhoneNumber(value: string) {
    let input = value.replace(/\D/g, "");
    let formatted = "";

    if (input.length > 3) {
        if (input.length > 6) {
            formatted = `(${input.slice(0, 3)}) ${input.slice(3, 6)}-${input.slice(
                6,
                10
            )}`;
        } else {
            formatted = `(${input.slice(0, 3)}) ${input.slice(3, 6)}`;
        }
    } else {
        formatted = input;
    }

    const formattedPhoneNumber =
        formatted.length > 14 ? formatted.slice(0, 14) : formatted;

    return formattedPhoneNumber;
}