import React from "react";
import Dropzone from "react-dropzone";
import AddAPhotoOutlinedIcon from "@mui/icons-material/AddAPhotoOutlined";
import Box from "components/atoms/Box";
import {
  StyledDropContainer,
  StyledDropzone,
  StyledUIconBox,
} from "./DropzonePhoto.style";

type DropzonePhotoProps = {
  onImageUploaded: (values: File[]) => void;
};

const DropzonePhoto: React.FC<DropzonePhotoProps> = ({ onImageUploaded }) => {
  const acceptFileTypes = {
    "image/jpeg": [],
    "image/png": [],
  };

  const getThePhoto = (newPhotos: File[]) => {
    onImageUploaded(newPhotos);
  };

  return (
    <>
      <Dropzone
        data-qa="photosDropzone"
        accept={acceptFileTypes}
        onDropAccepted={(acceptedFiles) => getThePhoto(acceptedFiles)}
        multiple={false}
      >
        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
          <StyledDropContainer
            {...getRootProps(StyledDropzone)}
            isDragActive={isDragActive}
            isDragReject={isDragReject}
          >
            <input {...getInputProps()} />

            <Box justifyContent="center" alignItems="center">
              {isDragReject ? (
                <>
                  <p>Invalid format</p>
                </>
              ) : isDragActive ? (
                <>
                  <p>Upload Photo</p>
                </>
              ) : (
                <>
                  <StyledUIconBox>
                    <AddAPhotoOutlinedIcon />
                  </StyledUIconBox>
                  <p>Add Photos</p>
                </>
              )}
            </Box>
          </StyledDropContainer>
        )}
      </Dropzone>
    </>
  );
};

export default DropzonePhoto;
