/* tslint:disable */
/* eslint-disable */
/**
 * Application Backend API
 * Bird Dog 
 *
 * The version of the OpenAPI document: version
 * Contact: mail
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { AmenityResponse } from '../models';
// @ts-ignore
import { ApiErrorResponse } from '../models';
// @ts-ignore
import { LodgingResponse } from '../models';
// @ts-ignore
import { PageLodgingResponse } from '../models';
// @ts-ignore
import { UpsertLodgingRequest } from '../models';
/**
 * LodgingsAPIApi - axios parameter creator
 * @export
 */
export const LodgingsAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new lodging
         * @param {UpsertLodgingRequest} upsertLodgingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLodging: async (upsertLodgingRequest: UpsertLodgingRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'upsertLodgingRequest' is not null or undefined
            assertParamExists('createLodging', 'upsertLodgingRequest', upsertLodgingRequest)
            const localVarPath = `/v1/lodgings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertLodgingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an existing lodging by its ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLodging: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteLodging', 'id', id)
            const localVarPath = `/v1/lodgings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all the lodgings
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllLodgings: async (page?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/lodgings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific lodging by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findLodgingById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findLodgingById', 'id', id)
            const localVarPath = `/v1/lodgings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all the amenities
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAmenities: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/lodgings/amenities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an existing lodging
         * @param {string} id 
         * @param {UpsertLodgingRequest} upsertLodgingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLodging: async (id: string, upsertLodgingRequest: UpsertLodgingRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateLodging', 'id', id)
            // verify required parameter 'upsertLodgingRequest' is not null or undefined
            assertParamExists('updateLodging', 'upsertLodgingRequest', upsertLodgingRequest)
            const localVarPath = `/v1/lodgings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertLodgingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LodgingsAPIApi - functional programming interface
 * @export
 */
export const LodgingsAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LodgingsAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new lodging
         * @param {UpsertLodgingRequest} upsertLodgingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLodging(upsertLodgingRequest: UpsertLodgingRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LodgingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLodging(upsertLodgingRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LodgingsAPIApi.createLodging']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete an existing lodging by its ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLodging(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLodging(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LodgingsAPIApi.deleteLodging']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all the lodgings
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllLodgings(page?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLodgingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllLodgings(page, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LodgingsAPIApi.findAllLodgings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a specific lodging by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findLodgingById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LodgingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findLodgingById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LodgingsAPIApi.findLodgingById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all the amenities
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllAmenities(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AmenityResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllAmenities(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LodgingsAPIApi.getAllAmenities']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update an existing lodging
         * @param {string} id 
         * @param {UpsertLodgingRequest} upsertLodgingRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLodging(id: string, upsertLodgingRequest: UpsertLodgingRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LodgingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLodging(id, upsertLodgingRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LodgingsAPIApi.updateLodging']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * LodgingsAPIApi - factory interface
 * @export
 */
export const LodgingsAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LodgingsAPIApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new lodging
         * @param {LodgingsAPIApiCreateLodgingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLodging(requestParameters: LodgingsAPIApiCreateLodgingRequest, options?: RawAxiosRequestConfig): AxiosPromise<LodgingResponse> {
            return localVarFp.createLodging(requestParameters.upsertLodgingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an existing lodging by its ID
         * @param {LodgingsAPIApiDeleteLodgingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLodging(requestParameters: LodgingsAPIApiDeleteLodgingRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteLodging(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all the lodgings
         * @param {LodgingsAPIApiFindAllLodgingsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllLodgings(requestParameters: LodgingsAPIApiFindAllLodgingsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PageLodgingResponse> {
            return localVarFp.findAllLodgings(requestParameters.page, requestParameters.pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a specific lodging by id
         * @param {LodgingsAPIApiFindLodgingByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findLodgingById(requestParameters: LodgingsAPIApiFindLodgingByIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<LodgingResponse> {
            return localVarFp.findLodgingById(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all the amenities
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAmenities(options?: RawAxiosRequestConfig): AxiosPromise<Array<AmenityResponse>> {
            return localVarFp.getAllAmenities(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an existing lodging
         * @param {LodgingsAPIApiUpdateLodgingRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLodging(requestParameters: LodgingsAPIApiUpdateLodgingRequest, options?: RawAxiosRequestConfig): AxiosPromise<LodgingResponse> {
            return localVarFp.updateLodging(requestParameters.id, requestParameters.upsertLodgingRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createLodging operation in LodgingsAPIApi.
 * @export
 * @interface LodgingsAPIApiCreateLodgingRequest
 */
export interface LodgingsAPIApiCreateLodgingRequest {
    /**
     * 
     * @type {UpsertLodgingRequest}
     * @memberof LodgingsAPIApiCreateLodging
     */
    readonly upsertLodgingRequest: UpsertLodgingRequest
}

/**
 * Request parameters for deleteLodging operation in LodgingsAPIApi.
 * @export
 * @interface LodgingsAPIApiDeleteLodgingRequest
 */
export interface LodgingsAPIApiDeleteLodgingRequest {
    /**
     * 
     * @type {string}
     * @memberof LodgingsAPIApiDeleteLodging
     */
    readonly id: string
}

/**
 * Request parameters for findAllLodgings operation in LodgingsAPIApi.
 * @export
 * @interface LodgingsAPIApiFindAllLodgingsRequest
 */
export interface LodgingsAPIApiFindAllLodgingsRequest {
    /**
     * 
     * @type {number}
     * @memberof LodgingsAPIApiFindAllLodgings
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof LodgingsAPIApiFindAllLodgings
     */
    readonly pageSize?: number
}

/**
 * Request parameters for findLodgingById operation in LodgingsAPIApi.
 * @export
 * @interface LodgingsAPIApiFindLodgingByIdRequest
 */
export interface LodgingsAPIApiFindLodgingByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof LodgingsAPIApiFindLodgingById
     */
    readonly id: string
}

/**
 * Request parameters for updateLodging operation in LodgingsAPIApi.
 * @export
 * @interface LodgingsAPIApiUpdateLodgingRequest
 */
export interface LodgingsAPIApiUpdateLodgingRequest {
    /**
     * 
     * @type {string}
     * @memberof LodgingsAPIApiUpdateLodging
     */
    readonly id: string

    /**
     * 
     * @type {UpsertLodgingRequest}
     * @memberof LodgingsAPIApiUpdateLodging
     */
    readonly upsertLodgingRequest: UpsertLodgingRequest
}

/**
 * LodgingsAPIApi - object-oriented interface
 * @export
 * @class LodgingsAPIApi
 * @extends {BaseAPI}
 */
export class LodgingsAPIApi extends BaseAPI {
    /**
     * 
     * @summary Create a new lodging
     * @param {LodgingsAPIApiCreateLodgingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LodgingsAPIApi
     */
    public createLodging(requestParameters: LodgingsAPIApiCreateLodgingRequest, options?: RawAxiosRequestConfig) {
        return LodgingsAPIApiFp(this.configuration).createLodging(requestParameters.upsertLodgingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an existing lodging by its ID
     * @param {LodgingsAPIApiDeleteLodgingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LodgingsAPIApi
     */
    public deleteLodging(requestParameters: LodgingsAPIApiDeleteLodgingRequest, options?: RawAxiosRequestConfig) {
        return LodgingsAPIApiFp(this.configuration).deleteLodging(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all the lodgings
     * @param {LodgingsAPIApiFindAllLodgingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LodgingsAPIApi
     */
    public findAllLodgings(requestParameters: LodgingsAPIApiFindAllLodgingsRequest = {}, options?: RawAxiosRequestConfig) {
        return LodgingsAPIApiFp(this.configuration).findAllLodgings(requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a specific lodging by id
     * @param {LodgingsAPIApiFindLodgingByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LodgingsAPIApi
     */
    public findLodgingById(requestParameters: LodgingsAPIApiFindLodgingByIdRequest, options?: RawAxiosRequestConfig) {
        return LodgingsAPIApiFp(this.configuration).findLodgingById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all the amenities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LodgingsAPIApi
     */
    public getAllAmenities(options?: RawAxiosRequestConfig) {
        return LodgingsAPIApiFp(this.configuration).getAllAmenities(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an existing lodging
     * @param {LodgingsAPIApiUpdateLodgingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LodgingsAPIApi
     */
    public updateLodging(requestParameters: LodgingsAPIApiUpdateLodgingRequest, options?: RawAxiosRequestConfig) {
        return LodgingsAPIApiFp(this.configuration).updateLodging(requestParameters.id, requestParameters.upsertLodgingRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

