import React from "react";
import UploadFiles from "components/templates/UploadPhotoTemplatePage/UploadPhotoTemplatePage";
import useParameter from "hooks/useParameter";
import { useNavigate } from "react-router-dom";

const UploadThumbnail = () => {
  const { getPageTitle, getPageType } = useParameter();
  const pageType = getPageType();
  const navigate = useNavigate();
  const pageTitle = getPageTitle();
  const title = "Thumbnail";
  const description = `Choose the thumbnail of your ${pageTitle}`;

  const goToNextPage = (id: string) => {
    navigate(`/${pageType}/upload/images?${pageType}-id=${id}`);
  };

  return (
    <UploadFiles
      title={title}
      description={description}
      allowOneFileToSubmit={true}
      goToNextPage={goToNextPage}
      isThumbnail={true}
    ></UploadFiles>
  );
};

export default UploadThumbnail;
