import { createTheme } from "@mui/material";
import { blue, grey, orange } from "./colors";

export const theme = createTheme({
  palette: {
    primary: {
      main: orange[100],
    },
    secondary: {
      main: blue[100],
    },
    greyShades: {
      main: grey[40],
      light: grey[20],
      dark: grey[60],
    },
    lightGreyShades: {
      main: grey[10],
    },
    whiteShades: {
      main: "#FFF",
    },
    blackShades: {
      main: "#000",
    },
    success: {
      main: "#28871B",
    },
    error: {
      main: "#C62828",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
            @font-face {
              font-family: 'Roboto';
              font-style: normal;
              font-display: swap;
              font-weight: 400;
              src: local('Roboto'), local('Roboto-Regular'), url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap") format('woff2');
              unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
            }
          `,
    },
  },
});

declare module "@mui/material/styles" {
  interface Palette {
    greyShades: Palette["primary"];
    whiteShades: Palette["primary"];
    blackShades: Palette["primary"];
    lightGreyShades: Palette["primary"];
  }
  interface PaletteOptions {
    greyShades?: PaletteOptions["primary"];
    whiteShades?: PaletteOptions["primary"];
    blackShades?: PaletteOptions["primary"];
    lightGreyShades?: PaletteOptions["primary"];
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    greyShades: true;
    whiteShades: true;
    blackShades: true;
    lightGreyShades: true;
  }
}
