import {
  Stack,
  Accordion as MuiAccordion,
  AccordionSummary,
  Box,
  AccordionDetails,
  Divider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useState } from "react";
import { StyledTitle } from "components/atoms/StyledTitles";

interface Props {
  title: string;
  children: React.ReactNode;
}

const Accordion = ({ title, children }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Stack gap={3}>
      <MuiAccordion
        expanded={isExpanded}
        onChange={() => setIsExpanded((wasExpanded) => !wasExpanded)}
        sx={{
          "&.MuiAccordion-root": {
            boxShadow: "none",
            border: "none",
            "::before": {
              display: "none",
            },
          },
        }}
      >
        <AccordionSummary
          sx={{
            padding: 0,
          }}
          expandIcon={
            <Box
              sx={{
                color: isExpanded ? "black" : "white",
                background: isExpanded ? "white" : "black",
                padding: "8px",
                borderRadius: "8px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid black",
              }}
            >
              <ExpandMoreIcon sx={{ width: "24px", height: "24px" }} />
            </Box>
          }
          aria-controls="panel-content"
          id="panel-header"
        >
          <StyledTitle variant="h3">{title}</StyledTitle>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: 0,
          }}
        >
          <Stack mt={1.5} gap={3}>
            <Divider color="black" sx={{ opacity: 1 }} />
            {children}
          </Stack>
        </AccordionDetails>
      </MuiAccordion>
      {!isExpanded && <Divider color="black" sx={{ opacity: 1 }} />}
    </Stack>
  );
};

export default Accordion;
