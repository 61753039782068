import * as yup from "yup";
import { bedroomSchema, lodgingSchema } from "./loggingValidation";
import { UpsertLodgingRequest } from "api/v1.0";

export type LodgingSchema = yup.InferType<typeof lodgingSchema>;
export type BedroomSchema = yup.InferType<typeof bedroomSchema>;

export const initialLodgingSchema: LodgingSchema = {
  description: "",
  maximumCapacity: 0,
  numberOfBathrooms: 0,
  sleepingArrangementDescription: null,
  bedrooms: [],
};

export const initialBedroomSchema: BedroomSchema = {
  name: "",
  numberOfFullBeds: 0,
  numberOfKingBeds: 0,
  numberOfQueenBeds: 0,
  numberOfTwinBeds: 0,
};

export type UpdateLodgingAmenitiesSchema = Omit<
  UpsertLodgingRequest,
  "amenityIds"
> & {
  amenityIds: string[];
};

export const initialUpdateLodgingAmenitiesSchema: UpdateLodgingAmenitiesSchema =
  {
    locationId: "",
    description: "",
    maximumCapacity: 0,
    numberOfBathrooms: 0,
    amenityIds: [],
  };
