import React from "react";
import Container from "components/atoms/Container";
import { useNavigate } from "react-router-dom";
import Location from "components/templates/Location/Location";
import useParameter from "hooks/useParameter";
import ApiError from "components/atoms/ApiError";
import {
  ILocationFormValues,
  locationFromResponse,
  locationToUpdateRequest,
  useGetLocationById,
  useGetLocationContactRoles,
} from "services/location";
import { useUpdateLocation } from "services/location/useUpdateLocation";
import { useDeleteLocation } from "services/location/useDeleteLocation";

function EditLocation() {
  const navigate = useNavigate();
  const { getLocationId } = useParameter();
  const locationId = getLocationId();
  const { data: location, ...restGetLocation } = useGetLocationById(locationId);
  const { data: locationContactRoles = [] } = useGetLocationContactRoles();
  const locationContactRoleNames = locationContactRoles.map((lcr) => lcr.name!);
  const { mutate: updateLocation, ...restUpdateLocation } = useUpdateLocation();
  const { mutate: deleteLocation, ...restDeleteLocation } = useDeleteLocation();

  const goToLodgingPage = () => {
    navigate("/success", {
      state: {
        title: "Location edited successfully!",
      },
    });
  };

  const goToSuccessPage = (status: string) => {
    navigate("/success", {
      state: {
        title: `Location ${status} successfully!`,
      },
    });
  };

  const onSubmit = (event: ILocationFormValues) => {
    updateLocation(
      {
        id: locationId,
        updateLocationRequest: locationToUpdateRequest(event),
      },
      {
        onSuccess: () => {
          goToLodgingPage();
        },
      },
    );
  };

  const onDeleteLocation = () => {
    deleteLocation(
      { id: locationId },
      {
        onSuccess: () => {
          goToSuccessPage("deleted");
        },
      },
    );
  };

  return (
    <Container>
      {restGetLocation.isError && (
        <ApiError>{restGetLocation.error?.message}</ApiError>
      )}
      {restUpdateLocation.isError && (
        <ApiError>{restUpdateLocation.error?.message}</ApiError>
      )}
      {restDeleteLocation.isError && (
        <ApiError>{restDeleteLocation.error?.message}</ApiError>
      )}
      <Location
        title={"Edit a location"}
        loadedLocation={!restGetLocation.isLoading}
        initialValues={locationFromResponse(location)}
        locationContactRoleNames={locationContactRoleNames}
        getSubmitValues={onSubmit}
        canEdit={true}
        deleteLocation={onDeleteLocation}
        isEdit
        location={location}
        locationId={locationId}
      />
    </Container>
  );
}

export default EditLocation;
