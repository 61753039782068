function HttpClient(baseURL: string) {
  const getMethod = async (path: string) => {
    try {
      const idToken = localStorage.getItem("idToken");
      const headers = {};

      if (idToken) {
        Object.assign(headers, { Authorization: `Bearer ${idToken}` });
      }

      const response = await fetch(`${baseURL}${path}`, {
        method: "GET",
        mode: "cors",
        headers: {
          ...headers,
          "Content-Type": "application/json",
        },
      });

      if (response.status > 210) {
        throw new Error(
          `Error: Got ${response.status} status code from API call`,
        );
      }
      const bodyAsString = await response.text();
      if (!bodyAsString) return null;
      const data = JSON.parse(bodyAsString);
      
      return data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };

  const postMethod = async (path: string, body: any) => {
    try {
      const idToken = localStorage.getItem("idToken");
      const headers = {};

      if (idToken) {
        Object.assign(headers, { Authorization: `Bearer ${idToken}` });
      }

      const response = await fetch(`${baseURL}${path}`, {
        method: "POST",
        mode: "cors",
        headers: {
          ...headers,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      if (response.status > 210) {
        throw new Error(
          `Error: Got ${response.status} status code from API call`,
        );
      }
      const bodyAsString = await response.text();
      if (!bodyAsString) return null;
      const data = JSON.parse(bodyAsString);

      return data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };

  const putMethod = async (path: string, body: any) => {
    try {
      const idToken = localStorage.getItem("idToken");
      const headers = {};

      if (idToken) {
        Object.assign(headers, { Authorization: `Bearer ${idToken}` });
      }

      const response = await fetch(`${baseURL}${path}`, {
        method: "PUT",
        mode: "cors",
        headers: {
          ...headers,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      if (response.status > 210) {
        throw new Error(
          `Error: Got ${response.status} status code from API call`,
        );
      }
      const bodyAsString = await response.text();
      if (!bodyAsString) return null;
      const data = JSON.parse(bodyAsString);

      return data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };

  const deleteMethod = async (path: string) => {
    try {
      const idToken = localStorage.getItem("idToken");
      const headers = {};

      if (idToken) {
        Object.assign(headers, { Authorization: `Bearer ${idToken}` });
      }
      
      const response = await fetch(`${baseURL}${path}`, {
        method: "DELETE",
        mode: "cors",
        headers: {
          ...headers,
          "Content-Type": "application/json",
        },
      });

      if (response.status > 210) {
        throw new Error(
          `Error: Got ${response.status} status code from API call`,
        );
      }

      const bodyAsString = await response.text();

      if (!bodyAsString) return null;

      const data = JSON.parse(bodyAsString);
      return data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };

  return { getMethod, postMethod, putMethod, deleteMethod };
}
export default HttpClient;
