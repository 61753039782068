export default function useParameter() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const getLocationId = () => {
    return urlParams.get("location-id") ?? "";
  };

  const getLocationContactId = () => {
    return urlParams.get("location-contact-id") ?? "";
  };

  const getExperienceId = () => {
    return urlParams.get("experience-id") ?? "";
  };

  const getLodgingId = () => {
    return urlParams.get("lodging-id") ?? "";
  };

  const getPackageId = () => {
    return urlParams.get("package-id") ?? "";
  };

  const getPageType = () => {
    return window.location.pathname.split("/")[1];
  };

  const getPageTitle = () => {
    return getPageType().charAt(0).toLocaleUpperCase() + getPageType().slice(1);
  };

  return {
    getLocationId,
    getLocationContactId,
    getExperienceId,
    getPackageId,
    getPageType,
    getPageTitle,
    getLodgingId,
  };
}
