import { useQuery } from "@tanstack/react-query";
import { lodgingApi } from "./lodgingService";
import AsyncStatuses from "common/types/enums/asyncStatuses";
import { lodgingKeys } from "./lodgingKeyFactory";

const useQueryLodgingById = (id: string) => {
  return useQuery({
    queryKey: lodgingKeys.detail(id),
    queryFn: () => {
      return lodgingApi.findLodgingById({ id });
    },
    enabled: id.trim().length > 0,
  });
};

export const useGetLodgingById = (id: string) => {
  const query = useQueryLodgingById(id);

  return {
    ...query,
    data: query.data?.data,
    lodgingStatus: query.status as AsyncStatuses,
  };
};
