/* tslint:disable */
/* eslint-disable */
/**
 * Application Backend API
 * Bird Dog 
 *
 * The version of the OpenAPI document: version
 * Contact: mail
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ApiErrorResponse } from '../models';
// @ts-ignore
import { BlockedDateRequest } from '../models';
// @ts-ignore
import { BlockedDateResponse } from '../models';
// @ts-ignore
import { CreateImageRequest } from '../models';
// @ts-ignore
import { CreateLocationRequest } from '../models';
// @ts-ignore
import { ImageResponse } from '../models';
// @ts-ignore
import { LocationContactRoleDto } from '../models';
// @ts-ignore
import { LocationResponse } from '../models';
// @ts-ignore
import { LodgingResponse } from '../models';
// @ts-ignore
import { PageLocationResponse } from '../models';
// @ts-ignore
import { UpdateLocationRequest } from '../models';
/**
 * LocationsAPIApi - axios parameter creator
 * @export
 */
export const LocationsAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Block or unblock calendar dates for a given location
         * @param {string} id 
         * @param {BlockedDateRequest} blockedDateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blockOrUnblockLocationDates: async (id: string, blockedDateRequest: BlockedDateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('blockOrUnblockLocationDates', 'id', id)
            // verify required parameter 'blockedDateRequest' is not null or undefined
            assertParamExists('blockOrUnblockLocationDates', 'blockedDateRequest', blockedDateRequest)
            const localVarPath = `/v1/locations/{id}/blocked-dates`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(blockedDateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create images for a location.
         * @param {string} id 
         * @param {Array<CreateImageRequest>} createImageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createImages: async (id: string, createImageRequest: Array<CreateImageRequest>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createImages', 'id', id)
            // verify required parameter 'createImageRequest' is not null or undefined
            assertParamExists('createImages', 'createImageRequest', createImageRequest)
            const localVarPath = `/v1/locations/{id}/images`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createImageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new location
         * @param {CreateLocationRequest} createLocationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLocation: async (createLocationRequest: CreateLocationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createLocationRequest' is not null or undefined
            assertParamExists('createLocation', 'createLocationRequest', createLocationRequest)
            const localVarPath = `/v1/locations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createLocationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create image for a location contact.
         * @param {string} id 
         * @param {string} locationContactId 
         * @param {CreateImageRequest} createImageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLocationContactImage: async (id: string, locationContactId: string, createImageRequest: CreateImageRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createLocationContactImage', 'id', id)
            // verify required parameter 'locationContactId' is not null or undefined
            assertParamExists('createLocationContactImage', 'locationContactId', locationContactId)
            // verify required parameter 'createImageRequest' is not null or undefined
            assertParamExists('createLocationContactImage', 'createImageRequest', createImageRequest)
            const localVarPath = `/v1/locations/{id}/location-contact/{locationContactId}/images`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"locationContactId"}}`, encodeURIComponent(String(locationContactId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createImageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an existing image by the locationId and imageId
         * @param {string} id 
         * @param {string} imageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImage: async (id: string, imageId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteImage', 'id', id)
            // verify required parameter 'imageId' is not null or undefined
            assertParamExists('deleteImage', 'imageId', imageId)
            const localVarPath = `/v1/locations/{id}/images/{imageId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"imageId"}}`, encodeURIComponent(String(imageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an existing location by its ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLocation: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteLocation', 'id', id)
            const localVarPath = `/v1/locations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an existing image by the locationId, locationContactId and imageId
         * @param {string} id 
         * @param {string} locationContactId 
         * @param {string} imageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLocationContactImage: async (id: string, locationContactId: string, imageId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteLocationContactImage', 'id', id)
            // verify required parameter 'locationContactId' is not null or undefined
            assertParamExists('deleteLocationContactImage', 'locationContactId', locationContactId)
            // verify required parameter 'imageId' is not null or undefined
            assertParamExists('deleteLocationContactImage', 'imageId', imageId)
            const localVarPath = `/v1/locations/{id}/location-contact/{locationContactId}/images/{imageId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"locationContactId"}}`, encodeURIComponent(String(locationContactId)))
                .replace(`{${"imageId"}}`, encodeURIComponent(String(imageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all the images for a location
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllImages: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findAllImages', 'id', id)
            const localVarPath = `/v1/locations/{id}/images`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all the locations
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [name] 
         * @param {string} [city] 
         * @param {string} [state] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllLocations: async (page?: number, pageSize?: number, name?: string, city?: string, state?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/locations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (city !== undefined) {
                localVarQueryParameter['city'] = city;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all the lodgings for a location
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllLodgings1: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findAllLodgings1', 'id', id)
            const localVarPath = `/v1/locations/{id}/lodgings`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all the blocked dates for a given location
         * @param {string} id 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findLocationBlockedDates: async (id: string, startDate: string, endDate: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findLocationBlockedDates', 'id', id)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('findLocationBlockedDates', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('findLocationBlockedDates', 'endDate', endDate)
            const localVarPath = `/v1/locations/{id}/blocked-dates`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substring(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substring(0,10) :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific location by its ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findLocationById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findLocationById', 'id', id)
            const localVarPath = `/v1/locations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all the location contact roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationContactRoles: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/locations/location-contact-roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an existing location - refresh integration data
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshLocationIntegrationData: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('refreshLocationIntegrationData', 'id', id)
            const localVarPath = `/v1/locations/{id}/refresh-integration-data`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an existing location
         * @param {string} id 
         * @param {UpdateLocationRequest} updateLocationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLocation: async (id: string, updateLocationRequest: UpdateLocationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateLocation', 'id', id)
            // verify required parameter 'updateLocationRequest' is not null or undefined
            assertParamExists('updateLocation', 'updateLocationRequest', updateLocationRequest)
            const localVarPath = `/v1/locations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLocationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LocationsAPIApi - functional programming interface
 * @export
 */
export const LocationsAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LocationsAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Block or unblock calendar dates for a given location
         * @param {string} id 
         * @param {BlockedDateRequest} blockedDateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async blockOrUnblockLocationDates(id: string, blockedDateRequest: BlockedDateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BlockedDateResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.blockOrUnblockLocationDates(id, blockedDateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocationsAPIApi.blockOrUnblockLocationDates']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create images for a location.
         * @param {string} id 
         * @param {Array<CreateImageRequest>} createImageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createImages(id: string, createImageRequest: Array<CreateImageRequest>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImageResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createImages(id, createImageRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocationsAPIApi.createImages']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create a new location
         * @param {CreateLocationRequest} createLocationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLocation(createLocationRequest: CreateLocationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLocation(createLocationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocationsAPIApi.createLocation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create image for a location contact.
         * @param {string} id 
         * @param {string} locationContactId 
         * @param {CreateImageRequest} createImageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLocationContactImage(id: string, locationContactId: string, createImageRequest: CreateImageRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLocationContactImage(id, locationContactId, createImageRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocationsAPIApi.createLocationContactImage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete an existing image by the locationId and imageId
         * @param {string} id 
         * @param {string} imageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteImage(id: string, imageId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteImage(id, imageId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocationsAPIApi.deleteImage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete an existing location by its ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLocation(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLocation(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocationsAPIApi.deleteLocation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete an existing image by the locationId, locationContactId and imageId
         * @param {string} id 
         * @param {string} locationContactId 
         * @param {string} imageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLocationContactImage(id: string, locationContactId: string, imageId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLocationContactImage(id, locationContactId, imageId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocationsAPIApi.deleteLocationContactImage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all the images for a location
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllImages(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImageResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllImages(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocationsAPIApi.findAllImages']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Find all the locations
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [name] 
         * @param {string} [city] 
         * @param {string} [state] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllLocations(page?: number, pageSize?: number, name?: string, city?: string, state?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLocationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllLocations(page, pageSize, name, city, state, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocationsAPIApi.findAllLocations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all the lodgings for a location
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllLodgings1(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LodgingResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllLodgings1(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocationsAPIApi.findAllLodgings1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all the blocked dates for a given location
         * @param {string} id 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findLocationBlockedDates(id: string, startDate: string, endDate: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BlockedDateResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findLocationBlockedDates(id, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocationsAPIApi.findLocationBlockedDates']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a specific location by its ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findLocationById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findLocationById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocationsAPIApi.findLocationById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all the location contact roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocationContactRoles(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LocationContactRoleDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLocationContactRoles(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocationsAPIApi.getLocationContactRoles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update an existing location - refresh integration data
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshLocationIntegrationData(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshLocationIntegrationData(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocationsAPIApi.refreshLocationIntegrationData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update an existing location
         * @param {string} id 
         * @param {UpdateLocationRequest} updateLocationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLocation(id: string, updateLocationRequest: UpdateLocationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLocation(id, updateLocationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocationsAPIApi.updateLocation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * LocationsAPIApi - factory interface
 * @export
 */
export const LocationsAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LocationsAPIApiFp(configuration)
    return {
        /**
         * 
         * @summary Block or unblock calendar dates for a given location
         * @param {LocationsAPIApiBlockOrUnblockLocationDatesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blockOrUnblockLocationDates(requestParameters: LocationsAPIApiBlockOrUnblockLocationDatesRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<BlockedDateResponse>> {
            return localVarFp.blockOrUnblockLocationDates(requestParameters.id, requestParameters.blockedDateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create images for a location.
         * @param {LocationsAPIApiCreateImagesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createImages(requestParameters: LocationsAPIApiCreateImagesRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<ImageResponse>> {
            return localVarFp.createImages(requestParameters.id, requestParameters.createImageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new location
         * @param {LocationsAPIApiCreateLocationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLocation(requestParameters: LocationsAPIApiCreateLocationRequest, options?: RawAxiosRequestConfig): AxiosPromise<LocationResponse> {
            return localVarFp.createLocation(requestParameters.createLocationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create image for a location contact.
         * @param {LocationsAPIApiCreateLocationContactImageRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLocationContactImage(requestParameters: LocationsAPIApiCreateLocationContactImageRequest, options?: RawAxiosRequestConfig): AxiosPromise<ImageResponse> {
            return localVarFp.createLocationContactImage(requestParameters.id, requestParameters.locationContactId, requestParameters.createImageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an existing image by the locationId and imageId
         * @param {LocationsAPIApiDeleteImageRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImage(requestParameters: LocationsAPIApiDeleteImageRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteImage(requestParameters.id, requestParameters.imageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an existing location by its ID
         * @param {LocationsAPIApiDeleteLocationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLocation(requestParameters: LocationsAPIApiDeleteLocationRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteLocation(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an existing image by the locationId, locationContactId and imageId
         * @param {LocationsAPIApiDeleteLocationContactImageRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLocationContactImage(requestParameters: LocationsAPIApiDeleteLocationContactImageRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteLocationContactImage(requestParameters.id, requestParameters.locationContactId, requestParameters.imageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all the images for a location
         * @param {LocationsAPIApiFindAllImagesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllImages(requestParameters: LocationsAPIApiFindAllImagesRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<ImageResponse>> {
            return localVarFp.findAllImages(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all the locations
         * @param {LocationsAPIApiFindAllLocationsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllLocations(requestParameters: LocationsAPIApiFindAllLocationsRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PageLocationResponse> {
            return localVarFp.findAllLocations(requestParameters.page, requestParameters.pageSize, requestParameters.name, requestParameters.city, requestParameters.state, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all the lodgings for a location
         * @param {LocationsAPIApiFindAllLodgings1Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllLodgings1(requestParameters: LocationsAPIApiFindAllLodgings1Request, options?: RawAxiosRequestConfig): AxiosPromise<Array<LodgingResponse>> {
            return localVarFp.findAllLodgings1(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all the blocked dates for a given location
         * @param {LocationsAPIApiFindLocationBlockedDatesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findLocationBlockedDates(requestParameters: LocationsAPIApiFindLocationBlockedDatesRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<BlockedDateResponse>> {
            return localVarFp.findLocationBlockedDates(requestParameters.id, requestParameters.startDate, requestParameters.endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a specific location by its ID
         * @param {LocationsAPIApiFindLocationByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findLocationById(requestParameters: LocationsAPIApiFindLocationByIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<LocationResponse> {
            return localVarFp.findLocationById(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all the location contact roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationContactRoles(options?: RawAxiosRequestConfig): AxiosPromise<Array<LocationContactRoleDto>> {
            return localVarFp.getLocationContactRoles(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an existing location - refresh integration data
         * @param {LocationsAPIApiRefreshLocationIntegrationDataRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshLocationIntegrationData(requestParameters: LocationsAPIApiRefreshLocationIntegrationDataRequest, options?: RawAxiosRequestConfig): AxiosPromise<LocationResponse> {
            return localVarFp.refreshLocationIntegrationData(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an existing location
         * @param {LocationsAPIApiUpdateLocationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLocation(requestParameters: LocationsAPIApiUpdateLocationRequest, options?: RawAxiosRequestConfig): AxiosPromise<LocationResponse> {
            return localVarFp.updateLocation(requestParameters.id, requestParameters.updateLocationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for blockOrUnblockLocationDates operation in LocationsAPIApi.
 * @export
 * @interface LocationsAPIApiBlockOrUnblockLocationDatesRequest
 */
export interface LocationsAPIApiBlockOrUnblockLocationDatesRequest {
    /**
     * 
     * @type {string}
     * @memberof LocationsAPIApiBlockOrUnblockLocationDates
     */
    readonly id: string

    /**
     * 
     * @type {BlockedDateRequest}
     * @memberof LocationsAPIApiBlockOrUnblockLocationDates
     */
    readonly blockedDateRequest: BlockedDateRequest
}

/**
 * Request parameters for createImages operation in LocationsAPIApi.
 * @export
 * @interface LocationsAPIApiCreateImagesRequest
 */
export interface LocationsAPIApiCreateImagesRequest {
    /**
     * 
     * @type {string}
     * @memberof LocationsAPIApiCreateImages
     */
    readonly id: string

    /**
     * 
     * @type {Array<CreateImageRequest>}
     * @memberof LocationsAPIApiCreateImages
     */
    readonly createImageRequest: Array<CreateImageRequest>
}

/**
 * Request parameters for createLocation operation in LocationsAPIApi.
 * @export
 * @interface LocationsAPIApiCreateLocationRequest
 */
export interface LocationsAPIApiCreateLocationRequest {
    /**
     * 
     * @type {CreateLocationRequest}
     * @memberof LocationsAPIApiCreateLocation
     */
    readonly createLocationRequest: CreateLocationRequest
}

/**
 * Request parameters for createLocationContactImage operation in LocationsAPIApi.
 * @export
 * @interface LocationsAPIApiCreateLocationContactImageRequest
 */
export interface LocationsAPIApiCreateLocationContactImageRequest {
    /**
     * 
     * @type {string}
     * @memberof LocationsAPIApiCreateLocationContactImage
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof LocationsAPIApiCreateLocationContactImage
     */
    readonly locationContactId: string

    /**
     * 
     * @type {CreateImageRequest}
     * @memberof LocationsAPIApiCreateLocationContactImage
     */
    readonly createImageRequest: CreateImageRequest
}

/**
 * Request parameters for deleteImage operation in LocationsAPIApi.
 * @export
 * @interface LocationsAPIApiDeleteImageRequest
 */
export interface LocationsAPIApiDeleteImageRequest {
    /**
     * 
     * @type {string}
     * @memberof LocationsAPIApiDeleteImage
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof LocationsAPIApiDeleteImage
     */
    readonly imageId: string
}

/**
 * Request parameters for deleteLocation operation in LocationsAPIApi.
 * @export
 * @interface LocationsAPIApiDeleteLocationRequest
 */
export interface LocationsAPIApiDeleteLocationRequest {
    /**
     * 
     * @type {string}
     * @memberof LocationsAPIApiDeleteLocation
     */
    readonly id: string
}

/**
 * Request parameters for deleteLocationContactImage operation in LocationsAPIApi.
 * @export
 * @interface LocationsAPIApiDeleteLocationContactImageRequest
 */
export interface LocationsAPIApiDeleteLocationContactImageRequest {
    /**
     * 
     * @type {string}
     * @memberof LocationsAPIApiDeleteLocationContactImage
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof LocationsAPIApiDeleteLocationContactImage
     */
    readonly locationContactId: string

    /**
     * 
     * @type {string}
     * @memberof LocationsAPIApiDeleteLocationContactImage
     */
    readonly imageId: string
}

/**
 * Request parameters for findAllImages operation in LocationsAPIApi.
 * @export
 * @interface LocationsAPIApiFindAllImagesRequest
 */
export interface LocationsAPIApiFindAllImagesRequest {
    /**
     * 
     * @type {string}
     * @memberof LocationsAPIApiFindAllImages
     */
    readonly id: string
}

/**
 * Request parameters for findAllLocations operation in LocationsAPIApi.
 * @export
 * @interface LocationsAPIApiFindAllLocationsRequest
 */
export interface LocationsAPIApiFindAllLocationsRequest {
    /**
     * 
     * @type {number}
     * @memberof LocationsAPIApiFindAllLocations
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof LocationsAPIApiFindAllLocations
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof LocationsAPIApiFindAllLocations
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof LocationsAPIApiFindAllLocations
     */
    readonly city?: string

    /**
     * 
     * @type {string}
     * @memberof LocationsAPIApiFindAllLocations
     */
    readonly state?: string
}

/**
 * Request parameters for findAllLodgings1 operation in LocationsAPIApi.
 * @export
 * @interface LocationsAPIApiFindAllLodgings1Request
 */
export interface LocationsAPIApiFindAllLodgings1Request {
    /**
     * 
     * @type {string}
     * @memberof LocationsAPIApiFindAllLodgings1
     */
    readonly id: string
}

/**
 * Request parameters for findLocationBlockedDates operation in LocationsAPIApi.
 * @export
 * @interface LocationsAPIApiFindLocationBlockedDatesRequest
 */
export interface LocationsAPIApiFindLocationBlockedDatesRequest {
    /**
     * 
     * @type {string}
     * @memberof LocationsAPIApiFindLocationBlockedDates
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof LocationsAPIApiFindLocationBlockedDates
     */
    readonly startDate: string

    /**
     * 
     * @type {string}
     * @memberof LocationsAPIApiFindLocationBlockedDates
     */
    readonly endDate: string
}

/**
 * Request parameters for findLocationById operation in LocationsAPIApi.
 * @export
 * @interface LocationsAPIApiFindLocationByIdRequest
 */
export interface LocationsAPIApiFindLocationByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof LocationsAPIApiFindLocationById
     */
    readonly id: string
}

/**
 * Request parameters for refreshLocationIntegrationData operation in LocationsAPIApi.
 * @export
 * @interface LocationsAPIApiRefreshLocationIntegrationDataRequest
 */
export interface LocationsAPIApiRefreshLocationIntegrationDataRequest {
    /**
     * 
     * @type {string}
     * @memberof LocationsAPIApiRefreshLocationIntegrationData
     */
    readonly id: string
}

/**
 * Request parameters for updateLocation operation in LocationsAPIApi.
 * @export
 * @interface LocationsAPIApiUpdateLocationRequest
 */
export interface LocationsAPIApiUpdateLocationRequest {
    /**
     * 
     * @type {string}
     * @memberof LocationsAPIApiUpdateLocation
     */
    readonly id: string

    /**
     * 
     * @type {UpdateLocationRequest}
     * @memberof LocationsAPIApiUpdateLocation
     */
    readonly updateLocationRequest: UpdateLocationRequest
}

/**
 * LocationsAPIApi - object-oriented interface
 * @export
 * @class LocationsAPIApi
 * @extends {BaseAPI}
 */
export class LocationsAPIApi extends BaseAPI {
    /**
     * 
     * @summary Block or unblock calendar dates for a given location
     * @param {LocationsAPIApiBlockOrUnblockLocationDatesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsAPIApi
     */
    public blockOrUnblockLocationDates(requestParameters: LocationsAPIApiBlockOrUnblockLocationDatesRequest, options?: RawAxiosRequestConfig) {
        return LocationsAPIApiFp(this.configuration).blockOrUnblockLocationDates(requestParameters.id, requestParameters.blockedDateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create images for a location.
     * @param {LocationsAPIApiCreateImagesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsAPIApi
     */
    public createImages(requestParameters: LocationsAPIApiCreateImagesRequest, options?: RawAxiosRequestConfig) {
        return LocationsAPIApiFp(this.configuration).createImages(requestParameters.id, requestParameters.createImageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new location
     * @param {LocationsAPIApiCreateLocationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsAPIApi
     */
    public createLocation(requestParameters: LocationsAPIApiCreateLocationRequest, options?: RawAxiosRequestConfig) {
        return LocationsAPIApiFp(this.configuration).createLocation(requestParameters.createLocationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create image for a location contact.
     * @param {LocationsAPIApiCreateLocationContactImageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsAPIApi
     */
    public createLocationContactImage(requestParameters: LocationsAPIApiCreateLocationContactImageRequest, options?: RawAxiosRequestConfig) {
        return LocationsAPIApiFp(this.configuration).createLocationContactImage(requestParameters.id, requestParameters.locationContactId, requestParameters.createImageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an existing image by the locationId and imageId
     * @param {LocationsAPIApiDeleteImageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsAPIApi
     */
    public deleteImage(requestParameters: LocationsAPIApiDeleteImageRequest, options?: RawAxiosRequestConfig) {
        return LocationsAPIApiFp(this.configuration).deleteImage(requestParameters.id, requestParameters.imageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an existing location by its ID
     * @param {LocationsAPIApiDeleteLocationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsAPIApi
     */
    public deleteLocation(requestParameters: LocationsAPIApiDeleteLocationRequest, options?: RawAxiosRequestConfig) {
        return LocationsAPIApiFp(this.configuration).deleteLocation(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an existing image by the locationId, locationContactId and imageId
     * @param {LocationsAPIApiDeleteLocationContactImageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsAPIApi
     */
    public deleteLocationContactImage(requestParameters: LocationsAPIApiDeleteLocationContactImageRequest, options?: RawAxiosRequestConfig) {
        return LocationsAPIApiFp(this.configuration).deleteLocationContactImage(requestParameters.id, requestParameters.locationContactId, requestParameters.imageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all the images for a location
     * @param {LocationsAPIApiFindAllImagesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsAPIApi
     */
    public findAllImages(requestParameters: LocationsAPIApiFindAllImagesRequest, options?: RawAxiosRequestConfig) {
        return LocationsAPIApiFp(this.configuration).findAllImages(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all the locations
     * @param {LocationsAPIApiFindAllLocationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsAPIApi
     */
    public findAllLocations(requestParameters: LocationsAPIApiFindAllLocationsRequest = {}, options?: RawAxiosRequestConfig) {
        return LocationsAPIApiFp(this.configuration).findAllLocations(requestParameters.page, requestParameters.pageSize, requestParameters.name, requestParameters.city, requestParameters.state, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all the lodgings for a location
     * @param {LocationsAPIApiFindAllLodgings1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsAPIApi
     */
    public findAllLodgings1(requestParameters: LocationsAPIApiFindAllLodgings1Request, options?: RawAxiosRequestConfig) {
        return LocationsAPIApiFp(this.configuration).findAllLodgings1(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all the blocked dates for a given location
     * @param {LocationsAPIApiFindLocationBlockedDatesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsAPIApi
     */
    public findLocationBlockedDates(requestParameters: LocationsAPIApiFindLocationBlockedDatesRequest, options?: RawAxiosRequestConfig) {
        return LocationsAPIApiFp(this.configuration).findLocationBlockedDates(requestParameters.id, requestParameters.startDate, requestParameters.endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a specific location by its ID
     * @param {LocationsAPIApiFindLocationByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsAPIApi
     */
    public findLocationById(requestParameters: LocationsAPIApiFindLocationByIdRequest, options?: RawAxiosRequestConfig) {
        return LocationsAPIApiFp(this.configuration).findLocationById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all the location contact roles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsAPIApi
     */
    public getLocationContactRoles(options?: RawAxiosRequestConfig) {
        return LocationsAPIApiFp(this.configuration).getLocationContactRoles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an existing location - refresh integration data
     * @param {LocationsAPIApiRefreshLocationIntegrationDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsAPIApi
     */
    public refreshLocationIntegrationData(requestParameters: LocationsAPIApiRefreshLocationIntegrationDataRequest, options?: RawAxiosRequestConfig) {
        return LocationsAPIApiFp(this.configuration).refreshLocationIntegrationData(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an existing location
     * @param {LocationsAPIApiUpdateLocationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationsAPIApi
     */
    public updateLocation(requestParameters: LocationsAPIApiUpdateLocationRequest, options?: RawAxiosRequestConfig) {
        return LocationsAPIApiFp(this.configuration).updateLocation(requestParameters.id, requestParameters.updateLocationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

