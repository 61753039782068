import React, { useContext, useEffect, useState } from "react";
import Container from "components/atoms/Container";
import { useNavigate } from "react-router-dom";
import { LoadingContext } from "context/LoadingContext";
import H3 from "components/atoms/H3";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ISearchExperiencesResponse } from "services/types/experience";
import {
  getUnavailabilityDates,
  searchExperiencesAPI,
} from "services/experiencesService";
import {
  Autocomplete,
  Box,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Button from "components/atoms/Button";
import { getOnePackageAPI, listPackagesAPI } from "services/packagesService";
import { DatePicker, DateRangePicker } from "@mui/x-date-pickers-pro";
import dayjs from "dayjs";
import { createBookingAPI } from "services/bookingService";
import { formatPhoneNumber } from "common/utils/formatCodes";
import FmdBadIcon from "@mui/icons-material/FmdBad";
import { IPackageResponse } from "services/types/packages";
import { locationApi } from "services/location";

export const createBookingInitialValues = {
  location: { label: "", id: "" },
  experience: { label: "", id: "" },
  package: { label: "", id: "" },
  bookingDates: [],
  participantCount: 0,
  nonParticipantCount: 0,
  firstName: "",
  lastName: "",
  email: "",
  dateOfBirth: "",
  phoneNumber: "",
};

const schema = yup.object().shape({
  location: yup
    .object()
    .required("Choose one location")
    .shape({
      id: yup.string(),
      label: yup.string(),
    })
    .test("validateLocation", (value, { createError }) => {
      if (value.id === "") {
        return createError({ message: "Location is required" });
      }
      return true;
    }),
  experience: yup
    .object()
    .required("Choose one experience")
    .shape({
      id: yup.string(),
      label: yup.string(),
    })
    .test("validateExperience", (value, { createError }) => {
      if (value.id === "") {
        return createError({ message: "Experience is required" });
      }
      return true;
    }),
  package: yup
    .object()
    .required("Choose one package")
    .shape({
      id: yup.string(),
      label: yup.string(),
    })
    .test("validatePackage", (value, { createError }) => {
      if (value.id === "") {
        return createError({ message: "Package is required" });
      }
      return true;
    }),
  bookingDates: yup
    .array()
    .required("Date is required")
    .test("validateDate", (value, { createError }) => {
      if (value.length === 0) {
        return createError({ message: "Date is required" });
      } else if (value[0] === null) {
        return createError({ message: "Start date is required" });
      } else if (value[1] === null) {
        return createError({ message: "End date is required" });
      }
      return true;
    }),
  participantCount: yup
    .number()
    .required("Hunting guest is a required field")
    .min(1, "There shouldn't be less than 1 hunting guests")
    .max(99, "There shouldn't be more than 99 hunting guests")
    .typeError("Hunting guest must be a number"),
  nonParticipantCount: yup
    .number()
    .required("Non-Hunting guest is a required field")
    .max(99, "There shouldn't be more than 99 non hunting guests")
    .typeError("Non hunting guest must be a number"),
  firstName: yup
    .string()
    .required("First Name is required")
    .min(2, "First Name must be at least 2 characters long")
    .max(40, "First Name is too long"),
  lastName: yup
    .string()
    .required("Last Name is required")
    .min(2, "Last Name must be at least 2 characters long")
    .max(40, "Last Name is too long"),
  email: yup
    .string()
    .email("Please enter a valid email address")
    .required("Email is required")
    .min(5, "Please enter a valid email address"),
  dateOfBirth: yup
    .date()
    .typeError("Please enter a valid date")
    .test("test age", "You must be at least 18 years old", (birthday) => {
      const age = dayjs().diff(birthday, "years");
      const isLegal = age >= 18;
      return isLegal;
    })
    .required(),
  phoneNumber: yup
    .string()
    .required("Phone is required")
    .min(14, "The phone number is not valid"),
});

const CreateBooking = () => {
  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
  } = useForm({
    mode: "onChange",
    /* @ts-ignore */
    resolver: yupResolver(schema),
    defaultValues: createBookingInitialValues,
  });

  const [autoCompleteLocations, setAutoCompleteLocations] = useState([
    { label: "", id: "" },
  ]);
  const [autoCompleteExperiences, setAutoCompletExperiences] = useState([
    { label: "", id: "" },
  ]);
  const [autoCompletePackages, setAutoCompletPackages] = useState([
    { label: "", id: "" },
  ]);
  const [locationId, setLocationId] = useState("");
  const [experienceId, setExperienceId] = useState("");
  const [selectedPackageInfo, setSelectedPackageInfo] = useState(
    {} as IPackageResponse,
  );
  const navigate = useNavigate();
  const { setLoading } = useContext(LoadingContext);
  const [unavailabilityDates, setUnavailabilityDates] = useState(
    [] as string[],
  );

  useEffect(() => {
    async function loadLocations() {
      try {
        setLoading(true);
        const locationsList = (
          await locationApi.findAllLocations({
            page: 0,
            pageSize: 100,
          })
        ).data;
        const locationsListFiltered = locationsList.content!.map(
          (location) => ({
            label: location.name!,
            id: location.id!,
          }),
        );
        setAutoCompleteLocations(locationsListFiltered);
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    }

    loadLocations();
  }, [setLoading]);

  useEffect(() => {
    async function loadExperience() {
      try {
        setLoading(true);
        const experiencesList: ISearchExperiencesResponse =
          await searchExperiencesAPI(0, 10, true, "", "", "", locationId);
        const experiencesListFiltered = experiencesList.content.map(
          (location: { id: string; name: string }) => ({
            label: location.name,
            id: location.id,
          }),
        );
        setAutoCompletExperiences(experiencesListFiltered);
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    }

    if (locationId) {
      loadExperience();
    }
  }, [locationId, setLoading]);

  useEffect(() => {
    async function loadPackages() {
      try {
        setLoading(true);
        const packagesList = await listPackagesAPI(0, 100, experienceId, true);
        const packageListFiltered = packagesList.content.map(
          (item: { id: string; title: string }) => ({
            label: item.title,
            id: item.id,
          }),
        );
        setAutoCompletPackages(packageListFiltered);
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    }

    loadPackages();
  }, [setLoading, experienceId]);

  useEffect(() => {
    async function getBlockedDates() {
      const yesterday = dayjs().subtract(1, "day");
      const oneYearForNow = dayjs().add(1, "year");
      const formatedInitalStartDate = yesterday.format("YYYY-MM-DD");
      const formatedInitalEndDate = oneYearForNow.format("YYYY-MM-DD");
      setLoading(true);
      const unavailabilityDatesResponse = await getUnavailabilityDates(
        experienceId,
        formatedInitalStartDate,
        formatedInitalEndDate,
      );
      setUnavailabilityDates(unavailabilityDatesResponse);
      setLoading(false);
    }
    if (experienceId) {
      getBlockedDates();
    } else {
      setExperienceId("");
    }
  }, [experienceId, setLoading]);

  const disableBlockDates = (date: any) => {
    const disableBlockDatesArray: any = [];
    unavailabilityDates.forEach((element: any) => {
      disableBlockDatesArray.push(element);
    });
    return disableBlockDatesArray.includes(date.format("YYYY-MM-DD"));
  };

  const getPackageInfo = async (packageId: string) => {
    console.log("packageId");
    try {
      const packageInfo = await getOnePackageAPI(packageId);
      setSelectedPackageInfo(packageInfo);
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmitForm = async () => {
    const valuesForm = getValues();
    const body = {
      packageId: valuesForm.package.id,
      startDate: new Date(valuesForm.bookingDates[0])
        .toISOString()
        .split("T")[0],
      endDate: new Date(valuesForm.bookingDates[1]).toISOString().split("T")[0],
      participantCount: valuesForm.participantCount,
      nonParticipantCount: valuesForm.nonParticipantCount,
      userDetails: {
        firstName: valuesForm.firstName,
        lastName: valuesForm.lastName,
        email: valuesForm.email,
        dateOfBirth: new Date(valuesForm.dateOfBirth)
          .toISOString()
          .split("T")[0],
        phoneNumber: valuesForm.phoneNumber,
      },
    };
    try {
      await createBookingAPI(body);
      goToSuccessPage();
    } catch (error) {
      console.log(error);
    }
  };

  const goToSuccessPage = () => {
    navigate("/success", {
      state: {
        title: "You created a new booking successfully!",
      },
    });
  };

  return (
    <Container>
      <H3 fontWeight="700">Create Booking</H3>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Controller
          name="location"
          control={control}
          render={({ field }) => (
            <Autocomplete
              disablePortal
              freeSolo
              forcePopupIcon={true}
              onBlur={() => {
                if (field.value) {
                  setLocationId(field.value.id ? field.value.id : "");
                }
              }}
              onChange={(event, value: any) => {
                return field.onChange(value);
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="location"
              options={autoCompleteLocations}
              isOptionEqualToValue={(option: any, value: any) => {
                return option === value;
              }}
              renderInput={(params) => (
                <Box mb={2}>
                  <TextField
                    {...params}
                    label="Choose a location *"
                    inputRef={field.ref}
                    error={!!errors.location}
                    helperText={
                      errors.location ? errors.location.message : null
                    }
                  />
                </Box>
              )}
            />
          )}
        />
        <Controller
          name="experience"
          control={control}
          render={({ field }) => (
            <Autocomplete
              disablePortal
              freeSolo
              forcePopupIcon={true}
              onChange={(event, value) => field.onChange(value)}
              onBlur={() => {
                if (field.value) {
                  setExperienceId(field.value.id ? field.value.id : "");
                }
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="experience"
              options={autoCompleteExperiences}
              isOptionEqualToValue={(option: any, value: any) => {
                return option === value;
              }}
              renderInput={(params) => (
                <Box mb={2}>
                  <TextField
                    {...params}
                    label="Choose an experience *"
                    inputRef={field.ref}
                    error={!!errors.experience}
                    helperText={
                      errors.experience ? errors.experience.message : null
                    }
                  />
                </Box>
              )}
            />
          )}
        />
        <Controller
          name="package"
          control={control}
          render={({ field }) => (
            <Autocomplete
              disablePortal
              freeSolo
              forcePopupIcon={true}
              onChange={(event, value) => field.onChange(value)}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="package"
              options={autoCompletePackages}
              isOptionEqualToValue={(option: any, value: any) => {
                return option === value;
              }}
              onBlur={() => {
                if (field.value) {
                  getPackageInfo(field.value.id);
                }
              }}
              renderInput={(params) => (
                <Box mb={2}>
                  <TextField
                    {...params}
                    label="Choose an package *"
                    inputRef={field.ref}
                    error={!!errors.experience}
                    helperText={
                      errors.experience ? errors.experience.message : null
                    }
                  />
                </Box>
              )}
            />
          )}
        />
        {Object.keys(selectedPackageInfo).length !== 0 && (
          <Paper>
            <Box pt={2} pb={2} ml={2} mb={2}>
              <Stack direction="row" spacing={1} alignItems="center">
                <FmdBadIcon fontSize="small" />
                <Typography>
                  At this package, the following prerequisites are required:
                </Typography>
              </Stack>
              <Box ml={3}>
                <Typography fontSize="15px">
                  - Reservation Days: {selectedPackageInfo.minReservationDays}{" "}
                  to {selectedPackageInfo.maxReservationDays} days
                </Typography>
                <Typography fontSize="15px">
                  - Hunting Guest: {selectedPackageInfo.minAmountOfGuests} to{" "}
                  {selectedPackageInfo.maxAmountOfGuests} guests
                </Typography>
              </Box>
            </Box>
          </Paper>
        )}
        <Box mb={2}>
          <Controller
            name="bookingDates"
            control={control}
            render={({ field }) => (
              <>
                <DateRangePicker
                  disablePast
                  inputRef={field.ref}
                  onChange={(date) => {
                    field.onChange(date);
                  }}
                  shouldDisableDate={disableBlockDates}
                  localeText={{ start: "Start Date *", end: "End Date *" }}
                  slotProps={{
                    textField: {
                      variant: "outlined",
                      error: !!errors.bookingDates,
                      helperText: errors.bookingDates?.message,
                    },
                  }}
                />
              </>
            )}
          />
        </Box>
        <Grid container spacing={4} mb={2}>
          <Grid item xs={6}>
            <Controller
              name="participantCount"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Hunting guest *"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  helperText={
                    errors.participantCount
                      ? errors.participantCount.message
                      : null
                  }
                  type={"number"}
                  error={!!errors.participantCount}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="nonParticipantCount"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Non-Hunting guest *"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  helperText={
                    errors.nonParticipantCount
                      ? errors.nonParticipantCount.message
                      : null
                  }
                  type={"number"}
                  error={!!errors.nonParticipantCount}
                />
              )}
            />
          </Grid>
        </Grid>
        <Box mb={2}>
          <Controller
            name="firstName"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                label="First Name *"
                fullWidth={true}
                error={!!errors.firstName}
                helperText={errors.firstName ? errors.firstName.message : null}
              />
            )}
          />
        </Box>
        <Box mb={2}>
          <Controller
            name="lastName"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Last Name *"
                fullWidth={true}
                error={!!errors.lastName}
                helperText={errors.lastName ? errors.lastName.message : null}
              />
            )}
          />
        </Box>
        <Box mb={2}>
          <Controller
            name="email"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Email *"
                fullWidth={true}
                error={!!errors.email}
                helperText={errors.email ? errors.email.message : null}
              />
            )}
          />
        </Box>
        <Box mb={2}>
          <Controller
            name="dateOfBirth"
            control={control}
            render={({ field: { onChange } }) => (
              <DatePicker
                label="Date of Birth"
                onChange={(newValue: any) => {
                  onChange(dayjs(newValue).format("MM/DD/YYYY"));
                }}
                sx={{ width: "100%" }}
                slotProps={{
                  textField: {
                    error: !!errors.dateOfBirth,
                    helperText: errors.dateOfBirth
                      ? errors.dateOfBirth.message
                      : null,
                  },
                }}
                // inputFormat="MM/DD/YYYY"
              />
            )}
          />
        </Box>
        <Box mb={2}>
          <Controller
            name="phoneNumber"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <TextField
                name="phoneNumber"
                id="payment-phone"
                label="Phone Number"
                fullWidth
                value={formatPhoneNumber(value) || ""}
                onChange={(e) => onChange(e.target.value)}
                error={!!errors.phoneNumber}
                helperText={
                  errors.phoneNumber ? errors.phoneNumber.message : null
                }
              />
            )}
          />
        </Box>
        <Grid container spacing={1} pb={1}>
          <Grid item xs={6}>
            <Button
              type="button"
              color="blackShades"
              variant="outlined"
              size="large"
              fullWidth={true}
              onClick={() => {
                navigate("/");
              }}
              qaAttribute="package-skip-button"
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth={true}
              type={"submit"}
              size="large"
              variant="contained"
              qaAttribute="package-continue-button"
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default CreateBooking;
