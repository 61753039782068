import React, { ReactNode } from "react";
import { Box } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import ButtonUnderlineText from "components/atoms/ButtonUnderlineText";

type ButtonProps = {
  children: ReactNode;
  lockerClose: boolean;
  onClick?: () => void;
  qaAttribute: string;
};

export const ButtonEditWithLocker: React.FC<ButtonProps> = ({
  children,
  lockerClose = true,
  onClick,
  qaAttribute,
}): React.ReactElement => {
  return (
    <>
      <Box display="flex" gap={1} mb={2}>
        {lockerClose ? (
          <LockOpenOutlinedIcon fontSize="small" />
        ) : (
          <LockOutlinedIcon fontSize="small" />
        )}

        <ButtonUnderlineText onClick={onClick} qaAttribute={qaAttribute}>
          {children}
        </ButtonUnderlineText>
      </Box>
    </>
  );
};

export default ButtonEditWithLocker;
