import React, { useContext, useEffect, useState } from "react";
import Container from "components/atoms/Container";
import { Box, Typography } from "@mui/material";
import { StyledTitle } from "./SearchPackage.style";
import Button from "components/atoms/Button";
import { LoadingContext } from "context/LoadingContext";
import useParameter from "hooks/useParameter";
import { listPackagesAPI } from "services/packagesService";
import SearchCard from "components/atoms/SearchCard/SearchCard";
import { useNavigate } from "react-router-dom";
import { IPackageResponse } from "services/types/packages";

const SearchPackage: React.FC = () => {
  const navigate = useNavigate();
  const { getPageType, getPageTitle, getExperienceId } = useParameter();
  const [packages, setPackages] = useState([] as any);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const type = getPageType();
  const title = getPageTitle();
  const { setLoading } = useContext(LoadingContext);
  const experienceId = getExperienceId();

  useEffect(() => {
    async function loadExperiences() {
      try {
        setLoading(true);
        const experiencesList = await listPackagesAPI(
          page,
          100,
          experienceId,
          true,
        );
        setPackages(experiencesList.content);
        setTotalPages(experiencesList.totalPages);
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    }

    loadExperiences();
  }, [page, setLoading, experienceId]);

  const loadMore = () => {
    setPage(page + 1);
  };

  return (
    <Container>
      <StyledTitle>Search Results</StyledTitle>
      <Typography mb={3} ml={3}>
        {title}
      </Typography>
      <Box display="flex" flexWrap="wrap" justifyContent="space-around">
        {packages !== undefined &&
          packages.map((element: IPackageResponse, i: number) => {
            return (
              <SearchCard
                title={element.title}
                showLocation={false}
                type="package"
                key={i}
                onClick={() => {
                  navigate(`/${type}/edit?${type}-id=${element.id}`, {
                    state: {
                      data: element,
                    },
                  });
                }}
              />
            );
          })}
      </Box>
      {page + 1 < totalPages && (
        <Box display="flex" justifyContent="center">
          <Button
            variant="text"
            qaAttribute="button-load-more"
            color="blackShades"
            onClick={loadMore}
          >
            Load more experiences
          </Button>
        </Box>
      )}
    </Container>
  );
};

export default SearchPackage;
