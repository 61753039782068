import React, { useState } from "react";
import Container from "components/atoms/Container";
import UploadPhotos from "components/organisms/DropzonePhoto/DropzonePhoto";
import { Box, Button, FormHelperText, Grid, Typography } from "@mui/material";
import H3 from "components/atoms/H3";
import UploadPhotoCard from "components/molecules/UploadPhotoCard/UploadedPhotoCard";
import { IFile } from "common/types/interfaces/global";
import { useNavigate } from "react-router-dom";
import useParameter from "hooks/useParameter";
import ApiError from "components/atoms/ApiError";
import Compressor from "compressorjs";
import { CreateImageRequest, ImageResponse } from "api/v1.0";
import { useCreateExperienceImages } from "services/experience";
import { useCreateLocationImages } from "services/location";
import { useCreateLocationContactImages } from "services/location/useCreateLocationContactImages";

interface UploadImage extends CreateImageRequest {
  imageFile: File;
}

interface UploadPhotoTemplatePageProps {
  title: string;
  description: string;
  goToNextPage: (id: string) => void;
  allowOneFileToSubmit: boolean;
  isThumbnail?: boolean;
}

type PageTypeType = "experience" | "location" | "location-contact";
type PageTypeImageSubmitHandler = (
  id: string,
  images: CreateImageRequest[],
) => Promise<ImageResponse[]>;

interface ImageCreationState {
  createError: Error | null;
  isCreateError: boolean;
}

const UploadPhotoTemplatePage: React.FC<UploadPhotoTemplatePageProps> = ({
  title,
  description,
  goToNextPage,
  allowOneFileToSubmit,
  isThumbnail = false,
}) => {
  const navigate = useNavigate();
  const { getLocationId, getExperienceId, getPageType, getLocationContactId } =
    useParameter();
  const pageType = getPageType();
  const [locationContactId, experienceId, locationId] = [
    getLocationContactId(),
    getExperienceId(),
    getLocationId(),
  ];
  const [photos, setPhotos] = useState([] as IFile[]);
  const [errorMessage, setErrorMessage] = useState("");

  const { mutateAsync: createExperienceImages, ...restCreateExperienceImages } =
    useCreateExperienceImages();
  const { mutateAsync: createLocationImages, ...restCreateLocationImages } =
    useCreateLocationImages();
  const {
    mutateAsync: createLocationContactImages,
    ...restCreateLocationContactImages
  } = useCreateLocationContactImages();

  const pageTypeCreateImageStates: Record<PageTypeType, ImageCreationState> = {
    "location-contact": {
      createError: restCreateLocationContactImages.error,
      isCreateError: restCreateLocationContactImages.isError,
    },
    experience: {
      createError: restCreateExperienceImages.error,
      isCreateError: restCreateExperienceImages.isError,
    },
    location: {
      createError: restCreateLocationImages.error,
      isCreateError: restCreateLocationImages.isError,
    },
  };

  const pageTypeSubmitHandlers: Record<
    PageTypeType,
    PageTypeImageSubmitHandler
  > = {
    "location-contact": async (id, images) => {
      return [
        (
          await createLocationContactImages({
            locationContactId: id,
            createImageRequest: images[0],
            id: locationId,
          })
        ).data,
      ];
    },
    experience: async (id, images) => {
      return (await createExperienceImages({ id, createImageRequest: images }))
        .data;
    },
    location: async (id, images) => {
      return (await createLocationImages({ id, createImageRequest: images }))
        .data;
    },
  };

  const canUpdateMoreImage = () => {
    if (photos.length > 0 && allowOneFileToSubmit) {
      setErrorMessage("You can upload just one photo as thumbnail");
      return false;
    } else if (photos.length === 14) {
      setErrorMessage("You can add up to 14 images");
      return false;
    } else {
      setErrorMessage("");
      return true;
    }
  };

  const onImageUploaded = async (newArrayOfPhotos: File[]) => {
    const firstPhoto = newArrayOfPhotos[0];
    setErrorMessage("");

    if (!canUpdateMoreImage()) return;

    const newPhotoCompressed = (await compressPhoto(firstPhoto)) as File;
    const newPhotoWithPreview: IFile =
      createPhotoObjWithPreview(newPhotoCompressed);

    const isEqual = isPhotoAlreadyUploaded(newPhotoWithPreview);
    if (isEqual) {
      setErrorMessage("You cannot upload the same image twice");
      return;
    }

    setPhotos((uploadedPhotos) => [...uploadedPhotos, newPhotoWithPreview]);
  };

  const createPhotoObjWithPreview = (photo: File) => {
    const binaryData = [];
    binaryData.push(photo);
    const createNewPhotosPreview = Object.assign(photo, {
      preview: URL.createObjectURL(
        new Blob(binaryData, { type: "image/jpeg" }),
      ),
      id: photo.name.replace(/ /g, ""),
    });

    return createNewPhotosPreview;
  };

  const compressPhoto = async (photo: File) => {
    try {
      return await new Promise((resolve, reject) => {
        new Compressor(photo, {
          quality: 0.8,
          maxHeight: 1200,
          maxWidth: 1200,
          mimeType: "image/jpeg",
          success: (compressedFile) => {
            return resolve(compressedFile);
          },
          error: reject,
        });
      });
    } catch (error) {
      setErrorMessage("There was an issue compressing the image");
      return photo;
    }
  };

  const isPhotoAlreadyUploaded = (newPhotoWithPreview: IFile) => {
    for (const uploadedPhoto of photos) {
      const currentId = newPhotoWithPreview.id;
      if (uploadedPhoto.id === currentId) {
        return true;
      }
    }
    return false;
  };

  const goToSuccessPage = () => {
    navigate("/success", {
      state: {
        title: "Thumbnail uploaded successfully!",
      },
    });
  };

  const onSubmit = async () => {
    try {
      const id = locationContactId || locationId || experienceId;
      if (id) {
        await submitImagesToBackend(id);
        if (pageType === "location-contact") {
          goToSuccessPage();
        } else {
          goToNextPage(id);
        }
      } else {
        throw Error("cannot find the id");
      }
    } catch (error: unknown) {
      console.error(error);
    }
  };

  const uploadedImages: UploadImage[] = photos.map((selectedFile, index) => ({
    order: index + 1,
    contentType: selectedFile.type,
    featured: false,
    thumbnail: isThumbnail,
    imageFile: selectedFile,
  }));

  const uploadImagesRequest = uploadedImages.map((uploadedImg) => {
    const { imageFile, ...request } = uploadedImg;
    return request;
  });

  const submitImagesToBackend = async (id: string) => {
    const submitHandler = pageTypeSubmitHandlers[pageType as PageTypeType];
    if (submitHandler) {
      const createImagesResponse = await submitHandler(id, uploadImagesRequest);

      createImagesResponse.forEach(async (imageResponse, index) => {
        const { imageFile } = uploadedImages[index];
        await uploadImageToGCPBucket(imageResponse.uploadUrl!, imageFile);
      });
    }
  };

  const uploadImageToGCPBucket = async (url: string, imageFile: File) => {
    await fetch(url, {
      method: "PUT",
      headers: {
        "content-type": imageFile.type,
      },
      body: imageFile,
    });
  };

  const deletePhoto = (photo: IFile) => {
    const returnNotDeletedPhotos = photos.filter((a: IFile) => {
      return a.id !== photo.id;
    });
    URL.revokeObjectURL(photo.id);
    setErrorMessage("");
    setPhotos(returnNotDeletedPhotos);
  };

  const { createError, isCreateError } =
    pageTypeCreateImageStates[pageType as PageTypeType] ?? {};

  return (
    <Container>
      {isCreateError && <ApiError>{createError?.message}</ApiError>}
      <H3> {title} Upload</H3>
      <Typography mb={2}>{description}</Typography>
      {!isThumbnail && (
        <Typography mb={2}>
          The order in which the images are uploaded is how they will be
          displayed
        </Typography>
      )}
      <Box p={2}>
        <UploadPhotos onImageUploaded={onImageUploaded} />
        <FormHelperText error={!!errorMessage}>
          {errorMessage ? errorMessage : null}
        </FormHelperText>
        {photos.map((photo: IFile, index) => {
          return (
            <div key={photo.lastModified}>
              <UploadPhotoCard
                photo={photo}
                index={index}
                deleteOnePhoto={deletePhoto}
                showPhotoOrder={title !== "Thumbnail"}
              />
            </div>
          );
        })}
      </Box>
      <Box mt={2}>
        <Grid container spacing={1} pb={1}>
          <Grid item xs={6}>
            <Button
              color="blackShades"
              variant="outlined"
              size="large"
              fullWidth={true}
              onClick={() => {
                navigate("/");
              }}
            >
              Cancel
            </Button>
          </Grid>

          <Grid item xs={6}>
            <Button
              color="blackShades"
              type="submit"
              variant="outlined"
              size="large"
              fullWidth={true}
              onClick={onSubmit}
              disabled={photos.length === 0}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default UploadPhotoTemplatePage;
