import React from "react";
import UploadFiles from "components/templates/UploadPhotoTemplatePage/UploadPhotoTemplatePage";
import useParameter from "hooks/useParameter";
import { useNavigate } from "react-router-dom";

const UploadImages = () => {
  const navigate = useNavigate();
  const { getPageTitle } = useParameter();
  const title = getPageTitle();

  const goToNextPage = () => {
    navigate("/success", {
      state: {
        title: "Images uploaded successfully!",
      },
    });
  };

  return (
    <UploadFiles
      title={title}
      description="Drag images here or search for them and upload them from your browser.
  You can add up to 14 images."
      allowOneFileToSubmit={false}
      goToNextPage={goToNextPage}
    ></UploadFiles>
  );
};

export default UploadImages;
