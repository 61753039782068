import React from "react";
import styled from "styled-components";
import { Box, CircularProgress } from "@mui/material";

export const StyledBackground = styled(Box)(() => ({
  position: "fixed",
  display: "block",
  width: "100%",
  height: "100%",
  top: "0",
  left: "0",
  opacity: "0.7",
  backgroundColor: "#fff",
  zIndex: 99,
  alignContent: "center",
  justifyContent: "center",
  flexWrap: "wrap",
}));

const LoadingOverlay = () => {
  return (
    <StyledBackground sx={{ display: "flex" }}>
      <CircularProgress />
    </StyledBackground>
  );
};

export default LoadingOverlay;
