import { useMutation, useQueryClient } from "@tanstack/react-query";
import { lodgingApi } from "./lodgingService";
import AsyncStatuses from "common/types/enums/asyncStatuses";
import { LodgingsAPIApiDeleteLodgingRequest } from "api/v1.0";
import { lodgingKeys } from "./lodgingKeyFactory";

function useMutateLodging() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (deleteLodgingApiRequest: LodgingsAPIApiDeleteLodgingRequest) =>
      lodgingApi.deleteLodging(deleteLodgingApiRequest),
    onSuccess: (_data, _variables) => {
      void queryClient.invalidateQueries({ queryKey: lodgingKeys.lists() });
    },
  });
}

export const useDeleteLodging = () => {
  const mutateLodging = useMutateLodging();

  return {
    ...mutateLodging,
    deleteStatus: mutateLodging.status as AsyncStatuses,
  };
};
