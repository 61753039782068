import { useMutation, useQueryClient } from "@tanstack/react-query";
import { locationApi } from "./locationService";
import AsyncStatuses from "common/types/enums/asyncStatuses";
import { LocationsAPIApiDeleteLocationRequest } from "api/v1.0";
import { locationKeys } from "./locationQueryKeyFactory";

function useMutateLocation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (
      deleteLocationApiRequest: LocationsAPIApiDeleteLocationRequest,
    ) => locationApi.deleteLocation(deleteLocationApiRequest),
    onSuccess: (_data, _variables) => {
      void queryClient.invalidateQueries({ queryKey: locationKeys.lists() });
    },
  });
}

export const useDeleteLocation = () => {
  const mutateLocation = useMutateLocation();

  return {
    ...mutateLocation,
    deleteStatus: mutateLocation.status as AsyncStatuses,
  };
};
