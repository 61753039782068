import { Box, styled } from "@mui/material";
const StyledSearchCard = styled(Box)(({ theme }) => ({
  overflow: "hidden",
  width: "250px",
  height: "140px",
  border: `1px solid ${theme.palette.lightGreyShades.main}`,
  borderRadius: "12px",
  padding: "16px",
  marginBottom: "20px",
  cursor: "pointer",
}));

const StyledTitle = styled("p")(() => ({
  fontWeight: "700",
  fontSize: "16px",
  margin: 0,
}));

const StyledLocation = styled("div")(() => ({
  fontWeight: "400",
  fontSize: "16px",
  margin: 0,
  marginTop: "15px",
  display: "flex",
  alignItems: "center",
}));

const StyledIcon = styled("div")(() => ({
  marginRight: "10px",
}));

export { StyledIcon, StyledLocation, StyledSearchCard, StyledTitle };
