import { useMutation, useQueryClient } from "@tanstack/react-query";
import { locationApi } from "./locationService";
import AsyncStatuses from "common/types/enums/asyncStatuses";
import { LocationsAPIApiBlockOrUnblockLocationDatesRequest } from "api/v1.0";
import { locationKeys } from "./locationQueryKeyFactory";

function useMutateLocationBlockedDates() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (
      createLocationBlockedDates: LocationsAPIApiBlockOrUnblockLocationDatesRequest,
    ) => locationApi.blockOrUnblockLocationDates(createLocationBlockedDates),
    onSuccess: async (_data, arg) => {
      await queryClient.invalidateQueries({
        queryKey: locationKeys.detailResource(arg.id, "dates"),
      });
    },
  });
}

export const useCreateLocationBlockedDates = () => {
  const mutateLocation = useMutateLocationBlockedDates();

  return {
    ...mutateLocation,
    createStatus: mutateLocation.status as AsyncStatuses,
  };
};
