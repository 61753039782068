import { Theme, styled } from "@mui/material";

const setBackgoundColor = (
  theme: Theme,
  isDragActive: boolean,
  isDragReject: boolean,
) => {
  if (isDragReject) return `${theme.palette.error.light}`;
  else if (isDragActive) return `${theme.palette.success.light}`;
};

export const StyledDropzone = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

export const StyledDropContainer = styled("div")<{
  isDragActive: boolean;
  isDragReject: boolean;
}>(({ theme, isDragActive, isDragReject }) => ({
  padding: "26px",
  borderRadius: "8px",
  border: `1px solid ${theme.palette.greyShades.main}`,
  color: setBackgoundColor(theme, isDragActive, isDragReject),
  minHeight: "100px",
}));

export const StyledUIconBox = styled("div")(() => ({
  padding: "10px",
}));
