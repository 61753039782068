import React, { useContext, useEffect, useState } from "react";
import Container from "components/atoms/Container";
import { Box, Typography } from "@mui/material";
import { StyledTitle } from "./SearchExperience.style";
import { useLocation } from "react-router-dom";
import { searchExperiencesAPI } from "services/experiencesService";
import Button from "components/atoms/Button";
import {
  ICreateExperienceResponse,
  ISearchExperiencesResponse,
} from "services/types/experience";
import Search from "components/templates/Search/Search";
import { LoadingContext } from "context/LoadingContext";
import useParameter from "hooks/useParameter";

const SearchExperience: React.FC = () => {
  const { getPageType, getPageTitle, getLocationId } = useParameter();
  const [experiences, setExperiences] = useState<ICreateExperienceResponse[]>(
    [],
  );
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const type = getPageType();
  const title = getPageTitle();
  const values = useLocation().state.values;
  const { setLoading } = useContext(LoadingContext);
  const getId = getLocationId();
  const locationId = getId === null ? "" : getId;

  useEffect(() => {
    let finalValues = {
      name: values?.name || "",
      city: values?.city || "",
      state: values?.state || "",
    };

    async function loadExperiences() {
      try {
        setLoading(true);
        const experiencesList: ISearchExperiencesResponse =
          await searchExperiencesAPI(
            page,
            10,
            true,
            finalValues.name,
            finalValues.city,
            finalValues.state,
            locationId,
          );
        setExperiences((experiences) =>
          experiences.concat(experiencesList.content),
        );
        setTotalPages(experiencesList.totalPages);
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    }

    loadExperiences();
  }, [page, type, setLoading, values, locationId]);

  const loadMore = () => {
    setPage(page + 1);
  };

  return (
    <Container>
      <StyledTitle>Search Results</StyledTitle>
      <Typography mb={3} ml={3}>
        {title}
      </Typography>
      {experiences !== undefined && (
        <Search data={experiences} type={type} showLocation={false} />
      )}
      {page + 1 < totalPages && (
        <Box display="flex" justifyContent="center">
          <Button
            variant="text"
            qaAttribute="button-load-more"
            color="blackShades"
            onClick={loadMore}
          >
            Load more experiences
          </Button>
        </Box>
      )}
    </Container>
  );
};

export default SearchExperience;
