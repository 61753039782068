import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Container from "components/atoms/Container";
import React, { useContext, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import H3 from "components/atoms/H3";
import Button from "components/atoms/Button";
import { useNavigate } from "react-router-dom";
import { TemplateProps } from "common/types/interfaces/templateInterface";
import ButtonEditWithLocker from "components/molecules/ButtonWithLocker";
import { IExperienceInitialValues } from "common/types/components/templates";
import { LoadingContext } from "context/LoadingContext";
import { wildlife } from "common/types/enums/wildlife";
import ButtonUnderlineText from "components/atoms/ButtonUnderlineText";
import {
  BoldItalicUnderlineToggles,
  ListsToggle,
  MDXEditor,
  MDXEditorMethods,
  listsPlugin,
  toolbarPlugin,
} from "@mdxeditor/editor";
import "@mdxeditor/editor/style.css";
import { StyledBoxDescription } from "./Experience.style";
import LoadingOverlay from "components/atoms/LoadingOverlay";
import { getAllExperienceTypesAPI } from "services/experiencesService";
import { locationApi } from "services/location";
interface ExperienceProps extends TemplateProps {
  initialValues: IExperienceInitialValues;
  loadedExperience: boolean;
  deleteExperience?: () => void;
  experienceId?: string;
}

const schema = yup.object().shape({
  location: yup
    .object()
    .required("Location is required")
    .shape({
      id: yup.string(),
      label: yup.string(),
    })
    .test("validateLocation", (value, { createError }) => {
      if (value.id === "") {
        return createError({ message: "Location is required" });
      }
      return true;
    }),
  name: yup
    .string()
    .required("Experience name is required")
    .min(2, "Experience name must be at least 2 characters long")
    .max(30, "Experience name is too long"),
  shortDescription: yup
    .string()
    .required("Short description is required")
    .min(10, "Short description must be at least 10 characters long")
    .max(70, "Short description is too long"),
  description: yup
    .string()
    .required("Long description is required")
    .min(50, "Long description must be at least 50 characters long"),
  experienceType: yup
    .object()
    .required("Type of experience is required")
    .shape({
      id: yup.string(),
      label: yup.string(),
    })
    .test("validateExperience", (value, { createError }) => {
      if (value.id === "") {
        return createError({ message: "Type of experience is required" });
      }
      return true;
    }),
  wildlife: yup
    .object()
    .required("Type of experience is required")
    .shape({
      id: yup.string(),
      label: yup.string(),
    })
    .test("validateWildLife", (value, { createError }) => {
      if (value.id === "") {
        return createError({ message: "Type of wild life is required" });
      }
      return true;
    }),
  publish: yup.boolean(),
  whatToExpectDescription: yup.string().nullable(),
});

const Experience: React.FC<ExperienceProps> = ({
  title,
  loadedExperience,
  initialValues,
  getSubmitValues,
  canEdit,
  deleteExperience,
  experienceId,
}): React.ReactElement => {
  const {
    control,
    reset,
    formState: { errors },
    getValues,
    handleSubmit,
    setValue,
    trigger,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });

  const navigate = useNavigate();
  const [enableEditForm, setEnableEditForm] = useState(canEdit ? false : true);
  const { setLoading } = useContext(LoadingContext);
  const [autoCompleteLocations, setAutoCompleteLocations] = useState([
    { label: "", id: "" },
  ]);
  const [experienceOptions, setExperienceOptions] = useState([
    { label: "", id: "" },
  ]);
  const descriptionRef = React.useRef<MDXEditorMethods>(null);

  useEffect(() => {
    reset(initialValues);
  }, [reset, initialValues]);

  useEffect(() => {
    async function loadLocations() {
      try {
        setLoading(true);
        const locationsListPromise = locationApi.findAllLocations({
          page: 0,
          pageSize: 100,
        });

        const experiencesListPromise = getAllExperienceTypesAPI();
        const [locationsList, experiencesList] = await Promise.all([
          locationsListPromise,
          experiencesListPromise,
        ]);

        const locationsListFiltered = locationsList.data.content!.map(
          (location) => ({
            label: location.name!,
            id: location.id!,
          }),
        );
        setAutoCompleteLocations(locationsListFiltered);

        const experiencesToOptions = experiencesList.map(
          ({ id, description }) => ({ label: description, id }),
        );
        setExperienceOptions([{ label: "", id: "" }, ...experiencesToOptions]);
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    }

    loadLocations();
  }, [setLoading]);

  const toggleEditForm = () => {
    setEnableEditForm((enable) => !enable);
  };

  const onSubmitForm = () => {
    console.log(getValues());
    const valuesForm = getValues();
    if (valuesForm.description) {
      valuesForm.description = valuesForm.description.replace(/\\<!--->/g, "");
    }
    getSubmitValues(valuesForm);
  };

  const seeAllPackages = () => {
    navigate(`/package/search?experience-id=${experienceId}`);
  };

  const uploadImages = () => {
    navigate(`/experience/upload/images?experience-id=${experienceId}`);
  };

  const uploadThumbnail = () => {
    navigate(`/experience/upload/thumbnail?experience-id=${experienceId}`);
  };

  const wildlifeOptions = [
    { label: "", id: "" },
    { label: "Deer", id: wildlife.deer },
    { label: "Wildhog", id: wildlife.wildhog },
    { label: "Tiger", id: wildlife.tiger },
    { label: "Other", id: wildlife.other },
  ];

  return !loadedExperience ? (
    <LoadingOverlay />
  ) : (
    <Container>
      <H3 fontWeight="700">{title}</H3>
      {canEdit && (
        <>
          <Box mb={1}>
            <ButtonUnderlineText
              onClick={seeAllPackages}
              qaAttribute="button-see-all-lodging"
            >
              See all Packages
            </ButtonUnderlineText>
          </Box>
          <Box mb={1}>
            <ButtonUnderlineText
              onClick={uploadImages}
              qaAttribute="button-upload-images"
            >
              Upload more Images
            </ButtonUnderlineText>
          </Box>
          <Box mb={1}>
            <ButtonUnderlineText
              onClick={uploadThumbnail}
              qaAttribute="button-upload-thumbnail"
            >
              Upload thumbnail
            </ButtonUnderlineText>
          </Box>
          <ButtonEditWithLocker
            onClick={() => {
              toggleEditForm();
            }}
            lockerClose={enableEditForm}
            qaAttribute="experience-edit-page-edit-button"
          >
            Edit
          </ButtonEditWithLocker>
        </>
      )}
      <form onSubmit={handleSubmit(onSubmitForm)} style={{ width: "100%" }}>
        <Box mb={2}>
          <Controller
            name="location"
            control={control}
            render={({ field }) => (
              <Autocomplete
                disablePortal
                freeSolo
                forcePopupIcon={true}
                disabled={canEdit ? true : !enableEditForm}
                onChange={(event, value) =>
                  value === null
                    ? field.onChange({
                        label: "",
                        id: "",
                      })
                    : field.onChange(value)
                }
                value={
                  canEdit
                    ? {
                        label: field.value.label,
                        id: field.value.id,
                      }
                    : undefined
                }
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="location"
                options={autoCompleteLocations}
                isOptionEqualToValue={(option: any, value: any) => {
                  return option === value;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Location"
                    inputRef={field.ref}
                    error={!!errors.location}
                    helperText={
                      errors.location ? errors.location.message : null
                    }
                  />
                )}
              />
            )}
          />
        </Box>
        <Box mb={2}>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Experience Name"
                disabled={!enableEditForm}
                fullWidth
                error={!!errors.name}
                helperText={errors.name ? errors.name.message : null}
              />
            )}
          />
        </Box>
        <Box mb={2}>
          <Controller
            name="experienceType"
            control={control}
            render={({ field }) => (
              <>
                <Autocomplete
                  disablePortal
                  onChange={(event, value) =>
                    value === null
                      ? field.onChange({ id: "", label: "" })
                      : field.onChange(value)
                  }
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  id="experienceType"
                  value={field.value}
                  options={experienceOptions}
                  isOptionEqualToValue={(option: any, value: any) => {
                    return option.id === value.id;
                  }}
                  disabled={!enableEditForm}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Type of experience"
                      inputRef={field.ref}
                      error={!!errors.experienceType}
                      helperText={
                        errors.experienceType
                          ? errors.experienceType.message
                          : null
                      }
                    />
                  )}
                />
              </>
            )}
          />
        </Box>
        <Box mb={2}>
          <Controller
            name="wildlife"
            control={control}
            render={({ field }) => (
              <Autocomplete
                disablePortal
                onChange={(event, value) =>
                  value === null
                    ? field.onChange({ id: "", label: "" })
                    : field.onChange(value)
                }
                selectOnFocus
                value={field.value}
                clearOnBlur
                handleHomeEndKeys
                id="wildlife"
                options={wildlifeOptions}
                isOptionEqualToValue={(option: any, value: any) => {
                  return option.id === value.id;
                }}
                disabled={!enableEditForm}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Type of wild life"
                    inputRef={field.ref}
                    error={!!errors.wildlife}
                    helperText={
                      errors.wildlife ? errors.wildlife.message : null
                    }
                  />
                )}
              />
            )}
          />
        </Box>
        <Box mb={2}>
          <Controller
            name="shortDescription"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Short Description"
                disabled={!enableEditForm}
                fullWidth
                error={!!errors.shortDescription}
                helperText={
                  errors.shortDescription
                    ? errors.shortDescription.message
                    : null
                }
              />
            )}
          />
        </Box>
        <Typography mt={2} mb={2}>
          Description
        </Typography>
        <Box mb={2}>
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <>
                <StyledBoxDescription>
                  <MDXEditor
                    {...field}
                    ref={descriptionRef}
                    markdown={initialValues.description}
                    readOnly={!enableEditForm}
                    onChange={() => {
                      setValue(
                        "description",
                        descriptionRef.current?.getMarkdown() || "",
                      );
                      trigger("description");
                    }}
                    plugins={[
                      listsPlugin(),
                      toolbarPlugin({
                        toolbarContents: () => (
                          <>
                            <BoldItalicUnderlineToggles />
                            <ListsToggle />
                          </>
                        ),
                      }),
                    ]}
                  />
                </StyledBoxDescription>
                <Box ml={2}>
                  <FormHelperText error={!!errors.description}>
                    {errors.description ? errors.description.message : null}
                  </FormHelperText>
                </Box>
              </>
            )}
          />
        </Box>
        <Box mb={2}>
          <Controller
            name="whatToExpectDescription"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="What to Expect"
                disabled={!enableEditForm}
                fullWidth
                error={!!errors.whatToExpectDescription}
                helperText={
                  errors.whatToExpectDescription
                    ? errors.whatToExpectDescription.message
                    : null
                }
              />
            )}
          />
        </Box>
        <Box mb={2}>
          <Controller
            name="publish"
            control={control}
            render={({ field: { onChange, value } }) => (
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={!enableEditForm}
                      color="success"
                      checked={value}
                      onChange={onChange}
                    />
                  }
                  label="Publish to page"
                />
              </>
            )}
          />
        </Box>
        <Grid container spacing={1} pb={1}>
          <Grid item xs={6}>
            <Button
              qaAttribute="save-experience-button"
              type="button"
              color="blackShades"
              variant="outlined"
              size="large"
              fullWidth={true}
              onClick={() => {
                navigate("/");
              }}
            >
              Cancel
            </Button>
          </Grid>

          {enableEditForm && (
            <Grid item xs={6}>
              <Button
                qaAttribute="submit-experience-button"
                type="submit"
                variant="contained"
                size="large"
                fullWidth={true}
              >
                Save
              </Button>
            </Grid>
          )}
          {deleteExperience && (
            <Grid item xs={6}>
              <Button
                type="button"
                color="blackShades"
                size="large"
                fullWidth={true}
                qaAttribute="location-delete-button"
                onClick={() => {
                  deleteExperience();
                }}
              >
                Delete
              </Button>
            </Grid>
          )}
        </Grid>
      </form>
    </Container>
  );
};

export default Experience;
