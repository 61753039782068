import Container from "components/atoms/Container";
import React, { useContext, useEffect, useState } from "react";
import Experience from "components/templates/Experience/Experience";
import { useNavigate } from "react-router-dom";
import {
  IEditExperienceRequest,
  IEditExperienceForm,
} from "services/types/experience";
import {
  editExperienceAPI,
  getOneExperienceAPI,
  deleteExperienceAPI,
} from "services/experiencesService";
import { LoadingContext } from "context/LoadingContext";
import { experienceInitialValues } from "common/types/components/templates";
import useParameter from "hooks/useParameter";
import ApiError from "components/atoms/ApiError";
import { locationApi } from "services/location";

function EditExperience() {
  const navigate = useNavigate();
  const { setLoading } = useContext(LoadingContext);
  const [initialValues, setInitialValues] = useState(experienceInitialValues);
  const [loadedExperience, setLoadedExperiences] = useState(false);
  const { getExperienceId } = useParameter();
  const experienceId = getExperienceId();
  const [apiError, setApiError] = React.useState("");

  useEffect(() => {
    async function loadExperience() {
      try {
        setLoading(true);
        if (experienceId) {
          const experienceInfo = await getOneExperienceAPI(experienceId);
          const location = (
            await locationApi.findLocationById({
              id: experienceInfo.locationId,
            })
          ).data;
          const initialValuesAPI = {
            ...experienceInfo,
            location: {
              label: location.name!,
              id: experienceInfo.locationId,
            },
            experienceType: {
              label: experienceInfo.experienceType.description,
              id: experienceInfo.experienceType.id,
            },
            wildlife: {
              label: experienceInfo.wildlife.description,
              id: experienceInfo.wildlife.id,
            },
            publish: experienceInfo.isPublished,
          };
          setInitialValues(initialValuesAPI);
        } else {
          throw new Error("Missing experience id");
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
        setLoadedExperiences(true);
      }
    }
    loadExperience();
  }, [experienceId, setLoading]);

  const goToSuccessPage = (status: string) => {
    navigate("/success", {
      state: {
        title: `Experience ${status} successfully!`,
      },
    });
  };

  const onSubmit = async (event: IEditExperienceForm) => {
    const experienceData: IEditExperienceRequest = {
      name: event.name,
      locationId: event.locationId,
      experienceTypeId: event.experienceType.id,
      wildlifeId: event.wildlife.id,
      description: event.description,
      shortDescription: event.shortDescription,
      whatToExpectDescription: event.whatToExpectDescription,
      published: event.publish,
    };
    setLoading(true);
    try {
      if (experienceId) {
        await editExperienceAPI(experienceId, experienceData);
        setLoading(false);
        setApiError("");

        goToSuccessPage("edited");
      } else {
        throw new Error("Missing experience id");
      }
    } catch (error: any) {
      console.log("Error: ", error);
      setApiError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    setLoading(true);

    try {
      await deleteExperienceAPI(experienceId);
      setLoading(false);
      setApiError("");
      goToSuccessPage("deleted");
    } catch (error: any) {
      console.log("Error: ", error);
      setApiError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      {apiError && <ApiError>{apiError}</ApiError>}
      <Experience
        title="Edit this Experience"
        loadedExperience={loadedExperience}
        initialValues={initialValues}
        getSubmitValues={onSubmit}
        canEdit={true}
        deleteExperience={handleDelete}
        experienceId={experienceId}
      />
    </Container>
  );
}

export default EditExperience;
