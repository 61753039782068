import styled from "styled-components";
import React, { ReactNode } from "react";

type StyledH3Props = {
  children: ReactNode;
  align?: "center" | "left" | "right";
  fontWeight?: "400" | "500" | "600" | "700";
};

const StyledH3 = styled("h3")<StyledH3Props>(({ align, fontWeight }) => ({
  fontSize: "20px",
  fontWeight: fontWeight,
  lineHeight: "30px",
  textAlign: align,
}));

export const H3 = ({
  children,
  align,
  fontWeight = "400",
}: StyledH3Props): React.ReactElement => {
  return (
    <StyledH3 align={align} fontWeight={fontWeight}>
      {children}
    </StyledH3>
  );
};

export default H3;
