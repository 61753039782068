import { Box, styled } from "@mui/material";
import React, { ReactNode } from "react";
const StyledCard = styled(Box)(({ theme }) => ({
  width: "250px",
  height: "120px",
  border: `1px solid ${theme.palette.lightGreyShades.main}`,
  borderRadius: "12px",
  padding: "16px",
  marginBottom: "20px",
  cursor: "pointer",
}));

type CardProps = {
  children: ReactNode;
  onClick?: () => void;
};
export const Card: React.FC<CardProps> = ({
  children,
  onClick,
}): React.ReactElement => {
  return <StyledCard onClick={onClick}>{children}</StyledCard>;
};
