import { useMutation, useQueryClient } from "@tanstack/react-query";
import { lodgingApi } from "./lodgingService";
import AsyncStatuses from "common/types/enums/asyncStatuses";
import { LodgingsAPIApiCreateLodgingRequest } from "api/v1.0";
import { lodgingKeys } from "./lodgingKeyFactory";

function useMutateLodging() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (createLodgingApiRequest: LodgingsAPIApiCreateLodgingRequest) =>
      lodgingApi.createLodging(createLodgingApiRequest),
    onSuccess: async (_data, _variables) => {
      await queryClient.invalidateQueries({ queryKey: lodgingKeys.lists() });
    },
  });
}

export const useCreateLodging = () => {
  const mutateLodging = useMutateLodging();

  return {
    ...mutateLodging,
    createStatus: mutateLodging.status as AsyncStatuses,
  };
};
