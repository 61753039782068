import styled from "styled-components";
import React, { ReactNode } from "react";
import { Container as MuiContainer } from "@mui/material";

const StyledContainer = styled(MuiContainer)(() => ({
  padding: "26px",
}));

type ContainerProps = {
  children: ReactNode;
};

export const Container = (props: ContainerProps): React.ReactElement => {
  return <StyledContainer maxWidth="sm">{props.children}</StyledContainer>;
};

export default Container;
