import React, { ReactNode } from "react";
import { Button as MuiButton } from "@mui/material";
type RoundButtonProps = {
  children: ReactNode;
  size?: "small" | "medium" | "large";
  type?: "button" | "submit" | "reset" | undefined;
  variant?: "outlined" | "contained" | "text";
  onClick?: () => void;
  fullWidth?: boolean;
  qaAttribute: string;
};

export const RoundButton: React.FC<RoundButtonProps> = ({
  children,
  size = "large",
  type = "button",
  variant = "outlined",
  onClick,
  fullWidth = false,
  qaAttribute,
}): React.ReactElement => {
  return (
    <>
      <MuiButton
        variant={variant}
        size={size}
        sx={{
          marginTop: "16px",
          boxShadow: 0,
          border: "1px solid",
          textTransform: "inherit",
          borderRadius: "88px",
        }}
        type={type}
        color="greyShades"
        onClick={onClick}
        fullWidth={fullWidth}
        data-qa={qaAttribute}
      >
        {children}
      </MuiButton>
    </>
  );
};

export default RoundButton;
