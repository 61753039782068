import React from "react";
import H2 from "components/atoms/H2";
import { StyledSubTitle } from "./Home.style";
import Container from "components/atoms/Container";
import Button from "components/atoms/Button";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import SearchForm from "components/molecules/SearchForm";

function Home() {
  const navigate = useNavigate();

  return (
    <Container>
      <H2>Welcome to your Admin portal</H2>
      <p>
        Here you can create, search or filter for experiences, locations or
        packages.
      </p>
      <StyledSubTitle>Create a new entry</StyledSubTitle>
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
      >
        <Grid item>
          <Button
            qaAttribute="create-experience-button"
            variant="contained"
            size="large"
            onClick={() => {
              navigate("/experience/create");
            }}
          >
            Experience
          </Button>
        </Grid>
        <Grid item>
          <Button
            qaAttribute="create-location-button"
            variant="contained"
            size="large"
            onClick={() => {
              navigate("/location/create");
            }}
          >
            Location
          </Button>
        </Grid>
        <Grid item>
          <Button
            qaAttribute="create-package-button"
            size="large"
            variant="contained"
            onClick={() => {
              navigate("/package/create");
            }}
          >
            Package
          </Button>
        </Grid>
      </Grid>
      <StyledSubTitle>Hunt Booking</StyledSubTitle>
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
      >
        <Grid item>
          <Button
            qaAttribute="manual-booking-button"
            variant="contained"
            size="large"
            onClick={() => {
              navigate("/booking/create");
            }}
          >
            Create Hunt
          </Button>
        </Grid>
        <Grid item>
          <Button
            qaAttribute="blackout-location-button"
            variant="contained"
            size="large"
            onClick={() => {
              navigate("/booking/view");
            }}
          >
            View Hunts
          </Button>
        </Grid>
      </Grid>
      <StyledSubTitle>Blackout Dates</StyledSubTitle>
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
      >
        <Grid item>
          <Button
            qaAttribute="blackout-experience-button"
            variant="contained"
            size="large"
            onClick={() => {
              navigate("/experience/blackout-dates");
            }}
          >
            Experience
          </Button>
        </Grid>
        <Grid item>
          <Button
            qaAttribute="blackout-location-button"
            variant="contained"
            size="large"
            onClick={() => {
              navigate("/location/blackout-dates");
            }}
          >
            Location
          </Button>
        </Grid>
      </Grid>

      <SearchForm />
    </Container>
  );
}

export default Home;
