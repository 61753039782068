import React, { ReactNode } from "react";
import { Button as MuiButton, styled } from "@mui/material";

type ButtonProps = {
  children: ReactNode;
  size?: "small" | "medium" | "large";
  type?: "button" | "submit" | "reset" | undefined;
  color?:
    | "primary"
    | "inherit"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning"
    | "greyShades"
    | "whiteShades"
    | "blackShades";
  variant?: "outlined" | "contained" | "text";
  onClick?: () => void;
  fullWidth?: boolean;
  isDisabled?: boolean;
  height?: "large";
  marginNone?: boolean;
  qaAttribute: string;
};

export const StyledButton = styled(MuiButton)(({ variant }) => ({
  border: variant === "outlined" ? "2px solid" : "0px",
  textTransform: "inherit",
  borderRadius: "4px",
  boxShadow: "none",
  borderColor: "inherit",
  textDecoration: "underline",
  lineHeight: " 20px",
  fontWeight: 400,
  margin: 0,
  padding: 0,
  display: "flex",
  justifyContent: "start",
  "&:hover": {
    boxShadow: "none",
    border: variant === "outlined" ? "2px solid" : "0px",
  },
}));

export const ButtonUnderlineText: React.FC<ButtonProps> = ({
  children,
  size = "medium",
  type = "button",
  color = "blackShades",
  variant = "text",
  onClick,
  fullWidth = false,
  isDisabled = false,
  height,
  marginNone,
  qaAttribute,
}): React.ReactElement => {
  return (
    <>
      <StyledButton
        variant={variant}
        size={size}
        type={type}
        color={color}
        onClick={onClick}
        fullWidth={fullWidth}
        disabled={isDisabled}
        data-qa={qaAttribute}
        sx={{
          height: height === "large" ? "56px" : "100%",
          margin: marginNone ? "0px" : "",
        }}
      >
        {children}
      </StyledButton>
    </>
  );
};

export default ButtonUnderlineText;
