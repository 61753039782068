import styled from "styled-components";

export const StyledTitle = styled("p")(() => ({
  fontSize: "20px",
  fontWeight: "400",
  lineHeight: "30px",
  textAlign: "center",
}));

export const StyledSearchCardWrapper = styled("div")(() => ({
  overflow: "hidden",
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-around",
}));
