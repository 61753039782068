import React, { useContext } from "react";
import LogoBirddog from "assets/images/logo-birddog.svg";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { authContext } from "context/AuthContext";
import Button from "./Button";
import Container from "./Container";
import { Grid } from "@mui/material";

const StyledHeader = styled("header")(() => ({
  padding: "26px",
  textAlign: "center",
}));

const StyledLogo = styled("img")(() => ({
  width: "225px",
  height: "39px",
}));

const Header = () => {
  const { isLoggedIn, logout } = useContext(authContext);

  const handleLogout = () => {
    logout();
  };

  return (
    <Container>
      <Grid container alignItems="center">
        <Grid item xs={11}>
          <Link to="/" data-qa="header">
            <StyledHeader>
              <StyledLogo src={LogoBirddog} alt="birddog logo" />
            </StyledHeader>
          </Link>
        </Grid>
        <Grid item xs={1}>
          <div style={{ display: "block" }}>
            {isLoggedIn ? (
              <Button
                size="small"
                qaAttribute="logout-button"
                onClick={handleLogout}
              >
                Logout
              </Button>
            ) : null}
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Header;
