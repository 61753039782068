import React, { useEffect, useState } from "react";
import Button from "components/atoms/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import * as yup from "yup";
import Container from "components/atoms/Container";
import H3 from "components/atoms/H3";
import {
  Autocomplete,
  Typography,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  TextField,
  Box,
  Grid,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { TemplateProps } from "common/types/interfaces/templateInterface";
import ButtonEditWithLocker from "components/molecules/ButtonWithLocker";
import ButtonUnderlineText from "components/atoms/ButtonUnderlineText";
import {
  BoldItalicUnderlineToggles,
  ListsToggle,
  MDXEditor,
  MDXEditorMethods,
  listsPlugin,
  toolbarPlugin,
} from "@mdxeditor/editor";
import "@mdxeditor/editor/style.css";
import { StyledBoxDescription } from "./Location.style";
import LoadingOverlay from "components/atoms/LoadingOverlay";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { LocationResponse, UpsertLocationContactRequest } from "api/v1.0";
import {
  ILocationFormValues,
  IUpsertLocationContactRequest,
  locationContactInitialValuesTemplate,
} from "services/location";
import {
  Country,
  State,
  City,
  ICountry,
  IState,
  ICity,
} from "country-state-city";
import { uniqBy } from "lodash";

interface LocationProps extends TemplateProps {
  initialValues: ILocationFormValues;
  loadedLocation: boolean;
  deleteLocation?: () => void;
  locationId?: string | null;
  locationContactRoleNames: string[];
  isEdit?: boolean;
  location?: LocationResponse;
}

function isCountryType(obj: unknown): obj is ICountry {
  const casted = obj as ICountry;

  if (!obj) return false;

  return typeof obj === "object" && !!casted.name && !!casted.isoCode;
}

function isStateType(obj: unknown): obj is IState {
  const casted = obj as IState;

  if (!obj) return false;

  return typeof obj === "object" && !!casted.countryCode && !!casted.isoCode;
}

function isCityType(obj: unknown): obj is ICity {
  const casted = obj as ICity;

  if (!obj) return false;

  return typeof obj === "object" && !!casted.countryCode && !!casted.name;
}

const schema: yup.ObjectSchema<ILocationFormValues> = yup.object().shape({
  name: yup
    .string()
    .required("Location name is required")
    .min(2, "Location name must be at least 2 characters long")
    .max(22, "Location name is too long"),
  description: yup
    .string()
    .required("Description is required")
    .min(50, "Description must be at least 50 characters long"),
  history: yup
    .string()
    .nullable(),
  address: yup.object().shape({
    addressLine1: yup.string(),
    addressLine2: yup.string(),
    city: yup.string().nullable(),
    state: yup.string().nullable(),
    country: yup.string().nullable(),
    postalCode: yup
      .string()
      .max(5, "Must be exactly 5 digits")
      .matches(/^[0-9]+$/, {
        message: "Must be only numbers",
        excludeEmptyString: true,
      }),
    longitude: yup
      .number()
      .min(-180, "Number must be bigger than -180")
      .max(180, "Number must be smaller than 180")
      .typeError("Longitude must be a number")
      .required("Longitude is required"),
    latitude: yup
      .number()
      .min(-90, "Number must be bigger than -90")
      .max(90, "Number must be smaller than 90")
      .typeError("Latitude must be a number")
      .required("Latitude is required"),
  }),
  locationAddressDisplayName: yup
    .string()
    .required("Public displayed address is required")
    .min(2, "Public displayed address must be at least 2 characters long")
    .max(34, "Public displayed address is too long"),
  taxRate: yup
    .number()
    .min(0, "Tax rate should be a positive number")
    .max(100, "Tax rate should not be over 100")
    .typeError("Tax rate must be a number")
    .required("Tax rate is required"),
  locationContacts: yup
    .array()
    .required()
    .of(
      yup.object().shape({
        roles: yup
          .array()
          .of(yup.string().required())
          .required("You must select at least one role")
          .test({
            message: "You must select at least one role",
            test: (arr) => arr.length > 0,
          }),
        firstName: yup
          .string()
          .required("First Name is required")
          .min(2, "First Name must be at least 2 characters long")
          .test(
            "combined-length",
            "Combined length of First Name and Last Name must be at most 30 characters",
            function (value) {
              const parent = this.parent as UpsertLocationContactRequest;
              const firstName = parent.firstName;
              const lastName = parent.lastName;
              return firstName.length + lastName.length <= 30;
            },
          ),
        lastName: yup
          .string()
          .required("Last Name is required")
          .min(2, "Last Name must be at least 2 characters long")
          .test(
            "combined-length",
            "Combined length of First Name and Last Name must be at most 30 characters",
            function (value) {
              const parent = this.parent as UpsertLocationContactRequest;
              const firstName = parent.firstName;
              const lastName = parent.lastName;
              return firstName.length + lastName.length <= 30;
            },
          ),
        email: yup
          .string()
          .email("Please enter a valid email address")
          .required("Email is required")
          .min(5, "Please enter a valid email address"),
        dateOfBirth: yup
          .date()
          .typeError("Please enter a valid date")
          .test("test age", "You must be at least 18 years old", (birthday) => {
            const age = dayjs().diff(birthday, "years");
            const isLegal = age >= 18;
            return isLegal;
          })
          .required(),
        bio: yup
          .string()
          .required("Bio is required")
          .test("test bio", (value, testContext) => {
            // Check if the roles array contains "Host"
            const locationContact =
              testContext.parent as IUpsertLocationContactRequest;

            const trimmedValue = value?.trim();

            if (!locationContact.roles.includes("HOST"))
              return trimmedValue !== null && trimmedValue !== undefined;

            if (
              trimmedValue &&
              trimmedValue.length >= 20 &&
              trimmedValue.length <= 250
            ) {
              return true;
            } else {
              return testContext.createError({
                path: `${testContext.path}`,
                message: "Bio must be between 20 and 250 characters long",
              });
            }
          }),
      }),
    ),
});

const mapRolesToOptionsPerContact = (
  locationContacts: IUpsertLocationContactRequest[],
  locationContactRoleNames: string[],
) => {
  const usedRoles = locationContacts
    .map((lc) => lc.roles?.filter((role) => !!role))
    .filter((roles) => roles !== undefined && roles.length > 0);
  const usedRolesFlat = locationContacts
    .flatMap((lc) => lc.roles)
    .filter((roles) => roles !== undefined);
  const leftoverRoles = locationContactRoleNames
    .filter((role) => !usedRolesFlat.includes(role))
    .map((role) => [role]);
  const rolesToMap = [...usedRoles, ...leftoverRoles].filter((role) => !!role);

  return { rolesToMap, leftoverRoles };
};

const Location: React.FC<LocationProps> = ({
  title,
  loadedLocation,
  initialValues,
  getSubmitValues,
  canEdit,
  deleteLocation,
  locationId,
  locationContactRoleNames,
  isEdit = false,
  location,
}): React.ReactElement => {
  const {
    control,
    reset,
    watch,
    formState: { errors, dirtyFields },
    getValues,
    handleSubmit,
    setValue,
    trigger,
  } = useForm<ILocationFormValues>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });
  const lcFieldArray = useFieldArray({ control, name: "locationContacts" });

  const navigate = useNavigate();
  const [enableEditForm, setEnableEditForm] = useState(canEdit ? false : true);
  const descriptionRef = React.useRef<MDXEditorMethods>(null);
  const historyRef = React.useRef<MDXEditorMethods>(null);

  useEffect(() => {
    reset(initialValues);
  }, [reset, initialValues]);

  const toggleEditForm = () => {
    setEnableEditForm((enable) => !enable);
  };

  const onSubmitForm = () => {
    const valuesForm = getValues();
    if (valuesForm.description) {
      valuesForm.description = valuesForm.description.replace(/\\<!--->/g, "");
    }
    if (valuesForm.history) {
      valuesForm.history = valuesForm.history.replace(/\\<!--->/g, "");
    }

    getSubmitValues(valuesForm);
  };

  const seeAllLodging = () => {
    navigate(`/lodging/search?location-id=${locationId}`, {
      state: {
        locationId: locationId,
      },
    });
  };

  const seeAllExperiences = () => {
    navigate(`/experience/search?location-id=${locationId}`, {
      state: {
        locationId: locationId,
      },
    });
  };

  const uploadImages = () => {
    navigate(`/location/upload/images?location-id=${locationId}`);
  };

  const uploadThumbnail = () => {
    navigate(`/location/upload/thumbnail?location-id=${locationId}`);
  };

  const uploadLocationContactThumbnail = (locationContactId: string) => () => {
    navigate(
      `/location-contact/upload/thumbnail?location-contact-id=${locationContactId}&location-id=${locationId}`,
    );
  };

  const formValues = watch();
  const locationContacts = formValues.locationContacts;
  const { rolesToMap, leftoverRoles } = mapRolesToOptionsPerContact(
    locationContacts,
    locationContactRoleNames,
  );

  const [countries, _setCountries] = useState<ICountry[]>(
    Country.getAllCountries(),
  );
  const [stateOptions, setStateOptions] = useState<IState[]>([]);
  const [cityOptions, setCityOptions] = useState<ICity[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<ICountry | null>(null);
  const [selectedState, setSelectedState] = useState<IState | null>(null);

  useEffect(() => {
    if (selectedCountry) {
      const options = State.getStatesOfCountry(selectedCountry.isoCode);
      setStateOptions(options);
    } else {
      setStateOptions([]);
      setSelectedState(null);
    }
  }, [selectedCountry, setValue, setStateOptions]);

  useEffect(() => {
    if (selectedCountry && selectedState) {
      setCityOptions(
        City.getCitiesOfState(selectedCountry.isoCode, selectedState.isoCode),
      );
    } else if (selectedCountry) {
      const cities = City.getCitiesOfCountry(selectedCountry.isoCode);

      if (cities && cities.length < 200) {
        const uniqCities = uniqBy(cities, "name");
        setCityOptions(uniqCities ?? []);
      } else {
        setCityOptions([]);
      }
    } else {
      setCityOptions([]);
    }
  }, [selectedState, selectedCountry, setCityOptions]);

  useEffect(() => {
    const country = getCountryValue(formValues.address.country);
    const state = getStateValue(formValues.address.state);

    if (isCountryType(country) || country === null) setSelectedCountry(country);
    if (isStateType(state) || state === null) setSelectedState(state);
  }, [formValues.address.country, formValues.address.state]);

  const getCountryValue = (value: unknown) => {
    if (isCountryType(value) || value === null) {
      return value;
    }

    const option = countries.find((country) => country.name === value);

    return option ?? null;
  };

  const getStateValue = (value: unknown) => {
    if (isStateType(value) || value === null) {
      return value;
    }

    const option = stateOptions.find((state) => state.name === value);
    if (option) return option;

    return value && typeof value === "string" ? value : null;
  };

  const getCityValue = (value: unknown) => {
    if (isCityType(value)) return value;

    const option = cityOptions.find((city) => city.name === value);
    if (option) return option;

    return value && typeof value === "string" ? value : null;
  };

  return !loadedLocation ? (
    <LoadingOverlay />
  ) : (
    <Container>
      <H3 fontWeight="700">{title}</H3>
      {canEdit && (
        <>
          <Box mb={1}>
            <ButtonUnderlineText
              onClick={seeAllLodging}
              qaAttribute="button-see-all-lodging"
            >
              See all Lodgings
            </ButtonUnderlineText>
          </Box>
          <Box mb={1}>
            <ButtonUnderlineText
              onClick={seeAllExperiences}
              qaAttribute="button-see-all-experiences"
            >
              See all Experiences
            </ButtonUnderlineText>
          </Box>
          <Box mb={1}>
            <ButtonUnderlineText
              onClick={uploadImages}
              qaAttribute="button-upload-images"
            >
              Upload more Images
            </ButtonUnderlineText>
          </Box>
          <Box mb={1}>
            <ButtonUnderlineText
              onClick={uploadThumbnail}
              qaAttribute="button-upload-thumbnail"
            >
              Upload thumbnail
            </ButtonUnderlineText>
          </Box>
          <ButtonEditWithLocker
            onClick={() => {
              toggleEditForm();
            }}
            lockerClose={enableEditForm}
            qaAttribute="location-edit-page-edit-button"
          >
            Edit
          </ButtonEditWithLocker>
        </>
      )}
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Box mb={2}>
          <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Your property name *"
                disabled={!enableEditForm}
                fullWidth={true}
                error={!!errors.name}
                helperText={errors.name ? errors.name.message : null}
              />
            )}
          />
        </Box>
        <Typography mt={2} mb={2}>
          Description
        </Typography>
        <Box mb={2}>
          <Controller
            name="description"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <>
                <StyledBoxDescription>
                  <MDXEditor
                    {...field}
                    ref={descriptionRef}
                    markdown={initialValues.description}
                    readOnly={!enableEditForm}
                    onChange={() => {
                      setValue(
                        "description",
                        descriptionRef.current?.getMarkdown() ?? "",
                      );
                      trigger("description").catch((e) => console.error(e));
                    }}
                    plugins={[
                      listsPlugin(),
                      toolbarPlugin({
                        toolbarContents: () => (
                          <>
                            <BoldItalicUnderlineToggles />
                            <ListsToggle />
                          </>
                        ),
                      }),
                    ]}
                  />
                </StyledBoxDescription>
                <Box ml={2}>
                  <FormHelperText error={!!errors.description}>
                    {errors.description ? errors.description.message : null}
                  </FormHelperText>
                </Box>
              </>
            )}
          />
        </Box>
        <Typography mt={2} mb={2}>
          History
        </Typography>
        <Box mb={2}>
          <Controller
            name="history"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <>
                <StyledBoxDescription>
                  <MDXEditor
                    {...field}
                    ref={historyRef}
                    markdown={initialValues.history ?? ""}
                    readOnly={!enableEditForm}
                    onChange={() => {
                      setValue(
                        "history",
                        historyRef.current?.getMarkdown() &&
                          historyRef.current?.getMarkdown().trim().length > 0
                          ? historyRef.current?.getMarkdown()
                          : null,
                      );
                      trigger("history").catch((e) => console.error(e));
                    }}
                    plugins={[
                      listsPlugin(),
                      toolbarPlugin({
                        toolbarContents: () => (
                          <>
                            <BoldItalicUnderlineToggles />
                            <ListsToggle />
                          </>
                        ),
                      }),
                    ]}
                  />
                </StyledBoxDescription>
                <Box ml={2}>
                  <FormHelperText error={!!errors.history}>
                    {errors.history ? errors.history.message : null}
                  </FormHelperText>
                </Box>
              </>
            )}
          />
        </Box>
        <Typography mt={2} mb={2}>
          Property Address
        </Typography>
        <Box mb={2}>
          <Controller
            name="address.addressLine1"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Address line 1"
                disabled={!enableEditForm}
                fullWidth={true}
                error={!!errors.address?.addressLine1}
                helperText={
                  errors.address?.addressLine1
                    ? errors.address?.addressLine1.message
                    : null
                }
              />
            )}
          />
        </Box>
        <Box mb={2}>
          <Controller
            name="address.addressLine2"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Address line 2"
                disabled={!enableEditForm}
                fullWidth={true}
                error={!!errors.address?.addressLine2}
                value={field.value}
                helperText={
                  errors.address?.addressLine2
                    ? errors.address?.addressLine2.message
                    : null
                }
              />
            )}
          />
        </Box>
        <Box mb={2}>
          <Controller
            name="address.country"
            control={control}
            render={({ field }) => (
              <Autocomplete
                {...field}
                id="country-select"
                options={countries}
                disabled={!enableEditForm}
                autoHighlight
                fullWidth={true}
                getOptionLabel={(option) => option.name}
                value={getCountryValue(field.value)}
                onChange={(event, value) => {
                  value ? field.onChange(value.name) : field.onChange(value);
                }}
                onInputChange={(event, value) => {
                  value && field.onChange(value);
                }}
                renderOption={(props, option) => {
                  return (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {option.flag} {option.name} ({option.isoCode}) +{" "}
                      {option.phonecode}
                    </Box>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Choose a country"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                    disabled={!enableEditForm}
                    error={!!errors.address?.country}
                    helperText={
                      errors.address?.country
                        ? errors.address?.country.message
                        : null
                    }
                  />
                )}
              />
            )}
          />
        </Box>
        <Box mb={2}>
          <Controller
            name="address.state"
            control={control}
            render={({ field }) => (
              <Autocomplete
                {...field}
                id="state-select"
                options={stateOptions}
                freeSolo
                disabled={!enableEditForm}
                autoHighlight
                fullWidth={true}
                getOptionLabel={(option) =>
                  isStateType(option) ? option.name : option
                }
                value={getStateValue(field.value)}
                onChange={(event, value) => {
                  value && isStateType(value)
                    ? field.onChange(value.name)
                    : field.onChange(value);
                }}
                onInputChange={(event, value) => {
                  value && field.onChange(value);
                }}
                renderOption={(props, option) => {
                  return (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {option.name} ({option.isoCode})
                    </Box>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Choose a state"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                    disabled={!enableEditForm}
                    error={!!errors.address?.state}
                    helperText={
                      errors.address?.state
                        ? errors.address?.state.message
                        : null
                    }
                  />
                )}
              />
            )}
          />
        </Box>

        <Box mb={2}>
          <Controller
            name="address.city"
            control={control}
            render={({ field }) => {
              return (
                <Autocomplete
                  {...field}
                  id="city-select"
                  options={cityOptions}
                  disabled={!enableEditForm}
                  autoHighlight
                  fullWidth={true}
                  freeSolo
                  getOptionLabel={(option) =>
                    isCityType(option) ? option.name : option
                  }
                  value={getCityValue(field.value)}
                  onChange={(event, value) => {
                    value && isCityType(value)
                      ? field.onChange(value.name)
                      : field.onChange(value);
                  }}
                  renderOption={(props, option) => {
                    return (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option.name}
                      </Box>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choose a city"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                      disabled={!enableEditForm}
                      error={!!errors.address?.city}
                      helperText={
                        errors.address?.city
                          ? errors.address?.city.message
                          : null
                      }
                    />
                  )}
                />
              );
            }}
          />
        </Box>

        <Box mb={2}>
          <Controller
            name="address.postalCode"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Zip Code"
                disabled={!enableEditForm}
                fullWidth={true}
                error={!!errors.address?.postalCode}
                helperText={
                  errors.address?.postalCode
                    ? errors.address?.postalCode.message
                    : null
                }
              />
            )}
          />
        </Box>
        <Box mb={2}>
          <Controller
            name="address.latitude"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Latitude *"
                disabled={!enableEditForm}
                type={"number"}
                fullWidth={true}
                error={!!errors.address?.latitude}
                helperText={
                  errors.address?.latitude
                    ? errors.address?.latitude.message
                    : null
                }
              />
            )}
          />
        </Box>
        <Box mb={2}>
          <Controller
            name="address.longitude"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Longitude *"
                disabled={!enableEditForm}
                type={"number"}
                fullWidth={true}
                error={!!errors.address?.longitude}
                helperText={
                  errors.address?.longitude
                    ? errors.address?.longitude.message
                    : null
                }
              />
            )}
          />
        </Box>
        <Box mb={2}>
          <Controller
            name="locationAddressDisplayName"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Public displayed address *"
                disabled={!enableEditForm}
                fullWidth={true}
                error={!!errors.locationAddressDisplayName}
                helperText={
                  errors.locationAddressDisplayName
                    ? errors.locationAddressDisplayName.message
                    : null
                }
              />
            )}
          />
        </Box>
        <Box mb={2}>
          <Controller
            name="taxRate"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Tax Rate *"
                disabled={isEdit || !enableEditForm}
                fullWidth={true}
                error={!!errors.taxRate}
                type="number"
                helperText={errors.taxRate ? errors.taxRate.message : null}
              />
            )}
          />
        </Box>
        <Typography mt={2} mb={2}>
          Location Contacts
        </Typography>
        {lcFieldArray.fields.map((locationContactField, index) => {
          if (index >= rolesToMap.length) return null;

          const roles = rolesToMap[index];
          const allRoles = new Set([...roles, ...leftoverRoles.flat()]);
          const locationContactRoleOptions: string[] = Array.from(allRoles);

          return (
            <Box key={locationContactField.id}>
              <Typography mt={2} mb={2}>
                Location Contact {roles.join(", ")}
              </Typography>
              <Box mb={2}>
                {location?.locationContacts?.[index] && (
                  <ButtonUnderlineText
                    isDisabled={!!dirtyFields.locationContacts}
                    onClick={uploadLocationContactThumbnail(
                      location.locationContacts[index].id!,
                    )}
                    qaAttribute={`upload-contact-thumbnail-${index}`}
                  >
                    Upload location contact thumbnail
                  </ButtonUnderlineText>
                )}
              </Box>
              <Box mb={2}>
                <Controller
                  name={`locationContacts.${index}.firstName`}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="First Name"
                      disabled={!enableEditForm}
                      fullWidth={true}
                      error={!!errors.locationContacts?.[index]?.firstName}
                      helperText={
                        errors.locationContacts?.[index]?.firstName?.message ??
                        null
                      }
                    />
                  )}
                />
              </Box>
              <Box mb={2}>
                <Controller
                  name={`locationContacts.${index}.lastName`}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Last Name"
                      disabled={!enableEditForm}
                      fullWidth={true}
                      error={!!errors.locationContacts?.[index]?.lastName}
                      helperText={
                        errors.locationContacts?.[index]?.lastName?.message ??
                        null
                      }
                    />
                  )}
                />
              </Box>
              <Box mb={2}>
                <Controller
                  name={`locationContacts.${index}.email`}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="E-mail"
                      disabled={!enableEditForm}
                      fullWidth={true}
                      error={!!errors.locationContacts?.[index]?.email}
                      helperText={
                        errors.locationContacts?.[index]?.email?.message ?? null
                      }
                    />
                  )}
                />
              </Box>
              <Box mb={2}>
                <Controller
                  name={`locationContacts.${index}.dateOfBirth`}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <DatePicker
                      disabled={!enableEditForm}
                      label="Date of Birth"
                      value={dayjs(value)}
                      onChange={(newValue: dayjs.Dayjs | null) => {
                        onChange(dayjs(newValue).format("MM/DD/YYYY"));
                      }}
                      sx={{ width: "100%" }}
                      slotProps={{
                        textField: {
                          error:
                            !!errors.locationContacts?.[index]?.dateOfBirth,
                          helperText:
                            errors.locationContacts?.[index]?.dateOfBirth
                              ?.message ?? null,
                        },
                      }}
                      // inputFormat="MM/DD/YYYY"
                    />
                  )}
                />
              </Box>
              <Box mb={2}>
                <Controller
                  name={`locationContacts.${index}.roles`}
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      multiple
                      disablePortal
                      forcePopupIcon={true}
                      options={locationContactRoleOptions}
                      isOptionEqualToValue={(option: string, value: string) => {
                        return option === value;
                      }}
                      fullWidth={true}
                      onChange={(_event, value) => {
                        !value ? field.onChange(roles) : field.onChange(value);
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      id={`locationContacts.${index}.roles`}
                      disabled={!enableEditForm}
                      value={
                        field.value && field.value.length > 0 ? field.value : []
                      }
                      renderInput={(params) => (
                        <Box mb={2}>
                          <TextField
                            {...params}
                            label="Choose a role *"
                            inputRef={field.ref}
                            disabled={!enableEditForm}
                            error={!!errors.locationContacts?.[index]?.roles}
                            helperText={
                              errors.locationContacts?.[index]?.roles
                                ?.message ?? null
                            }
                          />
                        </Box>
                      )}
                    />
                  )}
                />
              </Box>
              <Box mb={2}>
                <Controller
                  name={`locationContacts.${index}.bio`}
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      multiline
                      label="Bio"
                      disabled={!enableEditForm}
                      fullWidth={true}
                      error={!!errors.locationContacts?.[index]?.bio}
                      helperText={
                        errors.locationContacts?.[index]?.bio?.message ?? null
                      }
                    />
                  )}
                />
              </Box>
              <Button
                type="button"
                color="blackShades"
                variant="outlined"
                isDisabled={!enableEditForm || lcFieldArray.fields.length <= 1}
                size="medium"
                fullWidth={true}
                onClick={() => {
                  lcFieldArray.remove(index);
                }}
                qaAttribute="remove-location-contact-button"
              >
                Remove location contact field
              </Button>
            </Box>
          );
        })}
        <Button
          type="button"
          color="blackShades"
          variant="outlined"
          isDisabled={
            !enableEditForm || lcFieldArray.fields.length >= rolesToMap.length
          }
          size="medium"
          fullWidth={true}
          onClick={() => {
            lcFieldArray.append(locationContactInitialValuesTemplate);
          }}
          qaAttribute="add-location-contact-button"
        >
          Add location contact field
        </Button>
        <FormControlLabel
          control={<Checkbox disabled={!enableEditForm} />}
          label="Publish to site"
        />
        <Grid container spacing={1} pb={1}>
          <Grid item xs={6}>
            <Button
              type="button"
              color="blackShades"
              variant="outlined"
              size="large"
              fullWidth={true}
              onClick={() => {
                navigate("/");
              }}
              qaAttribute="location-cancel-button"
            >
              Cancel
            </Button>
          </Grid>
          {enableEditForm && (
            <Grid item xs={6}>
              <Button
                type="submit"
                variant="contained"
                size="large"
                fullWidth={true}
                qaAttribute="location-continue-button"
              >
                Save and continue
              </Button>
            </Grid>
          )}
          {deleteLocation && (
            <Grid item xs={6}>
              <Button
                type="button"
                color="blackShades"
                variant="outlined"
                size="large"
                fullWidth={true}
                qaAttribute="location-delete-button"
                onClick={() => {
                  deleteLocation();
                }}
              >
                Delete
              </Button>
            </Grid>
          )}
        </Grid>
      </form>
    </Container>
  );
};

export default Location;
