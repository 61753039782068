import React, { useContext } from "react";
import Container from "components/atoms/Container";
import { useNavigate } from "react-router-dom";
import Package from "components/templates/Package/Package";
import { LoadingContext } from "context/LoadingContext";
import {
  IPackageInitialValues,
  packageInitialValues,
} from "common/types/components/templates";
import { createPackageAPI } from "services/packagesService";
import ApiError from "components/atoms/ApiError";

function CreatePackage() {
  const [apiError, setApiError] = React.useState("");
  const navigate = useNavigate();
  const { setLoading } = useContext(LoadingContext);

  const goToSuccessPage = () => {
    navigate("/success", {
      state: {
        title: "Package created successfully!",
      },
    });
  };

  const onSubmit = async (data: IPackageInitialValues) => {
    const choosedPricingModel =
      data.minReservationDays === data.maxReservationDays ? "FIXED" : "PER_DAY";
    setLoading(true);
    const packageBody = {
      experienceId: data.experience.id,
      lodgingId: data.lodging.id,
      title: data.title,
      description: data.description,
      minAmountOfGuests: data.minAmountOfGuests,
      maxAmountOfGuests: data.maxAmountOfGuests,
      minReservationDays: data.minReservationDays,
      maxReservationDays: data.maxReservationDays,
      participantPrice: data.participantPrice,
      nonParticipantPrice: data.nonParticipantPrice,
      pricingModel: choosedPricingModel, // check
      published: data.published,
      mostPopular: data.mostPopular,
      soldOut: data.soldOut,
    };
    try {
      await createPackageAPI(packageBody);
      setLoading(false);
      setApiError("");

      goToSuccessPage();
    } catch (error: any) {
      setLoading(false);
      setApiError(error.message);
      console.error("Error: ", error);
      return error;
    }
  };

  return (
    <Container>
      {apiError && <ApiError>{apiError}</ApiError>}
      <Package
        title={"Create a package"}
        loadedPackage={true}
        /* @ts-ignore */
        initialValues={packageInitialValues}
        getSubmitValues={onSubmit}
        canEdit={false}
      />
    </Container>
  );
}

export default CreatePackage;
