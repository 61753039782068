import { useMutation, useQueryClient } from "@tanstack/react-query";
import { experienceApi } from "./experienceService";
import AsyncStatuses from "common/types/enums/asyncStatuses";
import { ExperiencesAPIApiCreateImages1Request } from "api/v1.0";
import { experienceKeys } from "./experienceQueryKeyFactory";

function useMutateExperienceImages() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (
      createImagesApiRequest: ExperiencesAPIApiCreateImages1Request,
    ) => experienceApi.createImages1(createImagesApiRequest),
    onSuccess: async (_data, arg) => {
      await queryClient.invalidateQueries({
        queryKey: experienceKeys.detailResource(arg.id, "images"),
      });
    },
  });
}

export const useCreateExperienceImages = () => {
  const mutateExperienceImages = useMutateExperienceImages();

  return {
    ...mutateExperienceImages,
    createStatus: mutateExperienceImages.status as AsyncStatuses,
  };
};
