import React from "react";
import { Route, Routes } from "react-router-dom";
import CreateLocation from "components/pages/CreateLocation/CreateLocation";
import Home from "components/pages/Home/Home";
import Login from "components/pages/Login/Login";
import CreateExperience from "components/pages/CreateExperience/CreateExperience";
import CreateLodging from "components/pages/CreateLodging/CreateLodging";
import EditExperience from "components/pages/EditExperience/EditExperience";
import SuccessPage from "components/pages/Success/Success";
import CreatePackage from "components/pages/CreatePackage/CreatePackage";
import EditLocation from "components/pages/EditLocation/EditLocation";
import EditLodging from "components/pages/EditLodging/EditLodging";
import EditPackage from "components/pages/EditPackage/EditPackage";
import SearchExperience from "components/pages/SearchExperience/SearchExperience";
import SearchLocation from "components/pages/SearchLocation/SearchLocation";
import SearchLodging from "components/pages/SearchLodging/SearchLodging";
import AuthRoutes from "./AuthRoutes";
import UploadThumbnail from "components/pages/UploadThumbnail/UploadThumbnail";
import UploadImages from "components/pages/UploadImages/UploadImages";
import Forbidden from "components/pages/Forbidden/Forbidden";
import NotFound from "components/pages/NotFound/NotFound";
import SearchPackage from "components/pages/SearchPackage/SearchPackage";
import BlackoutDateLocation from "components/pages/BlackoutDateLocation/BlackoutDateLocation";
import BlackoutDateExperience from "components/pages/BlackoutDateExperience/BlackoutDateExperience";
import CreateBooking from "components/pages/CreateBooking/CreateBooking";
import ViewBooking from "components/pages/ViewBooking/ViewBooking";
import EditLodgingAmenities from "components/pages/EditLodgingAmenities/EditLodgingAmenities";

function ApplicationRoutes() {
  return (
    <Routes>
      <Route element={<AuthRoutes isPrivateRoute={false} />}>
        <Route path="/login" element={<Login />} />
        <Route path="/forbidden" element={<Forbidden />} />
      </Route>
      <Route element={<AuthRoutes isPrivateRoute={true} />}>
        <Route path="/" element={<Home />} />
        <Route path="location">
          <Route path="create" element={<CreateLocation />} />
          <Route path="edit" element={<EditLocation />} />
          <Route path="search" element={<SearchLocation />} />
          <Route path="upload">
            <Route path="thumbnail" element={<UploadThumbnail />} />
            <Route path="images" element={<UploadImages />} />
          </Route>
          <Route path="blackout-dates" element={<BlackoutDateLocation />} />
        </Route>
        <Route path="location-contact">
          <Route path="upload">
            <Route path="thumbnail" element={<UploadThumbnail />} />
          </Route>
        </Route>
        <Route path="lodging">
          <Route path="create" element={<CreateLodging />} />
          <Route path="edit" element={<EditLodging />} />
          <Route path="search" element={<SearchLodging />} />
          <Route path=":id">
            <Route path="amenities" element={<EditLodgingAmenities />} />
          </Route>
        </Route>
        <Route path="experience">
          <Route path="create" element={<CreateExperience />} />
          <Route path="edit" element={<EditExperience />} />
          <Route path="search" element={<SearchExperience />} />
          <Route path="upload">
            <Route path="thumbnail" element={<UploadThumbnail />} />
            <Route path="images" element={<UploadImages />} />
          </Route>
          <Route path="blackout-dates" element={<BlackoutDateExperience />} />
        </Route>
        <Route path="package">
          <Route path="create" element={<CreatePackage />} />
          <Route path="edit" element={<EditPackage />} />
          <Route path="search" element={<SearchPackage />} />
        </Route>
        <Route path="booking">
          <Route path="create" element={<CreateBooking />} />
          <Route path="view" element={<ViewBooking />} />
        </Route>
        <Route path="/success" element={<SuccessPage />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default ApplicationRoutes;
