import React from "react";
import Container from "components/atoms/Container";
import { useNavigate } from "react-router-dom";
import Location from "components/templates/Location/Location";
import ApiError from "components/atoms/ApiError";
import {
  ILocationFormValues,
  locationFormInitialValuesTemplate,
  locationToCreateRequest,
  useCreateLocation,
  useGetLocationContactRoles,
} from "services/location";

function CreateLocation() {
  const { mutate: createLocation, ...restCreateLocation } = useCreateLocation();
  const { data: locationContactRoles = [] } = useGetLocationContactRoles();
  const locationContactRoleNames = locationContactRoles.map((lcr) => lcr.name!);
  const navigate = useNavigate();

  const goToNextPage = (locationId: string) => {
    navigate(`/lodging/create?location-id=${locationId}`, {
      state: {
        locationId: locationId,
      },
    });
  };

  const onSubmit = (event: ILocationFormValues) => {
    createLocation(
      {
        createLocationRequest: locationToCreateRequest(event),
      },
      {
        onSuccess: (data) => {
          const locationId = data.data.id;
          goToNextPage(locationId!);
        },
      },
    );
  };

  return (
    <Container>
      {restCreateLocation.isError && (
        <ApiError>{restCreateLocation.error?.message}</ApiError>
      )}
      <Location
        title={"Create a location"}
        loadedLocation={true}
        initialValues={locationFormInitialValuesTemplate}
        getSubmitValues={onSubmit}
        locationContactRoleNames={locationContactRoleNames}
        canEdit={false}
      />
    </Container>
  );
}

export default CreateLocation;
