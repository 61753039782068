import React from "react";
import Container from "components/atoms/Container";
import { useNavigate } from "react-router-dom";
import Lodging from "components/templates/Lodging/Lodging";
import { LodgingResponse } from "api/v1.0";

function CreateLodging() {
  const navigate = useNavigate();

  const handleSubmitSuccess = (lodgingResponse: LodgingResponse) => {
    navigate(`/lodging/${lodgingResponse.id}/amenities`);
  };

  return (
    <Container>
      <Lodging
        title={"Create a lodging"}
        canEdit={false}
        onSubmitSuccess={handleSubmitSuccess}
      />
    </Container>
  );
}

export default CreateLodging;
