import React, { useContext, useEffect, useState } from "react";
import Container from "components/atoms/Container";
import { useNavigate } from "react-router-dom";
import Package from "components/templates/Package/Package";
import {
  IPackageInitialValues,
  packageInitialValues,
} from "common/types/components/templates";
import { LoadingContext } from "context/LoadingContext";
import {
  deletePackageAPI,
  editPackageAPI,
  getOnePackageAPI,
} from "services/packagesService";
import useParameter from "hooks/useParameter";
import { getOneExperienceAPI } from "services/experiencesService";
import { getOneLodgingAPI } from "services/lodgingsService";
import { IPackageRequest } from "services/types/packages";
import ApiError from "components/atoms/ApiError";
import { locationApi } from "services/location";

function EditPackage() {
  const navigate = useNavigate();
  const { setLoading } = useContext(LoadingContext);
  const [initialValues, setInitialValues] = useState(packageInitialValues);
  const { getPackageId } = useParameter();
  const packageId = getPackageId();
  const [loadedPackage, setLoadedPackages] = useState(false);
  const [apiError, setApiError] = React.useState("");

  useEffect(() => {
    async function loadPackage() {
      try {
        setLoading(true);
        const packageInfo = await getOnePackageAPI(packageId);
        const experienceInfo = await getOneExperienceAPI(
          packageInfo.experienceId,
        );
        const locationInfo = (
          await locationApi.findLocationById({
            id: experienceInfo.locationId,
          })
        ).data;
        const lodgingInfo = packageInfo.lodgingId
          ? await getOneLodgingAPI(packageInfo.lodgingId)
          : null;
        const initialValuesAPI: IPackageInitialValues = {
          ...packageInfo,
          location: {
            label: locationInfo.name!,
            id: locationInfo.id!,
          },
          experience: {
            label: experienceInfo.name,
            id: experienceInfo.id,
          },
          lodging: {
            label: lodgingInfo?.description || " ",
            id: lodgingInfo?.id || "",
          },
        };
        setInitialValues(initialValuesAPI);
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
        setLoadedPackages(true);
      }
    }
    loadPackage();
  }, [setLoading]);

  const goToSuccessPage = (status: string) => {
    navigate("/success", {
      state: {
        title: `Package ${status} successfully!`,
      },
    });
  };

  const onSubmit = async (event: IPackageInitialValues) => {
    setLoading(true);
    const choosedPricingModel =
      event.minReservationDays === event.maxReservationDays
        ? "FIXED"
        : "PER_DAY";
    try {
      const packageBody: IPackageRequest = {
        ...event,
        experienceId: event.experience.id,
        lodgingId: event.lodging.id,
        pricingModel: choosedPricingModel, // check
      };
      await editPackageAPI(packageId, packageBody);
      setLoading(false);
      setApiError("");

      goToSuccessPage("edited");
    } catch (error: any) {
      console.log("Error: ", error);
      setApiError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      await deletePackageAPI(packageId);
      setLoading(false);
      setApiError("");
      goToSuccessPage("deleted");
    } catch (error) {
      console.log("Error: ", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      {apiError && <ApiError>{apiError}</ApiError>}
      <Package
        title={"Edit a package"}
        loadedPackage={loadedPackage}
        /* @ts-ignore */
        initialValues={initialValues}
        getSubmitValues={onSubmit}
        pricesDisabled={true}
        canEdit={true}
        deletePackage={handleDelete}
      />
    </Container>
  );
}

export default EditPackage;
