import React, { useContext, useEffect, useState } from "react";
import Container from "components/atoms/Container";
import Button from "components/atoms/Button";
import {
  Autocomplete,
  Box,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import H3 from "components/atoms/H3";
import { useForm, Controller } from "react-hook-form";
import { LoadingContext } from "context/LoadingContext";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs, { Dayjs } from "dayjs";
import ApiError from "components/atoms/ApiError";
import { IBlockedDatesResponse } from "services/types/generic";
import { locationApi } from "services/location";

const schema = yup.object().shape({
  location: yup
    .object()
    .required("Location is required")
    .shape({
      id: yup.string(),
      label: yup.string(),
    })
    .test("validateLocation", (value, { createError }) => {
      if (value.id === "") {
        return createError({ message: "Location is required" });
      }
      return true;
    }),
  blackoutDates: yup.array().required("Date is required"),
  radioGroup: yup.object(),
});

const BlackoutDateLocation: React.FC = () => {
  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: { location: { label: "", id: "" } },
  });

  const { setLoading } = useContext(LoadingContext);
  const [unavailabilityDates, setUnavailabilityDates] = useState(
    [] as IBlockedDatesResponse[],
  );
  const [locationId, setLocationId] = useState("");
  const [apiError, setApiError] = useState("");
  const [typeOfChange, setTypeOfChange] = useState("block");
  const [autoCompleteLocations, setAutoCompleteLocations] = useState([
    { label: "", id: "" },
  ]);

  useEffect(() => {
    async function loadLocations() {
      try {
        setLoading(true);
        const locationsList = (
          await locationApi.findAllLocations({
            page: 0,
            pageSize: 100,
          })
        ).data;
        const locationsListFiltered = locationsList.content!.map(
          (location) => ({
            label: location.name!,
            id: location.id!,
          }),
        );
        setAutoCompleteLocations(locationsListFiltered);
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    }
    loadLocations();
  }, [setLoading]);

  useEffect(() => {
    async function getBlockedDates() {
      const yesterday = dayjs().subtract(1, "day");
      const oneYearForNow = dayjs().add(1, "year");
      const formatedInitalStartDate = yesterday.format("YYYY-MM-DD");
      const formatedInitalEndDate = oneYearForNow.format("YYYY-MM-DD");
      setLoading(true);
      const unavailabilityDatesResponse = (
        await locationApi.findLocationBlockedDates({
          id: locationId,
          startDate: formatedInitalStartDate,
          endDate: formatedInitalEndDate,
        })
      ).data;
      setUnavailabilityDates(
        unavailabilityDatesResponse as IBlockedDatesResponse[],
      );
      setLoading(false);
    }
    if (locationId) {
      getBlockedDates();
    } else {
      setLocationId("");
    }
  }, [locationId, setLoading]);

  const disableBlockDates = (date: any) => {
    const disableBlockDatesArray: any = [];
    unavailabilityDates.forEach((element: any) => {
      disableBlockDatesArray.push(element.blockedDate);
    });
    if (typeOfChange === "block") {
      return disableBlockDatesArray.includes(date.format("YYYY-MM-DD"));
    } else if (typeOfChange === "unblock") {
      return !disableBlockDatesArray.includes(date.format("YYYY-MM-DD"));
    }
  };

  const dateRangeToArray = (startDate: Dayjs, endDate: Dayjs) => {
    const dateArray = [] as string[];
    let currentDate = startDate;
    if (startDate.isSame(endDate)) {
      return [startDate];
    }
    while (currentDate <= endDate) {
      dateArray.push(currentDate.format("YYYY-MM-DD"));
      currentDate = currentDate.add(1, "days");
    }
    return dateArray;
  };

  const onSubmitForm = async () => {
    const valuesForm = getValues();
    const blackoutDates = valuesForm.blackoutDates;
    const blockDatesArray: any[] = dateRangeToArray(
      blackoutDates[0],
      blackoutDates[1],
    );
    const body = {
      blocked: typeOfChange === "block" ? true : false,
      dates: blockDatesArray,
      reason: "blocked dates",
    };
    try {
      await locationApi.blockOrUnblockLocationDates({
        id: locationId,
        blockedDateRequest: body,
      });
      goToSuccessPage();
      setLoading(false);
      setApiError("");
    } catch (error: any) {
      setLoading(false);
      setApiError(error.message);
    }
  };

  const goToSuccessPage = () => {
    navigate("/success", {
      state: {
        title:
          typeOfChange === "block"
            ? "You blocked dates successfully!"
            : "You unblocked dates successfully!",
      },
    });
  };

  const updateSelection = (event: any, value: any) => {
    setTypeOfChange(value);
  };

  const navigate = useNavigate();
  const title = "Blackout Dates - Location";
  return (
    <Container>
      {apiError && <ApiError>{apiError}</ApiError>}
      <H3 fontWeight="700">{title}</H3>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Box mb={2}>
          <Controller
            name="radioGroup"
            control={control}
            render={({ field: { onChange, ...props } }) => (
              <RadioGroup
                {...props}
                id="radioGroup"
                aria-labelledby="radio-buttons-group-label"
                name="radio-buttons-group"
                defaultValue="block"
                onChange={updateSelection}
              >
                <Box display="flex" justifyContent="space-around">
                  <FormControlLabel
                    value="block"
                    control={<Radio />}
                    label="Block Dates"
                  />
                  <FormControlLabel
                    value="unblock"
                    control={<Radio />}
                    label="Unblock Dates"
                  />
                </Box>
              </RadioGroup>
            )}
          />
        </Box>
        <Box mb={2}>
          <Controller
            name="location"
            control={control}
            render={({ field }) => (
              <Autocomplete
                disablePortal
                freeSolo
                forcePopupIcon={true}
                onChange={(event, value) => {
                  value === null
                    ? field.onChange({
                        label: "",
                        id: "",
                      })
                    : field.onChange(value);
                }}
                onBlur={(value) => {
                  if (value) {
                    setLocationId(field.value.id ? field.value.id : "");
                  }
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="location"
                options={autoCompleteLocations}
                isOptionEqualToValue={(option: any, value: any) => {
                  return option === value;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Location"
                    inputRef={field.ref}
                    error={!!errors.location}
                    helperText={
                      errors.location ? errors.location.message : null
                    }
                  />
                )}
              />
            )}
          />
        </Box>
        <Box mb={2}>
          <Controller
            name="blackoutDates"
            control={control}
            render={({ field }) => (
              <>
                <DateRangePicker
                  disablePast
                  inputRef={field.ref}
                  onChange={(date) => {
                    field.onChange(date);
                  }}
                  shouldDisableDate={disableBlockDates}
                  localeText={{ start: "Start Date", end: "End Date" }}
                />
                <FormHelperText error={!!errors.blackoutDates}>
                  {errors.blackoutDates ? errors.blackoutDates.message : null}
                </FormHelperText>
              </>
            )}
          />
        </Box>
        <Grid container spacing={1} pb={1}>
          <Grid item xs={6}>
            <Button
              type="button"
              color="blackShades"
              variant="outlined"
              size="large"
              fullWidth={true}
              onClick={() => {
                navigate("/");
              }}
              qaAttribute="location-blackout-button"
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              type="submit"
              variant="contained"
              size="large"
              fullWidth={true}
              qaAttribute="location-continue-button"
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default BlackoutDateLocation;
