import Container from "components/atoms/Container";
import React, { useContext } from "react";
import Experience from "components/templates/Experience/Experience";
import { useNavigate } from "react-router-dom";
import { createExperienceAPI } from "services/experiencesService";
import {
  IExperienceInitialValues,
  experienceInitialValues,
} from "common/types/components/templates";
import { LoadingContext } from "context/LoadingContext";
import ApiError from "components/atoms/ApiError";

function CreateExperience() {
  const navigate = useNavigate();
  const { setLoading } = useContext(LoadingContext);
  const [apiError, setApiError] = React.useState("");

  const goToSuccessPage = (experienceId: string) => {
    navigate(`/experience/upload/thumbnail?experience-id=${experienceId}`);
  };

  const onSubmit = async (data: IExperienceInitialValues) => {
    setLoading(true);
    const experienceBody = {
      name: data.name,
      locationId: data.location.id,
      experienceTypeId: data.experienceType.id,
      wildlifeId: data.wildlife.id,
      description: data.description,
      shortDescription: data.shortDescription,
      whatToExpectDescription: data.whatToExpectDescription,
      published: data.publish,
    };
    try {
      const data: any = await createExperienceAPI(experienceBody);
      setLoading(false);
      setApiError("");
  
      const experienceId = data.id;

      goToSuccessPage(experienceId);

    } catch (error:any) {
      setLoading(false);
      setApiError(error.message);
      console.error("Error: ", error);
      return error;
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      {apiError && <ApiError>{apiError}</ApiError>}
      <Experience
        title="Create an Experience"
        loadedExperience={true}
        initialValues={experienceInitialValues}
        getSubmitValues={onSubmit}
        canEdit={false}
      />
    </Container>
  );
}

export default CreateExperience;
