import React from "react";
import {
  StyledPreviewImage,
  StyledTextPreviewImage,
} from "./UploadPhotoCard.style";
import { Grid, IconButton, Typography } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { IFile } from "common/types/interfaces/global";

type UploadPhotoCardProps = {
  photo: IFile;
  deleteOnePhoto: (photo: IFile) => void;
  index: number;
  showPhotoOrder: boolean;
};

const UploadPhotoCard: React.FC<UploadPhotoCardProps> = ({
  photo,
  deleteOnePhoto,
  index,
  showPhotoOrder,
}) => {
  const deletePhoto = () => {
    deleteOnePhoto(photo);
  };

  return (
    <Grid container spacing={1} display="flex" alignItems="center" mt={2}>
      <Grid item xs={3}>
        <StyledPreviewImage
          src={photo.preview}
          alt="preview"
          onLoad={() => {
            URL.revokeObjectURL(photo.preview);
          }}
        />
      </Grid>
      <Grid item xs={9}>
        <Grid container spacing={1}>
          <Grid item xs={10}>
            <StyledTextPreviewImage>Name: {photo.name}</StyledTextPreviewImage>
          </Grid>
          <Grid item xs={2}>
            <IconButton onClick={deletePhoto}>
              <DeleteOutlineIcon fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
        {showPhotoOrder && <Typography>Photo order:{index + 1}</Typography>}
      </Grid>
    </Grid>
  );
};

export default UploadPhotoCard;
