import React, { useContext, useEffect, useState } from "react";
import Container from "components/atoms/Container";
import { LoadingContext } from "context/LoadingContext";
import H3 from "components/atoms/H3";
import { getBookingsAPI } from "services/bookingService";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Pagination,
  Stack,
  Typography,
} from "@mui/material";
import { IGetBookingsResponse } from "services/types/booking";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { getOneExperienceAPI } from "services/experiencesService";
import { getOnePackageAPI } from "services/packagesService";
import { locationApi } from "services/location";

const ViewBooking = () => {
  const { setLoading } = useContext(LoadingContext);
  const [bookings, setBookings] = useState({} as IGetBookingsResponse);
  const [page, setPage] = useState(1);
  const [calendarDate, setCalendarDate] = useState("");
  const [bookingsCard, setBookingsCard] = useState([] as any);

  useEffect(() => {
    async function loadBookings() {
      try {
        const bookings = await getBookingsAPI(calendarDate, page - 1, 4);
        setBookings(bookings);
      } catch (error) {
        console.log("error", error);
      }
    }
    if (calendarDate) {
      loadBookings();
    }
  }, [page, calendarDate]);

  useEffect(() => {
    async function loadApis() {
      try {
        setLoading(true);
        const bookingCardContent: any[] = [];
        if (bookings?.content) {
          for (const booking of bookings.content) {
            const locationPromise = locationApi.findLocationById({
              id: booking.locationId,
            });

            const experiencePromise = getOneExperienceAPI(booking.experienceId);
            const packagePromise = getOnePackageAPI(booking.packageId);

            const [locationResponse, experienceResponse, packageResponse] =
              await Promise.all([
                locationPromise,
                experiencePromise,
                packagePromise,
              ]);

            const newBooking = {
              booking: booking,
              location: locationResponse.data,
              experience: experienceResponse,
              package: packageResponse,
            };

            bookingCardContent.push(newBooking);
          }
          setBookingsCard(bookingCardContent);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    }
    if (bookings?.content) {
      loadApis();
    }
  }, [bookings, setLoading]);

  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
  };

  const handleCalendarChange = (e: Dayjs) => {
    const teste = dayjs(e).format("YYYY/MM").replace("/", "-");
    setCalendarDate(teste);
  };

  return (
    <Container>
      <H3 fontWeight="700">View Hunts</H3>
      <DatePicker
        disableHighlightToday={true}
        onAccept={(e: any) => handleCalendarChange(e)}
        label={"Select the month"}
        views={["month", "year"]}
      />
      {bookingsCard?.map((elm: any) => {
        return (
          <>
            <Box mt={3}>
              <Card key={elm.booking.bookingId}>
                <CardHeader
                  title={elm.booking.bookingNumber}
                  sx={{ borderBottom: "1px solid lightgrey" }}
                  action={
                    <Box pr={2} pt={1}>
                      <Typography
                        color={
                          elm.booking.status === "CONFIRMED"
                            ? { color: "grey" }
                            : elm.booking.status === "CANCELED"
                              ? { color: "red" }
                              : elm.booking.status === "VERIFIED"
                                ? { color: "green" }
                                : {}
                        }
                      >
                        {elm.booking.status.slice(0, 1)}
                        {elm.booking.status.slice(1).toLowerCase()}
                      </Typography>
                    </Box>
                  }
                />
                <CardContent>
                  <Typography>
                    <b>Booking</b>
                  </Typography>
                  <Box ml={2}>
                    <Typography>
                      - Hunting Guest: {elm.booking.participantCount}
                    </Typography>
                    <Typography>
                      - Non Hunting Guest: {elm.booking.nonParticipantCount}
                    </Typography>
                    <Typography>
                      - Dates:{" "}
                      {dayjs(elm.booking.startDate).format("MM/DD/YYYY")} -{" "}
                      {dayjs(elm.booking.endDate).format("MM/DD/YYYY")}
                    </Typography>
                  </Box>
                  <Typography>
                    <b>Location:</b> {elm.location.name}
                  </Typography>
                  <Typography>
                    <b>Experience:</b> {elm.experience.name}
                  </Typography>
                  <Typography>
                    <b>Package:</b> {elm.package.title}
                  </Typography>
                  <Typography>
                    <b>Location:</b> {elm.location.name}
                  </Typography>
                  <Typography display="flex">
                    <b>Payment Status: &nbsp;</b>
                    {elm.booking.paymentStatus === "PENDING" && (
                      <Typography>Pending</Typography>
                    )}
                    {elm.booking.paymentStatus === "SUCCESSFUL" && (
                      <Typography>Successful</Typography>
                    )}
                    {elm.booking.paymentStatus === "" && (
                      <Typography>Failed</Typography>
                    )}
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          </>
        );
      })}
      {bookings?.content?.length === 0 ? (
        <Box mt={3}>
          <Typography>There is no booking for this month</Typography>
        </Box>
      ) : (
        <Box mt={3}>
          <Stack spacing={2}>
            <Pagination
              count={bookings.totalPages}
              page={page}
              onChange={handlePaginationChange}
            />
          </Stack>
        </Box>
      )}
    </Container>
  );
};

export default ViewBooking;
