import styled from "styled-components";
import React, { ReactNode } from "react";

type StyledH2Props = {
  children: ReactNode;
  align?: "center" | "left" | "right";
};

const StyledH2 = styled("h2")<StyledH2Props>(({ align }) => ({
  fontSize: "28px",
  fontWeight: "400",
  lineHeight: "24px",
  textAlign: align,
}));

export const H2 = ({ children, align }: StyledH2Props): React.ReactElement => {
  return <StyledH2 align={align}>{children}</StyledH2>;
};

export default H2;
