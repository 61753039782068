import React, { useEffect, useState } from "react";
import Button from "components/atoms/Button";
import { Controller } from "react-hook-form";
import Container from "components/atoms/Container";
import H3 from "components/atoms/H3";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  TextField,
  Typography,
  FormHelperText,
} from "@mui/material";
import ButtonEditWithLocker from "components/molecules/ButtonWithLocker";
import {
  BoldItalicUnderlineToggles,
  ListsToggle,
  MDXEditor,
  MDXEditorMethods,
  listsPlugin,
  toolbarPlugin,
} from "@mdxeditor/editor";
import "@mdxeditor/editor/style.css";
import { StyledBoxDescription } from "./Lodging.style";
import LoadingOverlay from "components/atoms/LoadingOverlay";
import { initialBedroomSchema, useLodgingForm } from "services/lodging";
import useParameter from "hooks/useParameter";
import ApiError from "components/atoms/ApiError";
import ButtonUnderlineText from "components/atoms/ButtonUnderlineText";
import { LodgingResponse } from "api/v1.0";

interface LodgingProps {
  title: string;
  canEdit: boolean;
  onSubmitSuccess: (lodgingResponse: LodgingResponse) => void;
  onDeleteSuccess?: (status: string) => void;
}

const Lodging: React.FC<LodgingProps> = ({
  title,
  canEdit,
  onSubmitSuccess,
  onDeleteSuccess,
}): React.ReactElement => {
  const navigate = useNavigate();
  const { getLodgingId, getLocationId } = useParameter();
  const routeState = useLocation().state as {
    locationId: string | undefined;
    lodgingId: string | undefined;
  };
  let lodgingId = routeState.lodgingId;
  let locationId = routeState.locationId;
  if (!lodgingId) lodgingId = getLodgingId();
  if (!locationId) locationId = getLocationId();

  const [enableEditForm, setEnableEditForm] = useState(canEdit ? false : true);
  const descriptionRef = React.useRef<MDXEditorMethods>(null);
  const sleepingArrangementsRef = React.useRef<MDXEditorMethods>(null);

  const {
    form,
    bedroomsFieldArray,
    onSubmit,
    isLoading,
    onDelete,
    error,
    isError,
    initialValues,
  } = useLodgingForm(locationId, lodgingId);

  const toggleEditForm = () => {
    setEnableEditForm((enable) => !enable);
  };

  const {
    control,
    setValue,
    trigger,
    formState: { errors },
  } = form;

  useEffect(() => {
    if (descriptionRef.current) {
      descriptionRef.current.setMarkdown(initialValues.description);
    }
    if (
      sleepingArrangementsRef.current &&
      initialValues.sleepingArrangementDescription
    ) {
      sleepingArrangementsRef.current.setMarkdown(
        initialValues.sleepingArrangementDescription,
      );
    }
  }, [initialValues]);

  const editAmenities = () => {
    navigate(`/lodging/${lodgingId}/amenities`);
  };

  return (
    <>
      {isError && <ApiError>{error?.message}</ApiError>}
      {isLoading ? (
        <LoadingOverlay />
      ) : (
        <Container>
          <H3 fontWeight="700">{title}</H3>
          {canEdit && (
            <ButtonEditWithLocker
              onClick={() => {
                toggleEditForm();
              }}
              lockerClose={enableEditForm}
              qaAttribute="lodging-edit-page-edit-button"
            >
              Edit
            </ButtonEditWithLocker>
          )}
          {lodgingId && lodgingId !== "" && (
            <Box mb={1}>
              <ButtonUnderlineText
                onClick={editAmenities}
                qaAttribute="button-edit-amenities"
              >
                Edit Amenities
              </ButtonUnderlineText>
            </Box>
          )}
          <form
            onSubmit={form.handleSubmit(
              onSubmit(
                (lodgingResponse: LodgingResponse) =>
                  onSubmitSuccess(lodgingResponse),
                (lodgingResponse: LodgingResponse) =>
                  onSubmitSuccess(lodgingResponse),
              ),
            )}
          >
            <Typography mt={2} mb={1}>
              Description
            </Typography>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <>
                  <StyledBoxDescription>
                    <MDXEditor
                      {...field}
                      ref={descriptionRef}
                      markdown={initialValues.description}
                      readOnly={!enableEditForm}
                      onChange={() => {
                        setValue(
                          "description",
                          descriptionRef.current?.getMarkdown() ?? "",
                        );
                        trigger("description").catch((err) =>
                          console.error(err),
                        );
                      }}
                      plugins={[
                        listsPlugin(),
                        toolbarPlugin({
                          toolbarContents: () => (
                            <>
                              <BoldItalicUnderlineToggles />
                              <ListsToggle />
                            </>
                          ),
                        }),
                      ]}
                    />
                  </StyledBoxDescription>
                  <Box ml={2}>
                    <FormHelperText error={!!errors.description}>
                      {errors.description ? errors.description.message : null}
                    </FormHelperText>
                  </Box>
                </>
              )}
            />
            <Typography mt={2} mb={1}>
              Maximum Capacity
            </Typography>
            <Controller
              name="maximumCapacity"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Number of people  *"
                  disabled={!enableEditForm}
                  type="number"
                  onChange={(event) =>
                    field.onChange(parseInt(event.target.value, 10))
                  }
                  fullWidth={true}
                  error={!!errors.maximumCapacity}
                  helperText={
                    errors.maximumCapacity
                      ? errors.maximumCapacity.message
                      : null
                  }
                />
              )}
            />
            <Typography mt={2} mb={1}>
              Bathrooms available
            </Typography>
            <Controller
              name="numberOfBathrooms"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Number of bathrooms *"
                  disabled={!enableEditForm}
                  type="number"
                  onChange={(event) =>
                    field.onChange(parseInt(event.target.value, 10))
                  }
                  fullWidth={true}
                  error={!!errors.numberOfBathrooms}
                  helperText={
                    errors.numberOfBathrooms
                      ? errors.numberOfBathrooms.message
                      : null
                  }
                />
              )}
            />
            <Typography mt={2} mb={1}>
              Sleeping Lodging Arrangements
            </Typography>
            <Controller
              name="sleepingArrangementDescription"
              control={control}
              render={({ field }) => (
                <>
                  <StyledBoxDescription>
                    <MDXEditor
                      {...field}
                      ref={sleepingArrangementsRef}
                      markdown={
                        initialValues.sleepingArrangementDescription ?? ""
                      }
                      readOnly={!enableEditForm}
                      onChange={() => {
                        setValue(
                          "sleepingArrangementDescription",
                          sleepingArrangementsRef.current?.getMarkdown() &&
                            sleepingArrangementsRef.current
                              ?.getMarkdown()
                              .trim().length > 0
                            ? sleepingArrangementsRef.current.getMarkdown()
                            : null,
                        );
                        trigger("sleepingArrangementDescription").catch((err) =>
                          console.error(err),
                        );
                      }}
                      plugins={[
                        listsPlugin(),
                        toolbarPlugin({
                          toolbarContents: () => (
                            <>
                              <BoldItalicUnderlineToggles />
                              <ListsToggle />
                            </>
                          ),
                        }),
                      ]}
                    />
                  </StyledBoxDescription>
                  <Box ml={2}>
                    <FormHelperText
                      error={!!errors.sleepingArrangementDescription}
                    >
                      {errors.sleepingArrangementDescription
                        ? errors.sleepingArrangementDescription.message
                        : null}
                    </FormHelperText>
                  </Box>
                </>
              )}
            />
            {bedroomsFieldArray.fields.map((bedroomField, idx) => (
              <Box
                display="flex"
                flexDirection="column"
                gap={2}
                pt={2}
                key={bedroomField.id}
              >
                <Typography>Bedroom {idx + 1}</Typography>
                <Controller
                  name={`bedrooms.${idx}.name`}
                  control={control}
                  defaultValue={`Bedroom ${idx + 1}`}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Bedroom name"
                      disabled={!enableEditForm}
                      type="text"
                      fullWidth={true}
                      error={!!errors.bedrooms?.[idx]?.name}
                      helperText={errors.bedrooms?.[idx]?.name?.message ?? null}
                    />
                  )}
                />
                <Controller
                  name={`bedrooms.${idx}.numberOfFullBeds`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Number of full beds*"
                      disabled={!enableEditForm}
                      type="number"
                      onChange={(event) =>
                        field.onChange(parseInt(event.target.value, 10))
                      }
                      fullWidth={true}
                      error={!!errors.bedrooms?.[idx]?.numberOfFullBeds}
                      helperText={
                        errors.bedrooms?.[idx]?.numberOfFullBeds?.message ??
                        null
                      }
                    />
                  )}
                />
                <Controller
                  name={`bedrooms.${idx}.numberOfTwinBeds`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Number of twin beds*"
                      disabled={!enableEditForm}
                      type="number"
                      onChange={(event) =>
                        field.onChange(parseInt(event.target.value, 10))
                      }
                      fullWidth={true}
                      error={!!errors.bedrooms?.[idx]?.numberOfTwinBeds}
                      helperText={
                        errors.bedrooms?.[idx]?.numberOfTwinBeds?.message ??
                        null
                      }
                    />
                  )}
                />
                <Controller
                  name={`bedrooms.${idx}.numberOfQueenBeds`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Number of queen beds*"
                      disabled={!enableEditForm}
                      type="number"
                      onChange={(event) =>
                        field.onChange(parseInt(event.target.value, 10))
                      }
                      fullWidth={true}
                      error={!!errors.bedrooms?.[idx]?.numberOfQueenBeds}
                      helperText={
                        errors.bedrooms?.[idx]?.numberOfQueenBeds?.message ??
                        null
                      }
                    />
                  )}
                />
                <Controller
                  name={`bedrooms.${idx}.numberOfKingBeds`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Number of king beds*"
                      disabled={!enableEditForm}
                      type="number"
                      onChange={(event) =>
                        field.onChange(parseInt(event.target.value, 10))
                      }
                      fullWidth={true}
                      error={!!errors.bedrooms?.[idx]?.numberOfKingBeds}
                      helperText={
                        errors.bedrooms?.[idx]?.numberOfKingBeds?.message ??
                        null
                      }
                    />
                  )}
                />
                <Button
                  type="button"
                  color="blackShades"
                  variant="outlined"
                  isDisabled={
                    !enableEditForm || bedroomsFieldArray.fields.length <= 0
                  }
                  size="medium"
                  fullWidth={true}
                  onClick={() => {
                    bedroomsFieldArray.remove(idx);
                  }}
                  qaAttribute="remove-bedroom-button"
                >
                  Remove bedroom field
                </Button>
              </Box>
            ))}
            <Button
              type="button"
              color="blackShades"
              variant="outlined"
              isDisabled={!enableEditForm}
              size="medium"
              fullWidth={true}
              onClick={() => {
                bedroomsFieldArray.append({
                  ...initialBedroomSchema,
                  name: `Bedroom ${bedroomsFieldArray.fields.length + 1}`,
                });
              }}
              qaAttribute="add-bedroom-button"
            >
              Add bedroom field
            </Button>
            <Grid container spacing={1} pb={1}>
              {!canEdit ? (
                <Grid item xs={6}>
                  <Button
                    type="button"
                    color="blackShades"
                    variant="outlined"
                    size="large"
                    fullWidth={true}
                    onClick={() => {
                      navigate(
                        `/location/upload/thumbnail?location-id=${locationId}`,
                      );
                    }}
                    qaAttribute="lodging-skip-button"
                  >
                    Skip this step
                  </Button>
                </Grid>
              ) : (
                <Grid item xs={6}>
                  <Button
                    type="button"
                    color="blackShades"
                    variant="outlined"
                    size="large"
                    fullWidth={true}
                    onClick={() => {
                      navigate("/");
                    }}
                    qaAttribute="location-cancel-button"
                  >
                    Cancel
                  </Button>
                </Grid>
              )}

              {enableEditForm && (
                <Grid item xs={6}>
                  <Button
                    fullWidth={true}
                    type={"submit"}
                    size="large"
                    variant="contained"
                    qaAttribute="lodging-continue-button"
                  >
                    Save and continue
                  </Button>
                </Grid>
              )}
              {lodgingId && (
                <Grid item xs={6}>
                  <Button
                    type="button"
                    color="blackShades"
                    variant="outlined"
                    size="large"
                    fullWidth={true}
                    qaAttribute="lodging-delete-button"
                    onClick={onDelete(
                      () => onDeleteSuccess && onDeleteSuccess("deleted"),
                    )}
                  >
                    Delete
                  </Button>
                </Grid>
              )}
            </Grid>
          </form>
        </Container>
      )}
    </>
  );
};

export default Lodging;
