import { useMutation, useQueryClient } from "@tanstack/react-query";
import { locationApi } from "./locationService";
import AsyncStatuses from "common/types/enums/asyncStatuses";
import { LocationsAPIApiUpdateLocationRequest } from "api/v1.0";
import { locationKeys } from "./locationQueryKeyFactory";

function useMutateLocation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (
      updateLocationApiRequest: LocationsAPIApiUpdateLocationRequest,
    ) => locationApi.updateLocation(updateLocationApiRequest),
    onSuccess: async (_data, _variables) => {
      await queryClient.invalidateQueries({ queryKey: locationKeys.all });
    },
  });
}

export const useUpdateLocation = () => {
  const mutateLocation = useMutateLocation();

  return {
    ...mutateLocation,
    updateStatus: mutateLocation.status as AsyncStatuses,
  };
};
