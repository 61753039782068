import {
  CreateLocationRequest,
  LocationAddressResponse,
  LocationContactResponse,
  LocationResponse,
  UpdateLocationRequest,
  UpsertLocationContactRequest,
} from "api/v1.0";
import {
  ILocationAddressRequestFormValues,
  ILocationFormValues,
  IUpsertLocationContactRequest,
  locationContactInitialValuesTemplate,
  locationFormInitialValuesTemplate,
} from "./locationTypes";
import dayjs from "dayjs";
import { removeBlankAttributes } from "common/utils/tsUtils";

export const locationContactFromResponse = ({
  id,
  ...restLocationContact
}: LocationContactResponse): IUpsertLocationContactRequest => {
  return {
    ...locationContactInitialValuesTemplate,
    ...removeBlankAttributes(restLocationContact),
    dateOfBirth: dayjs(restLocationContact.dateOfBirth).toDate(),
  };
};

export const locationContactToRequest = (
  locationContact: IUpsertLocationContactRequest,
): UpsertLocationContactRequest => {
  return {
    ...locationContact,
    dateOfBirth: dayjs(locationContact.dateOfBirth).toISOString(),
  };
};

export const locationAddressFromResponse = (
  locationAddress: LocationAddressResponse,
): ILocationAddressRequestFormValues => {
  return {
    ...locationFormInitialValuesTemplate.address,
    ...removeBlankAttributes(locationAddress),
  };
};

export const locationFromResponse = (
  location?: LocationResponse,
): ILocationFormValues => {
  const {
    address = {},
    directions,
    id,
    slug,
    locationContacts = [],
    thumbnailImageUrl,
    ...restLocation
  } = location ?? {};

  return {
    ...locationFormInitialValuesTemplate,
    ...removeBlankAttributes(restLocation),
    address: locationAddressFromResponse(address),
    locationContacts: locationContacts.map((lc) =>
      locationContactFromResponse(lc),
    ),
  };
};

export const locationToCreateRequest = ({
  locationContacts,
  address,
  ...restLocation
}: ILocationFormValues): CreateLocationRequest => {
  return {
    ...removeBlankAttributes(restLocation),
    address: removeBlankAttributes(address),
    locationContacts: locationContacts.map((lc) =>
      locationContactToRequest(lc),
    ),
  };
};

export const locationToUpdateRequest = ({
  locationContacts,
  taxRate,
  address,
  ...restLocation
}: ILocationFormValues): UpdateLocationRequest => {
  return {
    ...removeBlankAttributes(restLocation),
    address: removeBlankAttributes(address),
    locationContacts: locationContacts.map((lc) =>
      locationContactToRequest(lc),
    ),
  };
};
