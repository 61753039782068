/* tslint:disable */
/* eslint-disable */
/**
 * Application Backend API
 * Bird Dog 
 *
 * The version of the OpenAPI document: version
 * Contact: mail
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ApiErrorResponse } from '../models';
// @ts-ignore
import { BlockedDateRequest } from '../models';
// @ts-ignore
import { BlockedDateResponse } from '../models';
// @ts-ignore
import { CreateImageRequest } from '../models';
// @ts-ignore
import { ExperienceResponse } from '../models';
// @ts-ignore
import { ExperienceTypeResponse } from '../models';
// @ts-ignore
import { ImageResponse } from '../models';
// @ts-ignore
import { PageExperienceResponse } from '../models';
// @ts-ignore
import { UpsertExperienceRequest } from '../models';
/**
 * ExperiencesAPIApi - axios parameter creator
 * @export
 */
export const ExperiencesAPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Block or unblock calendar dates for a given experience
         * @param {string} id 
         * @param {BlockedDateRequest} blockedDateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blockOrUnblockLocationDates1: async (id: string, blockedDateRequest: BlockedDateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('blockOrUnblockLocationDates1', 'id', id)
            // verify required parameter 'blockedDateRequest' is not null or undefined
            assertParamExists('blockOrUnblockLocationDates1', 'blockedDateRequest', blockedDateRequest)
            const localVarPath = `/v1/experiences/{id}/blocked-dates`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(blockedDateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new experience
         * @param {UpsertExperienceRequest} upsertExperienceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExperience: async (upsertExperienceRequest: UpsertExperienceRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'upsertExperienceRequest' is not null or undefined
            assertParamExists('createExperience', 'upsertExperienceRequest', upsertExperienceRequest)
            const localVarPath = `/v1/experiences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertExperienceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create images for a location.
         * @param {string} id 
         * @param {Array<CreateImageRequest>} createImageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createImages1: async (id: string, createImageRequest: Array<CreateImageRequest>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createImages1', 'id', id)
            // verify required parameter 'createImageRequest' is not null or undefined
            assertParamExists('createImages1', 'createImageRequest', createImageRequest)
            const localVarPath = `/v1/experiences/{id}/images`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createImageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an existing experience by its ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExperience: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteExperience', 'id', id)
            const localVarPath = `/v1/experiences/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an existing image by the experienceId and imageId
         * @param {string} id 
         * @param {string} imageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImage1: async (id: string, imageId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteImage1', 'id', id)
            // verify required parameter 'imageId' is not null or undefined
            assertParamExists('deleteImage1', 'imageId', imageId)
            const localVarPath = `/v1/experiences/{id}/images/{imageId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"imageId"}}`, encodeURIComponent(String(imageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update embeddings for every experience
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        embedExperiences: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/experiences/embed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all experience types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllExperienceTypes: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/experiences/experience-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all the experiences
         * @param {boolean} includeUnpublished 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [query] 
         * @param {string} [name] 
         * @param {string} [city] 
         * @param {string} [state] 
         * @param {string} [locationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllExperiences: async (includeUnpublished: boolean, page?: number, pageSize?: number, query?: string, name?: string, city?: string, state?: string, locationId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'includeUnpublished' is not null or undefined
            assertParamExists('findAllExperiences', 'includeUnpublished', includeUnpublished)
            const localVarPath = `/v1/experiences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (city !== undefined) {
                localVarQueryParameter['city'] = city;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (locationId !== undefined) {
                localVarQueryParameter['locationId'] = locationId;
            }

            if (includeUnpublished !== undefined) {
                localVarQueryParameter['includeUnpublished'] = includeUnpublished;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all the images for a experience
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllImages1: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findAllImages1', 'id', id)
            const localVarPath = `/v1/experiences/{id}/images`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all the blocked dates for a given experience
         * @param {string} id 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findExperienceBlockedDates: async (id: string, startDate: string, endDate: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findExperienceBlockedDates', 'id', id)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('findExperienceBlockedDates', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('findExperienceBlockedDates', 'endDate', endDate)
            const localVarPath = `/v1/experiences/{id}/blocked-dates`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substring(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substring(0,10) :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific experience by its ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findExperienceById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findExperienceById', 'id', id)
            const localVarPath = `/v1/experiences/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all the booked and blocked dates for a experience. The list of dates returned can be out of order
         * @param {string} id 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUnavailability: async (id: string, startDate: string, endDate: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findUnavailability', 'id', id)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('findUnavailability', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('findUnavailability', 'endDate', endDate)
            const localVarPath = `/v1/experiences/{id}/unavailability`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substring(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substring(0,10) :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an existing experience
         * @param {string} id 
         * @param {UpsertExperienceRequest} upsertExperienceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExperience: async (id: string, upsertExperienceRequest: UpsertExperienceRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateExperience', 'id', id)
            // verify required parameter 'upsertExperienceRequest' is not null or undefined
            assertParamExists('updateExperience', 'upsertExperienceRequest', upsertExperienceRequest)
            const localVarPath = `/v1/experiences/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer-key required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertExperienceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExperiencesAPIApi - functional programming interface
 * @export
 */
export const ExperiencesAPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExperiencesAPIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Block or unblock calendar dates for a given experience
         * @param {string} id 
         * @param {BlockedDateRequest} blockedDateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async blockOrUnblockLocationDates1(id: string, blockedDateRequest: BlockedDateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BlockedDateResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.blockOrUnblockLocationDates1(id, blockedDateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExperiencesAPIApi.blockOrUnblockLocationDates1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create a new experience
         * @param {UpsertExperienceRequest} upsertExperienceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createExperience(upsertExperienceRequest: UpsertExperienceRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExperienceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createExperience(upsertExperienceRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExperiencesAPIApi.createExperience']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create images for a location.
         * @param {string} id 
         * @param {Array<CreateImageRequest>} createImageRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createImages1(id: string, createImageRequest: Array<CreateImageRequest>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImageResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createImages1(id, createImageRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExperiencesAPIApi.createImages1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete an existing experience by its ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteExperience(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteExperience(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExperiencesAPIApi.deleteExperience']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete an existing image by the experienceId and imageId
         * @param {string} id 
         * @param {string} imageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteImage1(id: string, imageId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteImage1(id, imageId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExperiencesAPIApi.deleteImage1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update embeddings for every experience
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async embedExperiences(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.embedExperiences(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExperiencesAPIApi.embedExperiences']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all experience types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllExperienceTypes(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExperienceTypeResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllExperienceTypes(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExperiencesAPIApi.findAllExperienceTypes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Find all the experiences
         * @param {boolean} includeUnpublished 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [query] 
         * @param {string} [name] 
         * @param {string} [city] 
         * @param {string} [state] 
         * @param {string} [locationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllExperiences(includeUnpublished: boolean, page?: number, pageSize?: number, query?: string, name?: string, city?: string, state?: string, locationId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageExperienceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllExperiences(includeUnpublished, page, pageSize, query, name, city, state, locationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExperiencesAPIApi.findAllExperiences']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all the images for a experience
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllImages1(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImageResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllImages1(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExperiencesAPIApi.findAllImages1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all the blocked dates for a given experience
         * @param {string} id 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findExperienceBlockedDates(id: string, startDate: string, endDate: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BlockedDateResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findExperienceBlockedDates(id, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExperiencesAPIApi.findExperienceBlockedDates']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a specific experience by its ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findExperienceById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExperienceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findExperienceById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExperiencesAPIApi.findExperienceById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get all the booked and blocked dates for a experience. The list of dates returned can be out of order
         * @param {string} id 
         * @param {string} startDate 
         * @param {string} endDate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findUnavailability(id: string, startDate: string, endDate: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Set<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findUnavailability(id, startDate, endDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExperiencesAPIApi.findUnavailability']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update an existing experience
         * @param {string} id 
         * @param {UpsertExperienceRequest} upsertExperienceRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateExperience(id: string, upsertExperienceRequest: UpsertExperienceRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExperienceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateExperience(id, upsertExperienceRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ExperiencesAPIApi.updateExperience']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ExperiencesAPIApi - factory interface
 * @export
 */
export const ExperiencesAPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExperiencesAPIApiFp(configuration)
    return {
        /**
         * 
         * @summary Block or unblock calendar dates for a given experience
         * @param {ExperiencesAPIApiBlockOrUnblockLocationDates1Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        blockOrUnblockLocationDates1(requestParameters: ExperiencesAPIApiBlockOrUnblockLocationDates1Request, options?: RawAxiosRequestConfig): AxiosPromise<Array<BlockedDateResponse>> {
            return localVarFp.blockOrUnblockLocationDates1(requestParameters.id, requestParameters.blockedDateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new experience
         * @param {ExperiencesAPIApiCreateExperienceRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExperience(requestParameters: ExperiencesAPIApiCreateExperienceRequest, options?: RawAxiosRequestConfig): AxiosPromise<ExperienceResponse> {
            return localVarFp.createExperience(requestParameters.upsertExperienceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create images for a location.
         * @param {ExperiencesAPIApiCreateImages1Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createImages1(requestParameters: ExperiencesAPIApiCreateImages1Request, options?: RawAxiosRequestConfig): AxiosPromise<Array<ImageResponse>> {
            return localVarFp.createImages1(requestParameters.id, requestParameters.createImageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an existing experience by its ID
         * @param {ExperiencesAPIApiDeleteExperienceRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExperience(requestParameters: ExperiencesAPIApiDeleteExperienceRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteExperience(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an existing image by the experienceId and imageId
         * @param {ExperiencesAPIApiDeleteImage1Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImage1(requestParameters: ExperiencesAPIApiDeleteImage1Request, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteImage1(requestParameters.id, requestParameters.imageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update embeddings for every experience
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        embedExperiences(options?: RawAxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.embedExperiences(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all experience types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllExperienceTypes(options?: RawAxiosRequestConfig): AxiosPromise<Array<ExperienceTypeResponse>> {
            return localVarFp.findAllExperienceTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all the experiences
         * @param {ExperiencesAPIApiFindAllExperiencesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllExperiences(requestParameters: ExperiencesAPIApiFindAllExperiencesRequest, options?: RawAxiosRequestConfig): AxiosPromise<PageExperienceResponse> {
            return localVarFp.findAllExperiences(requestParameters.includeUnpublished, requestParameters.page, requestParameters.pageSize, requestParameters.query, requestParameters.name, requestParameters.city, requestParameters.state, requestParameters.locationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all the images for a experience
         * @param {ExperiencesAPIApiFindAllImages1Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllImages1(requestParameters: ExperiencesAPIApiFindAllImages1Request, options?: RawAxiosRequestConfig): AxiosPromise<Array<ImageResponse>> {
            return localVarFp.findAllImages1(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all the blocked dates for a given experience
         * @param {ExperiencesAPIApiFindExperienceBlockedDatesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findExperienceBlockedDates(requestParameters: ExperiencesAPIApiFindExperienceBlockedDatesRequest, options?: RawAxiosRequestConfig): AxiosPromise<Array<BlockedDateResponse>> {
            return localVarFp.findExperienceBlockedDates(requestParameters.id, requestParameters.startDate, requestParameters.endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a specific experience by its ID
         * @param {ExperiencesAPIApiFindExperienceByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findExperienceById(requestParameters: ExperiencesAPIApiFindExperienceByIdRequest, options?: RawAxiosRequestConfig): AxiosPromise<ExperienceResponse> {
            return localVarFp.findExperienceById(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all the booked and blocked dates for a experience. The list of dates returned can be out of order
         * @param {ExperiencesAPIApiFindUnavailabilityRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUnavailability(requestParameters: ExperiencesAPIApiFindUnavailabilityRequest, options?: RawAxiosRequestConfig): AxiosPromise<Set<string>> {
            return localVarFp.findUnavailability(requestParameters.id, requestParameters.startDate, requestParameters.endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an existing experience
         * @param {ExperiencesAPIApiUpdateExperienceRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateExperience(requestParameters: ExperiencesAPIApiUpdateExperienceRequest, options?: RawAxiosRequestConfig): AxiosPromise<ExperienceResponse> {
            return localVarFp.updateExperience(requestParameters.id, requestParameters.upsertExperienceRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for blockOrUnblockLocationDates1 operation in ExperiencesAPIApi.
 * @export
 * @interface ExperiencesAPIApiBlockOrUnblockLocationDates1Request
 */
export interface ExperiencesAPIApiBlockOrUnblockLocationDates1Request {
    /**
     * 
     * @type {string}
     * @memberof ExperiencesAPIApiBlockOrUnblockLocationDates1
     */
    readonly id: string

    /**
     * 
     * @type {BlockedDateRequest}
     * @memberof ExperiencesAPIApiBlockOrUnblockLocationDates1
     */
    readonly blockedDateRequest: BlockedDateRequest
}

/**
 * Request parameters for createExperience operation in ExperiencesAPIApi.
 * @export
 * @interface ExperiencesAPIApiCreateExperienceRequest
 */
export interface ExperiencesAPIApiCreateExperienceRequest {
    /**
     * 
     * @type {UpsertExperienceRequest}
     * @memberof ExperiencesAPIApiCreateExperience
     */
    readonly upsertExperienceRequest: UpsertExperienceRequest
}

/**
 * Request parameters for createImages1 operation in ExperiencesAPIApi.
 * @export
 * @interface ExperiencesAPIApiCreateImages1Request
 */
export interface ExperiencesAPIApiCreateImages1Request {
    /**
     * 
     * @type {string}
     * @memberof ExperiencesAPIApiCreateImages1
     */
    readonly id: string

    /**
     * 
     * @type {Array<CreateImageRequest>}
     * @memberof ExperiencesAPIApiCreateImages1
     */
    readonly createImageRequest: Array<CreateImageRequest>
}

/**
 * Request parameters for deleteExperience operation in ExperiencesAPIApi.
 * @export
 * @interface ExperiencesAPIApiDeleteExperienceRequest
 */
export interface ExperiencesAPIApiDeleteExperienceRequest {
    /**
     * 
     * @type {string}
     * @memberof ExperiencesAPIApiDeleteExperience
     */
    readonly id: string
}

/**
 * Request parameters for deleteImage1 operation in ExperiencesAPIApi.
 * @export
 * @interface ExperiencesAPIApiDeleteImage1Request
 */
export interface ExperiencesAPIApiDeleteImage1Request {
    /**
     * 
     * @type {string}
     * @memberof ExperiencesAPIApiDeleteImage1
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof ExperiencesAPIApiDeleteImage1
     */
    readonly imageId: string
}

/**
 * Request parameters for findAllExperiences operation in ExperiencesAPIApi.
 * @export
 * @interface ExperiencesAPIApiFindAllExperiencesRequest
 */
export interface ExperiencesAPIApiFindAllExperiencesRequest {
    /**
     * 
     * @type {boolean}
     * @memberof ExperiencesAPIApiFindAllExperiences
     */
    readonly includeUnpublished: boolean

    /**
     * 
     * @type {number}
     * @memberof ExperiencesAPIApiFindAllExperiences
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof ExperiencesAPIApiFindAllExperiences
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof ExperiencesAPIApiFindAllExperiences
     */
    readonly query?: string

    /**
     * 
     * @type {string}
     * @memberof ExperiencesAPIApiFindAllExperiences
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof ExperiencesAPIApiFindAllExperiences
     */
    readonly city?: string

    /**
     * 
     * @type {string}
     * @memberof ExperiencesAPIApiFindAllExperiences
     */
    readonly state?: string

    /**
     * 
     * @type {string}
     * @memberof ExperiencesAPIApiFindAllExperiences
     */
    readonly locationId?: string
}

/**
 * Request parameters for findAllImages1 operation in ExperiencesAPIApi.
 * @export
 * @interface ExperiencesAPIApiFindAllImages1Request
 */
export interface ExperiencesAPIApiFindAllImages1Request {
    /**
     * 
     * @type {string}
     * @memberof ExperiencesAPIApiFindAllImages1
     */
    readonly id: string
}

/**
 * Request parameters for findExperienceBlockedDates operation in ExperiencesAPIApi.
 * @export
 * @interface ExperiencesAPIApiFindExperienceBlockedDatesRequest
 */
export interface ExperiencesAPIApiFindExperienceBlockedDatesRequest {
    /**
     * 
     * @type {string}
     * @memberof ExperiencesAPIApiFindExperienceBlockedDates
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof ExperiencesAPIApiFindExperienceBlockedDates
     */
    readonly startDate: string

    /**
     * 
     * @type {string}
     * @memberof ExperiencesAPIApiFindExperienceBlockedDates
     */
    readonly endDate: string
}

/**
 * Request parameters for findExperienceById operation in ExperiencesAPIApi.
 * @export
 * @interface ExperiencesAPIApiFindExperienceByIdRequest
 */
export interface ExperiencesAPIApiFindExperienceByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof ExperiencesAPIApiFindExperienceById
     */
    readonly id: string
}

/**
 * Request parameters for findUnavailability operation in ExperiencesAPIApi.
 * @export
 * @interface ExperiencesAPIApiFindUnavailabilityRequest
 */
export interface ExperiencesAPIApiFindUnavailabilityRequest {
    /**
     * 
     * @type {string}
     * @memberof ExperiencesAPIApiFindUnavailability
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof ExperiencesAPIApiFindUnavailability
     */
    readonly startDate: string

    /**
     * 
     * @type {string}
     * @memberof ExperiencesAPIApiFindUnavailability
     */
    readonly endDate: string
}

/**
 * Request parameters for updateExperience operation in ExperiencesAPIApi.
 * @export
 * @interface ExperiencesAPIApiUpdateExperienceRequest
 */
export interface ExperiencesAPIApiUpdateExperienceRequest {
    /**
     * 
     * @type {string}
     * @memberof ExperiencesAPIApiUpdateExperience
     */
    readonly id: string

    /**
     * 
     * @type {UpsertExperienceRequest}
     * @memberof ExperiencesAPIApiUpdateExperience
     */
    readonly upsertExperienceRequest: UpsertExperienceRequest
}

/**
 * ExperiencesAPIApi - object-oriented interface
 * @export
 * @class ExperiencesAPIApi
 * @extends {BaseAPI}
 */
export class ExperiencesAPIApi extends BaseAPI {
    /**
     * 
     * @summary Block or unblock calendar dates for a given experience
     * @param {ExperiencesAPIApiBlockOrUnblockLocationDates1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperiencesAPIApi
     */
    public blockOrUnblockLocationDates1(requestParameters: ExperiencesAPIApiBlockOrUnblockLocationDates1Request, options?: RawAxiosRequestConfig) {
        return ExperiencesAPIApiFp(this.configuration).blockOrUnblockLocationDates1(requestParameters.id, requestParameters.blockedDateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new experience
     * @param {ExperiencesAPIApiCreateExperienceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperiencesAPIApi
     */
    public createExperience(requestParameters: ExperiencesAPIApiCreateExperienceRequest, options?: RawAxiosRequestConfig) {
        return ExperiencesAPIApiFp(this.configuration).createExperience(requestParameters.upsertExperienceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create images for a location.
     * @param {ExperiencesAPIApiCreateImages1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperiencesAPIApi
     */
    public createImages1(requestParameters: ExperiencesAPIApiCreateImages1Request, options?: RawAxiosRequestConfig) {
        return ExperiencesAPIApiFp(this.configuration).createImages1(requestParameters.id, requestParameters.createImageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an existing experience by its ID
     * @param {ExperiencesAPIApiDeleteExperienceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperiencesAPIApi
     */
    public deleteExperience(requestParameters: ExperiencesAPIApiDeleteExperienceRequest, options?: RawAxiosRequestConfig) {
        return ExperiencesAPIApiFp(this.configuration).deleteExperience(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an existing image by the experienceId and imageId
     * @param {ExperiencesAPIApiDeleteImage1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperiencesAPIApi
     */
    public deleteImage1(requestParameters: ExperiencesAPIApiDeleteImage1Request, options?: RawAxiosRequestConfig) {
        return ExperiencesAPIApiFp(this.configuration).deleteImage1(requestParameters.id, requestParameters.imageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update embeddings for every experience
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperiencesAPIApi
     */
    public embedExperiences(options?: RawAxiosRequestConfig) {
        return ExperiencesAPIApiFp(this.configuration).embedExperiences(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all experience types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperiencesAPIApi
     */
    public findAllExperienceTypes(options?: RawAxiosRequestConfig) {
        return ExperiencesAPIApiFp(this.configuration).findAllExperienceTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all the experiences
     * @param {ExperiencesAPIApiFindAllExperiencesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperiencesAPIApi
     */
    public findAllExperiences(requestParameters: ExperiencesAPIApiFindAllExperiencesRequest, options?: RawAxiosRequestConfig) {
        return ExperiencesAPIApiFp(this.configuration).findAllExperiences(requestParameters.includeUnpublished, requestParameters.page, requestParameters.pageSize, requestParameters.query, requestParameters.name, requestParameters.city, requestParameters.state, requestParameters.locationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all the images for a experience
     * @param {ExperiencesAPIApiFindAllImages1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperiencesAPIApi
     */
    public findAllImages1(requestParameters: ExperiencesAPIApiFindAllImages1Request, options?: RawAxiosRequestConfig) {
        return ExperiencesAPIApiFp(this.configuration).findAllImages1(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all the blocked dates for a given experience
     * @param {ExperiencesAPIApiFindExperienceBlockedDatesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperiencesAPIApi
     */
    public findExperienceBlockedDates(requestParameters: ExperiencesAPIApiFindExperienceBlockedDatesRequest, options?: RawAxiosRequestConfig) {
        return ExperiencesAPIApiFp(this.configuration).findExperienceBlockedDates(requestParameters.id, requestParameters.startDate, requestParameters.endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a specific experience by its ID
     * @param {ExperiencesAPIApiFindExperienceByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperiencesAPIApi
     */
    public findExperienceById(requestParameters: ExperiencesAPIApiFindExperienceByIdRequest, options?: RawAxiosRequestConfig) {
        return ExperiencesAPIApiFp(this.configuration).findExperienceById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all the booked and blocked dates for a experience. The list of dates returned can be out of order
     * @param {ExperiencesAPIApiFindUnavailabilityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperiencesAPIApi
     */
    public findUnavailability(requestParameters: ExperiencesAPIApiFindUnavailabilityRequest, options?: RawAxiosRequestConfig) {
        return ExperiencesAPIApiFp(this.configuration).findUnavailability(requestParameters.id, requestParameters.startDate, requestParameters.endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an existing experience
     * @param {ExperiencesAPIApiUpdateExperienceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperiencesAPIApi
     */
    public updateExperience(requestParameters: ExperiencesAPIApiUpdateExperienceRequest, options?: RawAxiosRequestConfig) {
        return ExperiencesAPIApiFp(this.configuration).updateExperience(requestParameters.id, requestParameters.upsertExperienceRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

