import { useMutation, useQueryClient } from "@tanstack/react-query";
import AsyncStatuses from "common/types/enums/asyncStatuses";
import { LocationsAPIApiCreateLocationContactImageRequest } from "api/v1.0";
import { locationApi } from "./locationService";
import { locationKeys } from "./locationQueryKeyFactory";

function useMutateLocationContactImages() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (
      createImageApiRequest: LocationsAPIApiCreateLocationContactImageRequest,
    ) => locationApi.createLocationContactImage(createImageApiRequest),
    onSuccess: async (_data, arg) => {
      await queryClient.invalidateQueries({
        queryKey: locationKeys.detail(arg.id),
      });
    },
  });
}

export const useCreateLocationContactImages = () => {
  const mutateLocationContactImages = useMutateLocationContactImages();

  return {
    ...mutateLocationContactImages,
    createStatus: mutateLocationContactImages.status as AsyncStatuses,
  };
};
