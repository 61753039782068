import React from "react";
import H2 from "components/atoms/H2";
import Container from "components/atoms/Container";

const Forbidden = () => {
  return (
    <Container>
      <H2>Forbidden!</H2>
      <p>You must be a Birddog Admin in order to view this page</p>
    </Container>
  );
};

export default Forbidden;
