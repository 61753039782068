import {
  CreateExperienceImagesRequest,
  CreateExperienceImagesResponse,
  ICreateExperienceBlockedDatesRequest,
  ICreateExperienceRequest,
  ICreateExperienceResponse,
  IEditExperienceRequest,
  IExperienceType,
  IGetOneExperienceResponse,
  ISearchExperiencesResponse,
} from "services/types/experience";
import HttpClient from ".";
import { IBlockedDatesResponse } from "./types/generic";

const baseURL: any = process.env.REACT_APP_BASE_URL;
const httpClient = HttpClient(baseURL);

async function getOneExperienceAPI(id: string) {
  try {
    const data: IGetOneExperienceResponse = await httpClient.getMethod(
      `/v1/experiences/${id}`,
    );
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

async function listExperiencesAPI(page: number, size: number) {
  try {
    const data = await httpClient.getMethod(
      `/v1/experiences?page=${page}&pageSize=${size}`,
    );
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

async function searchExperiencesAPI(
  page: number,
  size: number,
  includeUnpublished: boolean,
  name?: string,
  city?: string,
  state?: string,
  locationId?: string,
) {
  try {
    const data: ISearchExperiencesResponse = await httpClient.getMethod(
      `/v1/experiences?page=${page}&pageSize=${size}&name=${name}&city=${city}&state=${state}&locationId=${locationId}&includeUnpublished=${includeUnpublished}`,
    );
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}
async function createExperienceAPI(body: ICreateExperienceRequest) {
  try {
    const data: ICreateExperienceResponse = await httpClient.postMethod(
      "/v1/experiences",
      body,
    );
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

async function editExperienceAPI(id: string, body: IEditExperienceRequest) {
  try {
    const data: ICreateExperienceResponse = await httpClient.putMethod(
      `/v1/experiences/${id}`,
      body,
    );
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

async function deleteExperienceAPI(id: string | null) {
  try {
    await httpClient.deleteMethod(`/v1/experiences/${id}`);
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

async function createExperienceImagesAPI(
  experienceId: string,
  body: CreateExperienceImagesRequest[],
) {
  try {
    const data: CreateExperienceImagesResponse[] = await httpClient.postMethod(
      `/v1/experiences/${experienceId}/images`,
      body,
    );
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

async function getExperienceBlockedDates(
  experienceId: string,
  startDate: string,
  endDate: string,
) {
  try {
    const data: IBlockedDatesResponse[] = await httpClient.getMethod(
      `/v1/experiences/${experienceId}/blocked-dates?startDate=${startDate}&endDate=${endDate}`,
    );
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

async function createExperienceBlockedDates(
  experienceId: string,
  body: ICreateExperienceBlockedDatesRequest,
) {
  try {
    const data: IBlockedDatesResponse[] = await httpClient.putMethod(
      `/v1/experiences/${experienceId}/blocked-dates`,
      body,
    );
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

async function getUnavailabilityDates(
  experienceId: string,
  startDate: string,
  endDate: string,
) {
  try {
    const data: string[] = await httpClient.getMethod(
      `/v1/experiences/${experienceId}/unavailability?startDate=${startDate}&endDate=${endDate}`,
    );
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

async function getAllExperienceTypesAPI() {
  try {
    const data: IExperienceType[] = await httpClient.getMethod(
      "/v1/experiences/experience-types",
    );
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

export {
  getOneExperienceAPI,
  listExperiencesAPI,
  createExperienceAPI,
  editExperienceAPI,
  deleteExperienceAPI,
  searchExperiencesAPI,
  createExperienceImagesAPI,
  getAllExperienceTypesAPI,
  getExperienceBlockedDates,
  createExperienceBlockedDates,
  getUnavailabilityDates,
};
