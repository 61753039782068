import { useEffect, useState } from "react";
import { lodgingSchema } from "./loggingValidation";
import { useFieldArray, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import { initialLodgingSchema, LodgingSchema } from "./lodgingTypes";
import { useGetLodgingById } from "./useGetLodgingById";
import { useUpdateLodging } from "./useUpdateLodging";
import { useDeleteLodging } from "./useDeleteLodging";
import { useCreateLodging } from "./useCreateLodging";
import {
  lodgingResponseToSchema,
  lodgingSchemaToRequest,
} from "./lodgingMapper";
import { LodgingResponse } from "api/v1.0";

export const useLodgingForm = (locationId?: string, lodgingId?: string) => {
  const [initialValues, setInitialValues] =
    useState<LodgingSchema>(initialLodgingSchema);
  const { data: lodgingData, ...restGetLodging } = useGetLodgingById(
    lodgingId ?? "",
  );
  const { mutate: createLodging, ...restCreateLodging } = useCreateLodging();
  const { mutate: updateLodging, ...restUpdateLodging } = useUpdateLodging();
  const { mutate: deleteLodging, ...restDeleteLodging } = useDeleteLodging();

  const form = useForm({
    mode: "onChange",
    /* @ts-ignore */
    resolver: yupResolver(lodgingSchema),
    defaultValues: initialValues,
  });
  const bedroomsFieldArray = useFieldArray({
    control: form.control,
    name: "bedrooms",
  });

  useEffect(() => {
    if (lodgingData) {
      setInitialValues(lodgingResponseToSchema(lodgingData));
    }
  }, [lodgingData]);

  useEffect(() => {
    form.reset(initialValues);
  }, [initialValues, form.reset]);

  const onDelete = (onDelete?: () => void) => () => {
    if (lodgingId) {
      deleteLodging(
        { id: lodgingId },
        {
          onSuccess: () => {
            if (onDelete) onDelete();
          },
        },
      );
    }
  };

  const handleCreate = (
    formValues: LodgingSchema,
    locationId: string,
    onCreate?: (lodgingResponse: LodgingResponse) => void,
  ) => {
    createLodging(
      {
        upsertLodgingRequest: lodgingSchemaToRequest(formValues, locationId),
      },
      {
        onSuccess: (data) => {
          if (onCreate) onCreate(data.data);
        },
      },
    );
  };

  const handleUpdate = (
    formValues: LodgingSchema,
    locationId: string,
    lodgingId: string,
    onUpdate?: (lodgingResponse: LodgingResponse) => void,
  ) => {
    updateLodging(
      {
        id: lodgingId,
        upsertLodgingRequest: lodgingSchemaToRequest(formValues, locationId),
      },
      {
        onSuccess: (data) => {
          if (onUpdate) onUpdate(data.data);
        },
      },
    );
  };

  const onSubmit =
    (
      onCreate?: (lodgingResponse: LodgingResponse) => void,
      onUpdate?: (lodgingResponse: LodgingResponse) => void,
    ) =>
    (formValues: LodgingSchema) => {
      if (formValues.description) {
        formValues.description = formValues.description.replace(
          /\\<!--->/g,
          "",
        );
      }
      if (formValues.sleepingArrangementDescription) {
        formValues.sleepingArrangementDescription =
          formValues.sleepingArrangementDescription.replace(/\\<!--->/g, "");
      }

      const isUpdate = locationId && lodgingId;
      const isCreate = !isUpdate && locationId;

      if (isUpdate) {
        handleUpdate(formValues, locationId, lodgingId, onUpdate);
      } else if (isCreate) {
        handleCreate(formValues, locationId, onCreate);
      }
    };

  return {
    isLoading: restGetLodging.isLoading,
    isPending:
      restCreateLodging.isPending ||
      restDeleteLodging.isPending ||
      restUpdateLodging.isPending,
    isError:
      restGetLodging.isError ||
      restCreateLodging.isError ||
      restDeleteLodging.isError ||
      restUpdateLodging.isError,
    error:
      restGetLodging.error ??
      restCreateLodging.error ??
      restDeleteLodging.error ??
      restUpdateLodging.error,
    onDelete,
    onSubmit,
    form,
    bedroomsFieldArray,
    initialValues,
  };
};
