import HttpClient from ".";
import {
  IListPackagesResponse,
  IPackageRequest,
  IPackageResponse,
} from "./types/packages";
import { IBadRequest } from "./types/generic";

const baseURL: any = process.env.REACT_APP_BASE_URL;
const httpClient = HttpClient(baseURL);

async function getOnePackageAPI(packageId: string) {
  try {
    const data: IPackageResponse = await httpClient.getMethod(
      `/v1/packages/${packageId}`,
    );
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

async function createPackageAPI(body: IPackageRequest) {
  try {
    const data: IPackageResponse | IBadRequest = await httpClient.postMethod(
      "/v1/packages",
      body,
    );
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

async function editPackageAPI(packageId: string, body: IPackageRequest) {
  try {
    const data: IPackageResponse = await httpClient.putMethod(
      `/v1/packages/${packageId}`,
      body,
    );
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

async function deletePackageAPI(packageId: string) {
  try {
    await httpClient.deleteMethod(`/v1/packages/${packageId}`);
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

async function listPackagesAPI(
  page: number,
  size: number,
  experienceId: string,
  includeUnpublished: boolean,
) {
  try {
    const data: IListPackagesResponse = await httpClient.getMethod(
      `/v1/packages?page=${page}&pageSize=${size}&experienceId=${experienceId}&includeUnpublished=${includeUnpublished}`,
    );
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

export {
  getOnePackageAPI,
  createPackageAPI,
  editPackageAPI,
  deletePackageAPI,
  listPackagesAPI,
};
