import React, { createContext, useEffect, useState } from "react";
import { firebaseAuth } from "services/firebase";
import {
  User,
  UserCredential,
  onIdTokenChanged,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";

interface AuthContextValue {
  isLoggedIn: boolean;
  isAdmin: boolean;
  userInfo: User;
  login: (
    username: string,
    password: string,
    returnUrl?: string,
  ) => Promise<User>;
  logout: () => Promise<void>;
}

interface AuthContextProps {
  children: React.ReactNode;
}

export const authContext = createContext({} as AuthContextValue);

export function AuthContextProvider({ children }: AuthContextProps) {
  const [isLoadingAuth, setIsLoadingAuth] = useState<boolean>(true);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<User>({} as User);

  const navigate = useNavigate();

  const login = async (
    username: string,
    password: string,
    returnUrl?: string,
  ) => {
    const userCredential: UserCredential = await signInWithEmailAndPassword(
      firebaseAuth,
      username,
      password,
    );
    const idTokenResult = await userCredential.user.getIdTokenResult();
    if ((idTokenResult.claims.ADMIN as boolean)) {
      localStorage.setItem('idToken', idTokenResult.token)
    }
    const redirectTo = returnUrl || "/";
    navigate(redirectTo);
    return userCredential.user;
  };

  const logout = async () => {
    localStorage.removeItem('idToken')
    await firebaseAuth.signOut();
  };

  useEffect(() => {
    onIdTokenChanged(firebaseAuth, (user) => {
      if (user) {
        user.getIdTokenResult().then((idTokenResult) => {
          const isAdmin = (idTokenResult.claims.ADMIN as boolean) || false;
          setIsLoggedIn(true);
          setIsAdmin(isAdmin);
          setUserInfo(user);
          localStorage.setItem('idToken', idTokenResult.token)
        });
      } else {
        setIsLoggedIn(false);
      }
      setIsLoadingAuth(false);
    });
  }, []);

  return (
    <authContext.Provider
      value={{ isLoggedIn, isAdmin, userInfo, login, logout }}
    >
      {!isLoadingAuth && children}
    </authContext.Provider>
  );
}
