import React from "react";
import { Typography } from "@mui/material";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import {
  StyledIcon,
  StyledLocation,
  StyledSearchCard,
  StyledTitle,
} from "./SearchCard.style";

type SearchProps = {
  title?: string;
  type: string;
  location?: string;
  onClick?: () => void;
  showLocation: boolean | undefined;
};

export const SearchCard: React.FC<SearchProps> = ({
  title,
  type,
  location,
  onClick,
  showLocation = true,
}): React.ReactElement => {
  return (
    <StyledSearchCard onClick={onClick}>
      <StyledTitle>{title}</StyledTitle>
      <Typography>{type}</Typography>
      {showLocation && (
        <StyledLocation>
          <StyledIcon>
            <FmdGoodIcon />
          </StyledIcon>
          {location}
        </StyledLocation>
      )}
    </StyledSearchCard>
  );
};

export default SearchCard;
