import { useMutation, useQueryClient } from "@tanstack/react-query";
import AsyncStatuses from "common/types/enums/asyncStatuses";
import { LocationsAPIApiCreateImagesRequest } from "api/v1.0";
import { locationApi } from "./locationService";
import { locationKeys } from "./locationQueryKeyFactory";

function useMutateLocationImages() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (createImagesApiRequest: LocationsAPIApiCreateImagesRequest) =>
      locationApi.createImages(createImagesApiRequest),
    onSuccess: async (_data, arg) => {
      await queryClient.invalidateQueries({
        queryKey: locationKeys.detailResource(arg.id, "images"),
      });
    },
  });
}

export const useCreateLocationImages = () => {
  const mutateLocationImages = useMutateLocationImages();

  return {
    ...mutateLocationImages,
    createStatus: mutateLocationImages.status as AsyncStatuses,
  };
};
