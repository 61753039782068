import React, { useEffect, useMemo, useState } from "react";
import { Stack, Grid } from "@mui/material";
import { useParams } from "react-router";
import { useUpdateLodgingAmenities } from "services/lodging/useUpdateLodgingAmenities";
import ApiError from "components/atoms/ApiError";
import Container from "components/atoms/Container";
import LoadingOverlay from "components/atoms/LoadingOverlay";
import { StyledTitle } from "components/atoms/StyledTitles";

import { chain, Dictionary, cloneDeep } from "lodash";
import Checkbox from "components/atoms/CheckBox";
import Accordion from "components/molecules/Accordion";
import { AmenityResponse } from "api/v1.0";
import Button from "components/atoms/Button";
import { useNavigate } from "react-router-dom";

type AmenityEntity = AmenityResponse & { isChecked: boolean };

const EditLodgingAmenities = () => {
  const { id } = useParams();
  const {
    allAmenities,
    error,
    isError,
    isLoading,
    initialAmenities,
    isPending,
    onSubmit,
  } = useUpdateLodgingAmenities(id ?? "");

  const [amenityEntities, setAmenityEntities] = useState<Dictionary<
    AmenityEntity[]
  > | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading) {
      const initialIds = initialAmenities.map((amenity) => amenity.amenityId);

      const mappedAmenities = chain(allAmenities)
        .map((amenity) => ({
          ...amenity,
          isChecked: initialIds.includes(amenity.amenityId),
        }))
        .groupBy("amenityId")
        .value();

      setAmenityEntities(mappedAmenities);
    }
  }, [isLoading]);

  const handleChange = (amenity: AmenityResponse) => () =>
    setAmenityEntities((prevEntities) => {
      const newEntities = cloneDeep(prevEntities!);
      const editedAmenity = newEntities[amenity.amenityId!][0];
      editedAmenity.isChecked = !editedAmenity.isChecked;
      return newEntities;
    });

  const onSuccess = () => {
    navigate("/success", {
      state: {
        title: `Lodging Amenities edited successfully!`,
      },
    });
  };

  const nonFeaturedAmenities = useMemo(() => {
    if (!allAmenities) return [];
    return chain(allAmenities)
      .filter((amenity) => !amenity.featured)
      .groupBy("category")
      .value();
  }, [allAmenities]);

  if (isLoading || !amenityEntities || isPending) {
    return <LoadingOverlay />;
  }

  const featuredAmenities = allAmenities!.filter((amenity) => amenity.featured);

  return (
    <Container>
      {isError && <ApiError>{error?.message}</ApiError>}
      <Stack gap={3}>
        <StyledTitle variant="h2">Lodging Amenities</StyledTitle>
        <Grid container rowSpacing={2}>
          {featuredAmenities.map((amenity) => (
            <Grid item key={amenity.amenityId} xs={6}>
              <Checkbox
                label={amenity.name}
                checked={amenityEntities[amenity.amenityId!][0].isChecked}
                onChange={handleChange(amenity)}
              />
            </Grid>
          ))}
        </Grid>

        <Button
          size="large"
          color="blackShades"
          onClick={() =>
            onSubmit(
              Object.values(amenityEntities)
                .flatMap((v) => v)
                .filter((value) => value.isChecked)
                .map((value) => value.amenityId!),
              onSuccess,
            )
          }
          qaAttribute="submit-button"
        >
          Submit
        </Button>

        {Object.entries(nonFeaturedAmenities).map((entry, idx) => (
          <React.Fragment key={entry[0]}>
            <Accordion title={entry[0]}>
              <Grid container rowSpacing={2}>
                {entry[1].map((amenity) => (
                  <Grid item key={amenity.amenityId} xs={6}>
                    <Checkbox
                      label={amenity.name}
                      checked={amenityEntities[amenity.amenityId!][0].isChecked}
                      onChange={handleChange(amenity)}
                    />
                  </Grid>
                ))}
              </Grid>
            </Accordion>
          </React.Fragment>
        ))}
      </Stack>
    </Container>
  );
};

export default EditLodgingAmenities;
