import { styled } from "@mui/material";

export const StyledPreviwText = styled("div")(() => ({
  padding: 4,
  display: "flex",
  width: "100%",
}));

export const StyledPreviewImage = styled("img")(() => ({
  width: "90px",
  height: "80px",
  objectFit: "cover",
  marginRight: "10px",
}));

export const StyledTextPreviewImage = styled("p")(({ theme }) => ({
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  marginTop: 0,
  [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
    width: "calc(100vw - 200px)",
  },
}));
