import React, { useContext, useEffect, useState } from "react";
import Button from "components/atoms/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import Container from "components/atoms/Container";
import H3 from "components/atoms/H3";
import {
  Box,
  Typography,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  Autocomplete,
  Grid,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { TemplateProps } from "common/types/interfaces/templateInterface";
import { StyledInput, StyledBoxDescription } from "./Package.style";
import { LoadingContext } from "context/LoadingContext";
import ButtonEditWithLocker from "components/molecules/ButtonWithLocker";
import { IPackageInitialValues } from "common/types/components/templates";
import { searchExperiencesAPI } from "services/experiencesService";
import { searchLodgingsFromLocationAPI } from "services/lodgingsService";
import { ILodging } from "services/types/lodgings";
import {
  BoldItalicUnderlineToggles,
  ListsToggle,
  MDXEditor,
  MDXEditorMethods,
  listsPlugin,
  toolbarPlugin,
} from "@mdxeditor/editor";
import "@mdxeditor/editor/style.css";
import LoadingOverlay from "components/atoms/LoadingOverlay";
import { ISearchExperiencesResponse } from "services/types/experience";
import { locationApi } from "services/location";

interface PackageProps extends TemplateProps {
  initialValues: IPackageInitialValues;
  loadedPackage: boolean;
  pricesDisabled?: boolean;
  deletePackage?: () => void;
}

const validateDecimal = (value: number, maxDecimals: number) => {
  const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${maxDecimals}})?$`);
  return regex.test(value.toPrecision());
};

const schema = yup.object().shape({
  location: yup.object().required("Choose one location").shape({
    id: yup.string(),
    label: yup.string(),
  }),
  experience: yup
    .object()
    .required("Choose one experience")
    .shape({
      id: yup.string(),
      label: yup.string(),
    })
    .test("validateExperience", (value, { createError }) => {
      if (value.id === "") {
        return createError({ message: "Experience is required" });
      }
      return true;
    }),
  lodging: yup.object().nullable().shape({
    id: yup.string(),
    label: yup.string(),
  }),
  title: yup
    .string()
    .required("Package name is required")
    .min(2, "Package name must be at least 2 characters long"),
  description: yup
    .string()
    .required("Description is required")
    .min(50, "Description must be at least 50 characters long"),
  minAmountOfGuests: yup
    .number()
    .required()
    .min(1, "There should be at least one guest")
    .max(99, "There shouldn't be more than 99 guests")
    .typeError("Minimum guests must be a number"),
  maxAmountOfGuests: yup
    .number()
    .required()
    .min(1, "There should be at least one guest")
    .max(99, "There shouldn't be more than 99 guests")
    .typeError("Maximum guests must be a number"),
  minReservationDays: yup
    .number()
    .required()
    .min(1, "Package should be at least one day")
    .max(31, "Please enter a value under 31")
    .typeError("Minimum days must be a number"),
  maxReservationDays: yup
    .number()
    .required()
    .min(1, "Package should be at least one day")
    .max(31, "Please enter a value under 31")
    .typeError("Maximum days must be a number"),
  participantPrice: yup
    .number()
    .required()
    .transform((value) => (isNaN(value) ? undefined : value))
    .typeError("Price must be a number")
    .min(1, "Price should be greater than 0")
    .test(
      "max-decimal-places",
      "Price cannot have more than 2 decimal places",
      (value) => value == null || validateDecimal(value, 2),
    ),
  nonParticipantPrice: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .typeError("Price must be a number")
    .nullable()
    .test(
      "max-decimal-places",
      "Price cannot have more than 2 decimal places",
      (value) => value == null || validateDecimal(value, 2),
    ),
  publish: yup.boolean(),
  mostPopular: yup.boolean(),
  soldOut: yup.boolean(),
});

const Package: React.FC<PackageProps> = ({
  title,
  loadedPackage,
  initialValues,
  getSubmitValues,
  canEdit,
  deletePackage,
  pricesDisabled = false,
}): React.ReactElement => {
  const {
    control,
    reset,
    formState: { errors },
    getValues,
    handleSubmit,
    setValue,
    trigger,
  } = useForm({
    mode: "onChange",
    /* @ts-ignore */
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });

  const [autoCompleteLocations, setAutoCompleteLocations] = useState([
    { label: "", id: "" },
  ]);
  const [autoCompleteExperiences, setAutoCompletExperiences] = useState([
    { label: "", id: "" },
  ]);
  const [autoCompleteLodging, setAutoCompletLodging] = useState([
    { label: "", id: "" },
  ]);
  const [enableEditForm, setEnableEditForm] = useState(!canEdit);
  const [locationId, setLocationId] = useState("");
  const navigate = useNavigate();
  const { setLoading } = useContext(LoadingContext);
  const descriptionRef = React.useRef<MDXEditorMethods>(null);

  useEffect(() => {
    reset(initialValues);
  }, [reset, initialValues, autoCompleteExperiences]);

  const onSubmitForm = () => {
    const valuesForm = getValues();
    if (valuesForm.description) {
      valuesForm.description = valuesForm.description.replace(/\\<!--->/g, "");
    }
    getSubmitValues(valuesForm);
  };

  const toggleEditForm = () => {
    setEnableEditForm((enable) => !enable);
  };

  useEffect(() => {
    async function loadLocations() {
      try {
        setLoading(true);
        const locationsList = (
          await locationApi.findAllLocations({
            page: 0,
            pageSize: 100,
          })
        ).data;
        const locationsListFiltered = locationsList.content!.map(
          (location) => ({
            label: location.name!,
            id: location.id!,
          }),
        );
        setAutoCompleteLocations(locationsListFiltered);
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    }

    loadLocations();
  }, [setLoading]);

  useEffect(() => {
    async function loadExperience() {
      try {
        setLoading(true);
        const experiencesList: ISearchExperiencesResponse =
          await searchExperiencesAPI(0, 10, true, "", "", "", locationId);
        const experiencesListFiltered = experiencesList.content.map(
          (location: { id: string; name: string }) => ({
            label: location.name,
            id: location.id,
          }),
        );
        setAutoCompletExperiences(experiencesListFiltered);
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    }
    async function loadLodging() {
      try {
        setLoading(true);
        const lodgingList = await searchLodgingsFromLocationAPI(locationId);
        const lodgingListFiltered = lodgingList.map((lodging: ILodging) => ({
          label: lodging.description,
          id: lodging.id,
        }));
        setAutoCompletLodging(lodgingListFiltered);
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    }
    if (locationId) {
      loadExperience();
      loadLodging();
    }
  }, [locationId]);

  const disabledExperienceField = () => {
    return locationId === "" || canEdit;
  };

  return !loadedPackage ? (
    <LoadingOverlay />
  ) : (
    <Container>
      <H3 fontWeight="700">{title}</H3>
      {canEdit && (
        <ButtonEditWithLocker
          onClick={() => {
            toggleEditForm();
          }}
          lockerClose={enableEditForm}
          qaAttribute="experience-edit-page-edit-button"
        >
          Edit
        </ButtonEditWithLocker>
      )}
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Controller
          name="location"
          control={control}
          render={({ field }) => (
            <Autocomplete
              disablePortal
              freeSolo
              forcePopupIcon={true}
              disabled={canEdit ? true : !enableEditForm}
              onBlur={() => {
                if (field.value) {
                  setLocationId(field.value.id ? field.value.id : "");
                }
              }}
              onChange={(event, value: any) => {
                return field.onChange(value);
              }}
              value={
                canEdit
                  ? {
                      label: initialValues.location.label,
                      id: initialValues.location.id,
                    }
                  : undefined
              }
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="location"
              options={autoCompleteLocations}
              isOptionEqualToValue={(option: any, value: any) => {
                return option === value;
              }}
              renderInput={(params) => (
                <Box mb={2}>
                  <TextField
                    {...params}
                    label="Choose a location *"
                    inputRef={field.ref}
                    error={!!errors.location}
                    helperText={
                      errors.location ? errors.location.message : null
                    }
                  />
                </Box>
              )}
            />
          )}
        />
        <Controller
          name="experience"
          control={control}
          render={({ field }) => (
            <Autocomplete
              disablePortal
              freeSolo
              forcePopupIcon={true}
              disabled={disabledExperienceField()}
              onChange={(event, value) => field.onChange(value)}
              value={
                canEdit
                  ? {
                      label: initialValues.experience.label,
                      id: initialValues.experience.id,
                    }
                  : undefined
              }
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="experience"
              options={autoCompleteExperiences}
              isOptionEqualToValue={(option: any, value: any) => {
                return option === value;
              }}
              renderInput={(params) => (
                <Box mb={2}>
                  <TextField
                    {...params}
                    label="Choose an experience *"
                    inputRef={field.ref}
                    error={!!errors.experience}
                    helperText={
                      errors.experience ? errors.experience.message : null
                    }
                  />
                </Box>
              )}
            />
          )}
        />
        <Controller
          name="lodging"
          control={control}
          render={({ field }) => (
            <Autocomplete
              disablePortal
              freeSolo
              forcePopupIcon={true}
              disabled={disabledExperienceField()}
              onChange={(event, value) => {
                if (!value) {
                  field.onChange({ label: "", id: "" });
                } else {
                  field.onChange(value);
                }
              }}
              value={canEdit ? field.value : undefined}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="lodging"
              options={autoCompleteLodging}
              isOptionEqualToValue={(option: any, value: any) => {
                return option === value;
              }}
              renderInput={(params) => (
                <Box mb={2}>
                  <TextField
                    {...params}
                    label="Choose the lodging"
                    inputRef={field.ref}
                  />
                </Box>
              )}
            />
          )}
        />
        <Typography mt={2} mb={2}>
          Package details
        </Typography>
        <Box mb={2}>
          <Controller
            name="title"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth={true}
                label="Name of package *"
                disabled={!enableEditForm}
                error={!!errors.title}
                helperText={errors.title ? errors.title.message : null}
              />
            )}
          />
        </Box>
        <Typography mt={2} mb={2}>
          Description
        </Typography>
        <Box mb={2}>
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <>
                <StyledBoxDescription>
                  <MDXEditor
                    {...field}
                    ref={descriptionRef}
                    markdown={initialValues.description}
                    readOnly={!enableEditForm}
                    onChange={() => {
                      setValue(
                        "description",
                        descriptionRef.current?.getMarkdown() || "",
                      );
                      trigger("description");
                    }}
                    plugins={[
                      listsPlugin(),
                      toolbarPlugin({
                        toolbarContents: () => (
                          <>
                            <BoldItalicUnderlineToggles />
                            <ListsToggle />
                          </>
                        ),
                      }),
                    ]}
                  />
                </StyledBoxDescription>
                <Box ml={2}>
                  <FormHelperText error={!!errors.description}>
                    {errors.description ? errors.description.message : null}
                  </FormHelperText>
                </Box>
              </>
            )}
          />
        </Box>
        <Typography mt={2} mb={2}>
          Number of guests
        </Typography>
        <Box>
          <Controller
            name="minAmountOfGuests"
            control={control}
            render={({ field }) => (
              <StyledInput
                {...field}
                label="Minimum number *"
                variant="outlined"
                color="primary"
                disabled={!enableEditForm}
                helperText={
                  errors.minAmountOfGuests
                    ? errors.minAmountOfGuests.message
                    : null
                }
                type={"number"}
                error={!!errors.minAmountOfGuests}
              />
            )}
          />
          <Controller
            name="maxAmountOfGuests"
            control={control}
            render={({ field }) => (
              <StyledInput
                {...field}
                label="Maximum number *"
                variant="outlined"
                color="primary"
                disabled={!enableEditForm}
                helperText={
                  errors.maxAmountOfGuests
                    ? errors.maxAmountOfGuests.message
                    : null
                }
                type="number"
                error={!!errors.maxAmountOfGuests}
              />
            )}
          />
        </Box>
        <Typography mt={2} mb={2}>
          Number of days
        </Typography>
        <Box>
          <Controller
            name="minReservationDays"
            control={control}
            render={({ field }) => (
              <StyledInput
                {...field}
                label="Minimum days *"
                variant="outlined"
                color="primary"
                disabled={!enableEditForm}
                helperText={
                  errors.minReservationDays
                    ? errors.minReservationDays.message
                    : null
                }
                type={"number"}
                error={!!errors.minReservationDays}
              />
            )}
          />
          <Controller
            name="maxReservationDays"
            control={control}
            render={({ field }) => (
              <StyledInput
                {...field}
                label="Maximum days *"
                variant="outlined"
                color="primary"
                disabled={!enableEditForm}
                helperText={
                  errors.maxReservationDays
                    ? errors.maxReservationDays.message
                    : null
                }
                type="number"
                error={!!errors.maxReservationDays}
              />
            )}
          />
        </Box>
        <Typography mt={2} mb={2}>
          Price for hunting guest
        </Typography>
        <Box mb={2}>
          <Controller
            name="participantPrice"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth={true}
                type="number"
                multiline
                rows={1}
                label="Price in USD *"
                error={!!errors.participantPrice}
                helperText={
                  errors.participantPrice
                    ? errors.participantPrice.message
                    : null
                }
                disabled={pricesDisabled}
              />
            )}
          />
        </Box>
        <Typography mt={2} mb={2}>
          Price for non hunting guest per day
        </Typography>
        <Box mb={2}>
          <Controller
            name="nonParticipantPrice"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth={true}
                multiline
                rows={1}
                type="number"
                label="Price in USD"
                disabled={pricesDisabled}
                error={!!errors.nonParticipantPrice}
                helperText={
                  errors.nonParticipantPrice
                    ? errors.nonParticipantPrice.message
                    : null
                }
              />
            )}
          />
        </Box>
        <Box>
          <Box mb={2}>
            <Controller
              name="published"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={!enableEditForm}
                        color="success"
                        checked={value}
                        onChange={onChange}
                      />
                    }
                    label="Publish to page"
                  />
                </>
              )}
            />
          </Box>
          <Box mb={2}>
            <Controller
              name="mostPopular"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={!enableEditForm}
                        color="success"
                        checked={value}
                        onChange={onChange}
                      />
                    }
                    label="Popular"
                  />
                </>
              )}
            />
          </Box>
          <Box mb={2}>
            <Controller
              name="soldOut"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={!enableEditForm}
                        color="success"
                        checked={value}
                        onChange={onChange}
                      />
                    }
                    label="Sold Out"
                  />
                </>
              )}
            />
          </Box>
        </Box>
        <Grid container spacing={1} pb={1}>
          <Grid item xs={6}>
            <Button
              type="button"
              color="blackShades"
              variant="outlined"
              size="large"
              fullWidth={true}
              onClick={() => {
                navigate("/");
              }}
              qaAttribute="package-skip-button"
            >
              Cancel
            </Button>
          </Grid>
          {enableEditForm && (
            <Grid item xs={6}>
              <Button
                fullWidth={true}
                type={"submit"}
                size="large"
                variant="contained"
                qaAttribute="package-continue-button"
              >
                Save
              </Button>
            </Grid>
          )}
          {deletePackage && (
            <Grid item xs={6}>
              <Button
                type="button"
                color="blackShades"
                variant="outlined"
                size="large"
                fullWidth={true}
                qaAttribute="package-delete-button"
                onClick={() => {
                  deletePackage();
                }}
              >
                Delete
              </Button>
            </Grid>
          )}
        </Grid>
      </form>
    </Container>
  );
};

export default Package;
