import React, { useState } from "react";
import { StyledSubTitle } from "./SearchForm.style";
import RoundButton from "components/atoms/RoundButton";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { Box, Grid, TextField } from "@mui/material";
import Button from "components/atoms/Button";
import { StyledAutocomplete } from "./SearchForm.style";
import { usStates, saStates} from "../../constants/StatesAndCountries";

const SearchForm = () => {
  
  const initialValues = {
    name: "",
    city: "",
    state: "",
  };

  let stateOptions = [...usStates, ...saStates];

  const schema = yup.object().shape({
    name: yup.string(),
    city: yup.string(),
    state: yup.string(),
  });

  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });
  const navigate = useNavigate();
  const [type, setType] = useState("experience");
  const [selected, setSelected] = useState(true);

  const onSubmitForm = () => {
    const valuesForm = getValues();
    navigate(`/${type}/search`, {
      state: {
        values: valuesForm,
      },
    });
  };

  

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <StyledSubTitle>Search for:</StyledSubTitle>
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
      >
        <Grid mb={2} item>
          <RoundButton
            variant={selected ? "contained" : "outlined"}
            qaAttribute="filter-experience-button"
            onClick={() => {
              setType("experience");
              setSelected(true);
            }}
          >
            Experience
          </RoundButton>
        </Grid>

        <Grid mb={2} item>
          <RoundButton
            variant={selected ? "outlined" : "contained"}
            qaAttribute="filter-location-button"
            onClick={() => {
              setType("location");
              setSelected(false);
            }}
          >
            Location
          </RoundButton>
        </Grid>
      </Grid>
      <Box mb={2}>
        <Controller
          name="name"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="Search by name"
              error={!!errors.name}
              helperText={errors.name ? errors.name.message : null}
              fullWidth={true}
            />
          )}
        />
      </Box>
      <Box mb={2} display={"flex"} justifyContent={"space-between"}>
        <Controller
          name="city"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="City "
              error={!!errors.city}
              helperText={errors.city ? errors.city.message : null}
            />
          )}
        />
        <Controller
          name="state"
          control={control}
          render={({ field }) => (
            <StyledAutocomplete
              {...field}
              data-qa="searchBar"
              options={stateOptions}
              onChange={(event, value) => {
                value === null ? field.onChange("") : field.onChange(value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="State"
                  error={!!errors.state}
                  helperText={errors.state ? errors.state.message : null}
                />
              )}
            />
          )}
        />
        <Button
          qaAttribute="submit-experience-button"
          type="submit"
          variant="contained"
          size="large"
        >
          Submit
        </Button>
      </Box>
    </form>
  );
};

export default SearchForm;
