import * as yup from "yup";

export const bedroomSchema = yup.object().shape({
  name: yup.string(),
  numberOfTwinBeds: yup
    .number()
    .required()
    .typeError("Must be a number")
    .min(0, "The number must be greater than or equal to 0"),
  numberOfFullBeds: yup
    .number()
    .required()
    .typeError("Must be a number")
    .min(0, "The number must be greater than or equal to 0"),
  numberOfQueenBeds: yup
    .number()
    .required()
    .typeError("Must be a number")
    .min(0, "The number must be greater than or equal to 0"),
  numberOfKingBeds: yup
    .number()
    .required()
    .typeError("Must be a number")
    .min(0, "The number must be greater than or equal to 0"),
});

export const lodgingSchema = yup.object().shape({
  description: yup
    .string()
    .required("Description is required")
    .min(50, "Description must be at least 50 characters long"),
  maximumCapacity: yup
    .number()
    .required()
    .min(0, "The number must be greater than or equal to 0")
    .typeError("Must be a number"),
  numberOfBathrooms: yup
    .number()
    .required()
    .typeError("Must be a number")
    .min(0, "The number must be greater than or equal to 0"),
  sleepingArrangementDescription: yup
    .string()
    .nullable()
    .min(
      50,
      "Sleeping Lodging Arrangements must be at least 50 characters long",
    ),
  bedrooms: yup.array(bedroomSchema),
});
