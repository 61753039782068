import React, { useContext, useEffect, useState } from "react";
import Container from "components/atoms/Container";
import { Typography } from "@mui/material";
import { StyledTitle } from "./SearchLodging.style";
import { useLocation } from "react-router-dom";
import { ILodging } from "services/types/lodgings";
import Search from "components/templates/Search/Search";
import { LoadingContext } from "context/LoadingContext";
import useParameter from "hooks/useParameter";
import { searchLodgingsFromLocationAPI } from "services/lodgingsService";

const SearchLodging: React.FC = () => {
  const { getPageType, getPageTitle } = useParameter();
  const [lodgings, setLodgings] = useState<ILodging[]>([]);
  const type = getPageType();
  const title = getPageTitle() + "(s)";
  const locationId = useLocation().state.locationId;
  const { setLoading } = useContext(LoadingContext);

  useEffect(() => {
    async function loadLodgings() {
      try {
        setLoading(true);
        const lodgingsList = await searchLodgingsFromLocationAPI(locationId);
        setLodgings((lodgings) => lodgingsList);
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    }

    loadLodgings();
  }, [type, setLoading, locationId]);

  return (
    <Container>
      <StyledTitle>Search Results</StyledTitle>
      <Typography mb={3} ml={3}>
        {title}
      </Typography>
      {lodgings !== undefined && <Search data={lodgings} type={type} />}
    </Container>
  );
};

export default SearchLodging;
