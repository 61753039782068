import { useMutation, useQueryClient } from "@tanstack/react-query";
import { locationApi } from "./locationService";
import AsyncStatuses from "common/types/enums/asyncStatuses";
import { LocationsAPIApiCreateLocationRequest } from "api/v1.0";
import { locationKeys } from "./locationQueryKeyFactory";

function useMutateLocation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (
      createLocationApiRequest: LocationsAPIApiCreateLocationRequest,
    ) => locationApi.createLocation(createLocationApiRequest),
    onSuccess: async (_data, _variables) => {
      await queryClient.invalidateQueries({ queryKey: locationKeys.lists() });
    },
  });
}

export const useCreateLocation = () => {
  const mutateLocation = useMutateLocation();

  return {
    ...mutateLocation,
    createStatus: mutateLocation.status as AsyncStatuses,
  };
};
