import React, { useContext, useEffect, useState } from "react";
import Button from "components/atoms/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import Container from "components/atoms/Container";
import { StyledTitle, StyledInput, StyledLine } from "./Login.Style";
import Box from "components/atoms/Box";
import { ILoginUserRequest } from "services/types/user";
import { FormHelperText } from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { authContext } from "context/AuthContext";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function Login() {
  const { isLoggedIn, login } = useContext(authContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    }
  }, [isLoggedIn, navigate]);

  const schema = yup.object().shape({
    email: yup
      .string()
      .email("Please enter a valid email address")
      .required("Email is required")
      .min(5, "Please enter a valid email address"),
    password: yup
      .string()
      .required("Password is required")
      .matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
        {
          message: "Invalid Username or Password",
          excludeEmptyString: true,
        },
      ),
  });

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const [loginError, setLoginError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [searchParams] = useSearchParams();

  const onSubmit = async (data: ILoginUserRequest) => {
    try {
      const returnUrl = searchParams.get("returnUrl") || "";
      await login(data.email, data.password, returnUrl);
    } catch (error: any) {
      const errorMessage = "Invalid username or password";
      setLoginError(errorMessage);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container>
        <StyledTitle variant="h6" align="center">
          ADMIN SIGN-IN
        </StyledTitle>
        <Box>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <StyledInput
                {...field}
                required
                label="Email Address"
                variant="outlined"
                color="primary"
                helperText={errors.email ? errors.email.message : null}
                type={"email"}
                error={!!errors.email}
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <StyledInput
                {...field}
                required
                label="Password"
                variant="outlined"
                color="primary"
                helperText={errors.password ? errors.password.message : null}
                error={!!errors.password}
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <>
                      {showPassword ? (
                        <VisibilityOutlinedIcon
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      ) : (
                        <VisibilityOffOutlinedIcon
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      )}
                    </>
                  ),
                }}
              />
            )}
          />
        </Box>
        <FormHelperText error={!!loginError}>
          {loginError ? loginError : null}
        </FormHelperText>
        <StyledLine>
          Everything you upload will be registered to your admin account
        </StyledLine>
        <Button
          qaAttribute="login-continue-button"
          type="submit"
          variant="contained"
          isDisabled={!isValid}
        >
          Continue
        </Button>
      </Container>
    </form>
  );
}
