import React, { Fragment } from "react";
import SearchCard from "components/atoms/SearchCard/SearchCard";
import { StyledSearchCardWrapper } from "./Search.style";
import { ICreateExperienceResponse } from "services/types/experience";
import { ILodging } from "services/types/lodgings";
import { useNavigate } from "react-router-dom";
import { Card } from "components/atoms/Card";
import Typography from "@mui/material/Typography";
import { IPackageResponse } from "services/types/packages";
import { LocationResponse } from "api/v1.0";

interface SearchProps {
  data: (
    | ICreateExperienceResponse
    | LocationResponse
    | ILodging
    | IPackageResponse
  )[];
  type: string;
  showLocation?: boolean | undefined;
  id?: string;
}

const Search: React.FC<SearchProps> = ({
  data,
  type,
  showLocation,
  id,
}): React.ReactElement => {
  const formatedType = type.charAt(0).toLocaleUpperCase() + type.slice(1);

  const navigate = useNavigate();

  return (
    <StyledSearchCardWrapper>
      {data.length === 0 ? (
        <Typography> No results </Typography>
      ) : (
        data.map((element, i) => {
          const location =
            "address" in element
              ? `${element.address?.city}, ${element.address?.state}, ${element.address?.country}`
              : "";
          return (
            <Fragment key={i}>
              {("name" || "title") in element ? (
                <SearchCard
                  title={element.name}
                  showLocation={showLocation}
                  location={location}
                  type={formatedType}
                  key={i}
                  onClick={() => {
                    navigate(`/${type}/edit?${type}-id=${element.id}`, {
                      state: {
                        data: element,
                      },
                    });
                  }}
                />
              ) : (
                <Card
                  key={i}
                  onClick={() => {
                    navigate(`/${type}/edit?${type}-id=${element.id}`, {
                      state: {
                        data: element,
                        locationId:
                          (element as { locationId?: string }).locationId ??
                          element.id ??
                          "",
                      },
                    });
                  }}
                >
                  <Typography>{element.description} </Typography>
                </Card>
              )}
            </Fragment>
          );
        })
      )}
    </StyledSearchCardWrapper>
  );
};

export default Search;
