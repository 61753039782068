import { lodgingResponseToUpdateLodgingAmenitiesSchema } from "./lodgingMapper";
import { useGetLodgingAmenities } from "./useGetLodgingAmenities";
import { useGetLodgingById } from "./useGetLodgingById";
import { useUpdateLodging } from "./useUpdateLodging";

export const useUpdateLodgingAmenities = (lodgingId: string) => {
  const { data: allAmenities, ...restGetAllAmenities } =
    useGetLodgingAmenities();
  const { data: lodgingData, ...restGetLodging } = useGetLodgingById(lodgingId);
  const { mutate: updateLodging, ...restUpdateLodging } = useUpdateLodging();

  const handleUpdate = (amenityIds: string[], onUpdate?: () => void) => {
    const values = lodgingResponseToUpdateLodgingAmenitiesSchema(lodgingData!);

    values.amenityIds = amenityIds;

    updateLodging(
      {
        id: lodgingId,
        upsertLodgingRequest: values,
      },
      {
        onSuccess: () => {
          if (onUpdate) onUpdate();
        },
      },
    );
  };

  return {
    isLoading: restGetAllAmenities.isLoading || restGetLodging.isLoading,
    isPending: restUpdateLodging.isPending,
    isError:
      restGetAllAmenities.isError ||
      restGetLodging.isError ||
      restUpdateLodging.isError,
    error:
      restGetAllAmenities.error ??
      restGetLodging.error ??
      restUpdateLodging.error,
    onSubmit: handleUpdate,
    initialAmenities: lodgingData?.amenities ?? [],
    allAmenities,
  };
};
