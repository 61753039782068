import { Typography, styled } from "@mui/material";

export const StyledTitle = styled(Typography)({
  fontFamily: "Obviously, Roboto, sans-serif",
  fontSize: "28px",
  fontWeight: "600",
  lineHeight: "36px",
});

export const StyledSubTitle = styled(Typography)(() => ({
  fontSize: "20px",
  lineHeight: "24px",
  fontWeight: "700",
}));
