import LoadingOverlay from "components/atoms/LoadingOverlay";
import useLoading from "hooks/useLoading";
import React, { Dispatch, SetStateAction, createContext } from "react";

interface LoadingProviderProps {
  children: React.ReactNode;
}
interface ILoadingContext {
  isLoading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
}
const initialState: ILoadingContext = {
  isLoading: false,
  setLoading: () => {},
};
export const LoadingContext = createContext(initialState);

export default function LoadingProvider({ children }: LoadingProviderProps) {
  const { isLoading, setLoading } = useLoading();

  return (
    <LoadingContext.Provider
      value={{
        isLoading,
        setLoading,
      }}
    >
      {children}
      {isLoading && <LoadingOverlay />}
    </LoadingContext.Provider>
  );
}
