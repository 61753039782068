export const orange = {
  100: "#FF5C00",
  80: "#FF9D66",
  60: "#FFBE99",
  40: "#FFBE99",
  20: "#FFDECC",
};

export const blue = {
  100: "#000037",
  80: "#33335F",
  60: "#666687",
  40: "#9999AF",
  20: "#CCCCD7",
};

export const grey = {
  100: "#000000",
  80: "#333333",
  60: "#666666",
  40: "#999999",
  20: "#CCCCCC",
  10: "#f4f4f0",
};
