import React, { useContext, useEffect, useState } from "react";
import Container from "components/atoms/Container";
import { Box, Typography } from "@mui/material";
import { StyledTitle } from "./SearchLocation.style";
import { useLocation } from "react-router-dom";
import Button from "components/atoms/Button";
import Search from "../../templates/Search/Search";
import { LoadingContext } from "context/LoadingContext";
import useParameter from "hooks/useParameter";
import { locationApi } from "services/location";
import { LocationResponse } from "api/v1.0";

const SearchLocation: React.FC = () => {
  const { getPageType, getPageTitle } = useParameter();
  const [locations, setLocations] = useState<LocationResponse[]>([]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const type = getPageType();
  const title = getPageTitle();
  const values = (
    useLocation().state as {
      values: { name: string; city: string; state: string };
    }
  ).values;
  const { setLoading } = useContext(LoadingContext);

  useEffect(() => {
    async function loadLocations() {
      try {
        setLoading(true);
        const locationsList = (
          await locationApi.findAllLocations({
            page,
            pageSize: 100,
            name: values.name,
            city: values.city,
            state: values.state,
          })
        ).data;

        setLocations((locations) => locations.concat(locationsList.content!));
        setTotalPages(locationsList.totalPages!);
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    }

    void loadLocations();
  }, [page, type, setLoading, values]);

  const loadMore = () => {
    setPage(page + 1);
  };

  return (
    <Container>
      <StyledTitle>Search Results</StyledTitle>
      <Typography mb={3} ml={3}>
        {title}
      </Typography>
      <Search data={locations} type={type} showLocation={true} />
      {page + 1 < totalPages && (
        <Box display="flex" justifyContent="center">
          <Button
            variant="text"
            qaAttribute="button-load-more"
            color="blackShades"
            onClick={loadMore}
          >
            Load more locations
          </Button>
        </Box>
      )}
    </Container>
  );
};

export default SearchLocation;
