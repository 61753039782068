import React, { ReactNode } from "react";
import { Button as MuiButton, styled } from "@mui/material";

type ButtonProps = {
  children: ReactNode;
  size?: "small" | "medium" | "large";
  type?: "button" | "submit" | "reset" | undefined;
  color?:
    | "primary"
    | "inherit"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning"
    | "greyShades"
    | "whiteShades"
    | "greyShades"
    | "blackShades";
  variant?: "outlined" | "contained" | "text";
  onClick?: () => void;
  fullWidth?: boolean;
  isDisabled?: boolean;
  qaAttribute: string;
};

export const StyledButton = styled(MuiButton)(({ variant }) => ({
  border: variant === "outlined" ? "2px solid" : "0px",
  textTransform: "inherit",
  borderRadius: "4px",
  boxShadow: "none",
  borderColor: "inherit",
  "&:hover": {
    boxShadow: "none",
    border: variant === "outlined" ? "2px solid" : "0px",
  },
}));

export const Button: React.FC<ButtonProps> = ({
  children,
  size = "medium",
  type = "button",
  color = "primary",
  variant = "outlined",
  onClick,
  fullWidth = false,
  isDisabled = false,
  qaAttribute,
}): React.ReactElement => {
  return (
    <>
      <StyledButton
        variant={variant}
        size={size}
        type={type}
        color={color}
        onClick={onClick}
        fullWidth={fullWidth}
        disabled={isDisabled}
        data-qa={qaAttribute}
      >
        {children}
      </StyledButton>
    </>
  );
};

export default Button;
